import React, { useEffect, useState, useRef } from 'react';
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import directus from "../../../services/directus";
import moment from "moment";
import { getCache, setCache } from 'src/utils/KeyValueCache';
import { getNewOrder, formatNumber, OrderDetailLoadingView, configurationFields } from 'src/components/ReusableFunction';
import { useStyles } from '../approvaldetailstyle';
import { getStatusIconPath } from 'src/components/ReusableStyle';
import { OverflowTip } from "src/components/OverflowTip";
import { IconButton, Divider, InputAdornment } from "@mui/material";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import ApprovalSelectedItemBodyView from '../ApprovalSelectedItemBodyView';
import ButtonsView from '../ButtonsView';

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search("interax") !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

function SelectedAwaitingApproval() {
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [showTotalDetails, setShowTotalDetails] = useState(false);
  const [showPOAccount, setShowPOAccount] = useState(false);
  const [showPOInternalCategory, setShowPOInternalCategory] = useState(false);
  const [, setSelectedApproval, selectedApprovalRef] = useStateRef(null);
  const [orderLoading, setOrderLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [openChangeLog, setOpenChangeLog] = useState(false);
  const [logData, setLogData] = useState([]);
  const [logDataLoading, setLogDataLoading] = useState(false);
  const [orderEventType, setOrderEventType] = useState(null);
  const [, setSelectedSupplier, selectedSupplierRef] = useStateRef(null);
  const [, setCategoryDescription, categoryDescriptionRef] = useStateRef(null);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [, setSubTotal, subTotalRef] = useStateRef(0);
  const [, setTotalTax, totalTaxRef] = useStateRef(0);
  const [, setItems, itemsRef] = useStateRef(null);
  const [, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  
  const [selectedDeliveryDate, handleDeliveryDateChange] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [notes, setNotes] = useState(null);
  const isMounted = useRef(true);
  
  useEffect(() => {
    isMounted.current = true; // component mount
    if (!initialized) {
      setInitialized(true);
      loadOrderSelected();
      getConfigurationSettings();
      loadOrderEventType();
    }

    return () => {
      isMounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const changeSelectedOrder = async () => {
      setOrderLoading(true);
      await loadOrderSelected();
    };

    const listener1 = EventEmitter.addListener("selected_awaiting_approval", changeSelectedOrder);

    return () => {
      listener1.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadOrderSelected = async () => {
    setOrderLoading(true);

    const selectedOrderId = window.location.pathname.split('/').pop();

    if (selectedOrderId !== 'approvals') {
      const params_filter = [
        "*",
        "category_type.*",
        "employee.id",
        "employee.id",
        "employee.user.id",
        "employee.user.first_name",
        "employee.user.last_name",
        "employee.supervisors.supervisor.id",
        "employee.supervisors.supervisor.user.id",
        "employee.supervisors.supervisor.user.first_name",
        "employee.supervisors.supervisor.user.last_name",
        "supplier.id",
        "supplier.code",
        "supplier.name",
        "supplier.status",
        "supplier.email",
        "farm_code.id",
        "farm_code.code",
        "farm_code.name",
        "farm_code.legal_name",
        "approvals.*",
        "approvals.level.id",
        "approvals.level.level",
        "approvals.employee.id",
        "approvals.employee.user.id",
        "approvals.employee.user.first_name",
        "approvals.employee.user.last_name",
        "approvals.employee.supervisors.supervisor.id",
        "approvals.employee.supervisors.supervisor.user.first_name",
        "approvals.employee.supervisors.supervisor.user.last_name",
        "order_items.order_item.category_description",
        "order_items.order_item.category_type.*",
        "order_items.order_item.description",
        "order_items.order_item.id",
        "order_items.order_item.quantity",
        "order_items.order_item.supplier_code",
        "order_items.order_item.unit_price",
        "order_items.id",
        "files.*.*",
      ];
  
      var request_header = {
        fields: params_filter,
      }
      
      var params = Object.entries(request_header).map(e => e.join('=')).join('&');
  
      let orderSelected = null;

      orderSelected = await directus.api.post(`/custom/orders/awaiting_approval?${params}`, {
        filter: {
          'orders.id': selectedOrderId
        }
      });
      
      if (orderSelected && orderSelected.data.length > 0) {
        var newOrder = await getNewOrder(orderSelected);
        let orderData = newOrder[0];
    
        let totalOrder = 0;
        
        if (orderData) {
          if (orderData.approvals && orderData.approvals.length > 0) {
            // Find the approval with the highest level
            orderData.approvals = [orderData.approvals.reduce((highest, approval) => {
              const numericLevel = parseInt(approval.level.level, 10);
              return numericLevel > parseInt(highest.level.level, 10) ? approval : highest;
            })];
          }
  
          if (orderData.order_items) {
            orderData.order_items.forEach(data => {
              if (data.order_item !== null) {
                totalOrder = totalOrder + data.order_item.unit_price * data.order_item.quantity;
              }
            });
          }   
    
          orderData.total_order = totalOrder;
          orderData.statusIconPath = getStatusIconPath(orderData.status);
        }
  
        if (isMounted.current) {
          setSelectedApproval(orderData);
    
          setTotalOrderItem(totalOrder);
          if (orderData) {
            setSelectedCategoryType(orderData.category_type);
            setCategoryDescription(orderData.category_description);
            setDeliveryInstructions(orderData.delivery_instructions);
            handleDeliveryDateChange(orderData.due_date ? moment(orderData.due_date).format() : Date.now())
            setNotes(orderData.notes);
            setSelectedCustomer(orderData.farm_code);
            setSelectedSupplier(orderData.supplier);
          }
          loadItems();
          setAdd(false);    
        }
      }
      if (isMounted.current) {
        countTotal();
      }
    } else {
      if (isMounted.current) {
        setSelectedApproval(null);
      }
    }

    if (isMounted.current) {
      setOrderLoading(false);
    }
  }

  const loadItems = () => {
    let itemData = [];

    if (selectedApprovalRef.current && selectedApprovalRef.current.order_items && selectedApprovalRef.current.order_items.length > 0) {
      //eslint-disable-next-line array-callback-return
      selectedApprovalRef.current.order_items.map((data, index) => {
        itemData[index] = { ...data.order_item, statusValue: 0, count: index, orders_items_id: data.id };
      });
      setItems(itemData);
    }
  };

  const loadOrderEventType = async () => {
    let company = JSON.parse(window.localStorage.getItem("company"));
    let token = JSON.parse(window.localStorage.getItem("directus_data"));

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.token
      }
    };

    var order_event_type = getCache("order_event_type_options_choices");

    if (!order_event_type) {
      fetch(`${react_app_url}${company}/fields/order_event/type`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (isMounted.current && result.data) {
            setOrderEventType(result.data.options.choices);
            setCache("order_event_type_options_choices", result.data.options.choices);
          }
        });
    } else {
      if (isMounted.current) {
        setOrderEventType(order_event_type);
      }
    }
  };

  const countTotal = () => {
    if (itemsRef.current) {
      let subtotal = 0;
      
      //eslint-disable-next-line array-callback-return
      itemsRef.current.map(data => {
        if (data.statusValue !== 3) {
          if (data.unit_price && data.quantity) {
            subtotal = subtotal + parseFloat(data.unit_price * data.quantity);
          }
        }
      });
      const tax = 10 * subtotal / 100;
      const total = subtotal;
      // const total = subtotal + tax;
  
      setSubTotal(subtotal.toFixed(2));
      setTotalTax(tax.toFixed(2));
      setTotalOrderItem(total.toFixed(2));
    }
  };

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration', { fields: configurationFields});
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (isMounted.current && result.data.length > 0) {
      if (result.data[0]) {

        if (result.data[0].show_purchase_orders_total_details === true) {
          setShowTotalDetails(true);
        }

        if (result.data[0].show_po_account === true) {
          setShowPOAccount(true);
        }

        if (result.data[0].show_po_internal_category === true) {
          setShowPOInternalCategory(true);
        }
      }
    }
  }

  const handleOpenChangeLog = async () => {
    setOpenChangeLog(true);
    setLogDataLoading(true);
    if(selectedApprovalRef.current) {
      var OrderEvent = await directus.getItems('order_event', {
        fields: '*.*',
        filter: {
          order: selectedApprovalRef.current.id
        },
        sort: '-id'
      });
      setLogData(OrderEvent.data);
    }
    setLogDataLoading(false);
  };

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else {
      return '';
    }
  }

  const noDataFound = () => (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ opacity: "0.2", paddingTop: "2pc" }}>
      <Grid item xs={12} sm={12} lg={12}>
        <img alt="No Data Found" height="200" width="200" src="/static/images/folder.png?v=0.0.1" />
        <Typography variant="h3" style={{ textAlign: "end" }}>
          No Data Found
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <div>
      {orderLoading ?
        <OrderDetailLoadingView />
      :
        selectedApprovalRef.current ?
          <>
            <Grid container spacing={0} justifyContent="space-around">
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography noWrap component="div" style={{ paddingRight: '20px', fontWeight: '590', fontSize: '20px' }}>
                    <OverflowTip>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '5px' }}>
                        Purchase Order
                      </div>
                    </OverflowTip>
                </Typography>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ fontWeight: '400', fontSize: '17px', color: 'rgba(60, 60, 67, 0.6)' }}
                >
                  <OverflowTip>
                    Created by: {
                      selectedApprovalRef.current ?
                        selectedApprovalRef.current ?
                          selectedApprovalRef.current.employee ?
                            selectedApprovalRef.current.employee.user ?
                              `${capitalizeFirstLetter(selectedApprovalRef.current.employee.user.first_name)} ${capitalizeFirstLetter(selectedApprovalRef.current.employee.user.last_name)}`
                            : ''
                          : ''
                        : ''
                      : ''
                    }
                  </OverflowTip>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexEnd}>  
                <OverflowTip>
                  <div style={{ display: 'flex', columnGap: '10px' }}>
                    {add ? '' :
                      <IconButton onClick={() => handleOpenChangeLog()} aria-label="delete" size="small" style={{  width: '47px', height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px' }}>
                        <img src='/static/images/icons/history.svg' alt='History Icon' />
                      </IconButton>
                    }
                    <IconButton aria-label="delete" size="small" style={{  height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', paddingRight: '20px', paddingLeft: '20px' }}>
                      <img src={selectedApprovalRef.current ? selectedApprovalRef.current && selectedApprovalRef.current.statusIconPath : ''} alt='Status Icon' />
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle2}
                        style={{ paddingLeft: '10px', color: '#374355', lineHeight: '22px', textTransform: 'capitalize' }}
                      >
                        <OverflowTip>
                          <span style={{ textTransform: 'capitalize' }}>
                            {selectedApprovalRef.current ?
                              selectedApprovalRef.current? 
                                selectedApprovalRef.current.status ?
                                  selectedApprovalRef.current.status === 'awaiting_approval' ?
                                    'Awaiting Approval'
                                  : selectedApprovalRef.current.status
                                : ''
                              : ''
                            : ''}
                          </span>
                        </OverflowTip>
                      </Typography>
                    </IconButton>
                  </div>
                </OverflowTip>
              </Grid>
            </Grid>
            <>
              <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
                <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
                  <>
                    {/* -------------------- SUPPLIER -------------------- */}
                    <div className='supplier'>
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle}
                        style={{ paddingLeft: '16px' }}
                      >
                        <OverflowTip>Supplier</OverflowTip>
                      </Typography>
                      <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle2}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>{selectedSupplierRef.current ? selectedSupplierRef.current.name : ''}</OverflowTip>
                        </Typography>
                      </div>
                    </div>


                    {/* -------------------- ACCOUNT -------------------- */}
                    {showPOAccount ?
                      <>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ marginTop: '17px', paddingLeft: '16px' }}
                        >
                          <OverflowTip>Account</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'white', height: '88px', borderRadius: '10px'}}>
                          <TextField
                            disabled
                            variant="outlined"
                            style={{ padding: '0px 16px'}}
                            className={classes.textfield}
                            value={ selectedCustomerRef.current ? selectedCustomerRef.current.name ?? '' : ''}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Customer</Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Divider style={{ marginLeft: '16px'}} />
                          <TextField
                            disabled
                            variant="outlined"
                            style={{ padding: '0px 16px'}}
                            className={classes.textfield}
                            value={ selectedCustomerRef.current ? selectedCustomerRef.current.legal_name ?? '' : ''}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Invoice to</Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </>
                    : ''}
                  </>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <>
                    {/* -------------------- DATE -------------------- */}
                    <div className='supplier'>
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle}
                        style={{ paddingLeft: '16px' }}
                      >
                        <OverflowTip>Date</OverflowTip>
                      </Typography>
                      <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle2}
                          style={{ paddingLeft: '16px' }}
                        >
                          <OverflowTip>
                            {selectedApprovalRef.current ?
                              selectedApprovalRef.current ?
                                selectedApprovalRef.current.order_date ?
                                  moment(selectedApprovalRef.current.order_date).format('DD MMMM YYYY')
                                : ''
                              : ''
                            : ''}
                          </OverflowTip>
                        </Typography>
                      </div>
                    </div>

                    {/* -------------------- INTERNAL -------------------- */}
                    {showPOInternalCategory ?
                      <>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle}
                          style={{ marginTop: '17px', color: '#67AC2D', paddingLeft: '16px' }}
                        >
                          <OverflowTip>Internal</OverflowTip>
                        </Typography>
                        <div style={{ backgroundColor: 'rgba(130, 203, 67, 0.15)', height: '88px', borderRadius: '10px'}}>
                          <TextField
                            disabled
                            variant="outlined"
                            style={{ padding: '0px 16px'}}
                            className={classes.textfield}
                            value={ selectedCategoryType ? selectedCategoryType.name ?? '' : ''}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Category</Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Divider style={{ marginLeft: '16px'}} />
                          <TextField
                            disabled
                            variant="outlined"
                            style={{ padding: '0px 16px'}}
                            className={classes.textfield}
                            value={ categoryDescriptionRef.current ? categoryDescriptionRef.current.name ?? '' : ''}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Details</Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </>
                    : ''}
                  </>
                </Grid>
              </Grid>
              {/* -------------------- TABLE HEADER -------------------- */}
              <Grid
                container
                spacing={0}
                justifyContent="space-around"
                style={{
                  marginTop: '20px',
                }}
              >
                <Grid
                  item
                  xs={showPOInternalCategory ? 7 : 12}
                  sm={showPOInternalCategory ? 7 : 12}
                  md={showPOInternalCategory ? 7 : 12}
                  lg={showPOInternalCategory ? 7 : 12}
                >
                  <div style={{ paddingRight: '16px' }}>
                    <Grid container spacing={0} justifyContent="space-around" style={{ padding: '0px 5px' }}>
                      <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                        <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                          <OverflowTip>Qty</OverflowTip>
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                        <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                          <OverflowTip>Code</OverflowTip>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                        <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                          <OverflowTip>Description</OverflowTip>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                        <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                          <OverflowTip>Unit Price</OverflowTip>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                {showPOInternalCategory ?
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <Grid container spacing={0} justifyContent="space-around" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                      <Grid item style={{ width: '100%' }}>
                        <Grid container spacing={0}>
                          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                            <Typography noWrap component="div" className={classes.typographyStyle} style={{ color: '#67AC2D' }}>
                              <OverflowTip>Item Category</OverflowTip>
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography noWrap component="div" className={classes.typographyStyle} style={{ color: '#67AC2D' }}>
                              <OverflowTip>Item Details</OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                : ''}
              </Grid>

              {/* -------------------- TABLE BODY -------------------- */}
              {itemsRef.current ?
                itemsRef.current?
                  itemsRef.current.map((dataItem, index) => {
                    const isLastItem = index === itemsRef.current.length - 1;

                    return (
                      <ApprovalSelectedItemBodyView
                        key={index}
                        dataItem={dataItem}
                        index={index}
                        showPOInternalCategory={showPOInternalCategory}
                        isLastItem={isLastItem}
                      />
                    )
                  })
                : ''
              : ''}
              {/* -------------------- DELIVERY -------------------- */}
              <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle}
                    style={{ marginTop: '17px', paddingLeft: '16px' }}
                  >
                    <OverflowTip>Delivery</OverflowTip>
                  </Typography>
                  <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', paddingLeft: '16px' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery by</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8} style={{ height: '44px' }}>
                        {selectedDeliveryDate ?
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.datepicker}
                              inputVariant='outlined'
                              id="date_picker"
                              disabled
                              format="dd MMMM yyyy"
                              placeholder="dd/MM/yyyy"
                              value={moment(selectedDeliveryDate).format()}
                              keyboardIcon={<img src='/static/images/icons/calendar.svg' alt='Calendar Icon' />}
                            />
                          </MuiPickersUtilsProvider>
                        : ''}
                      </Grid>
                    </Grid>
                    <div style={{ backgroundColor: 'white' }}>
                      <Divider />
                    </div>
                    <Grid container spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery instructions</Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>    
                        <TextField
                          variant="outlined"
                          className={classes.textfield}
                          value={deliveryInstructions ?? ''}
                          disabled
                          style={{ paddingRight: '16px' }}
                          name="delivery_instructions"
                        />
                      </Grid>
                    </Grid>
                    <div style={{ backgroundColor: 'white' }}>
                      <Divider />
                    </div>
                    <Grid container spacing={0} style={{ minHeight: '60px' }}>
                      <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                        <OverflowTip>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Delivery address</Typography>
                        </OverflowTip>
                        <OverflowTip>
                          <Typography className={classes.typographyStyle2} style={{ color: 'rgba(60, 60, 67, 0.6)' }}>
                            {selectedApprovalRef.current ? selectedApprovalRef.current.delivery_address_name : ''}
                          </Typography>
                        </OverflowTip>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.deliveryaddressstyle} style={{  display: 'flex', flexDirection: 'column', width: '100%' }}>
                          <div>
                            {selectedApprovalRef.current ?
                              `${selectedApprovalRef.current.delivery_address_line_1 ?? ''}${selectedApprovalRef.current.delivery_address_line_2 ? ', ' + selectedApprovalRef.current.delivery_address_line_2 : ''}`
                            : ''}
                          </div>
                          <div>
                            {selectedApprovalRef.current ?
                              `${selectedApprovalRef.current.delivery_address_city
                                  ? selectedApprovalRef.current.delivery_address_city + ' '
                                  : ''}` +
                              `${selectedApprovalRef.current.delivery_address_state
                                  ? selectedApprovalRef.current.delivery_address_state + ' '
                                  : ''}` +
                              `${selectedApprovalRef.current.delivery_address_postcode
                                  ? selectedApprovalRef.current.delivery_address_postcode + ' '
                                  : ''}`
                            : ''}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  {/* Notes */}
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle}
                    style={{ marginTop: '17px', paddingLeft: '16px' }}
                  >
                    <OverflowTip>Notes</OverflowTip>
                  </Typography>
                  <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', paddingLeft: '16px' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>    
                        <TextField
                          variant="outlined"
                          className={`${classes.textfield} ${classes.leftAlignTextfield}`}
                          value={notes ?? ''}
                          disabled
                          style={{ paddingRight: '16px' }}
                          name="notes"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} >
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle}
                    style={{ marginTop: '17px', paddingLeft: '16px' }}
                  >
                    <OverflowTip>Totals</OverflowTip>
                  </Typography>
                  <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                    {showTotalDetails ?
                      <>
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '13px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '13px', height: '44px' }}>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                          </Grid>
                          <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                            <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                              <OverflowTip>
                                {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                              </OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider style={{ marginLeft: '16px' }} />
                      </>
                    : ''}
                    <Grid container spacing={0} justifyContent="space-around" className={showTotalDetails ? classes.gridContainer2 : classes.gridContainer} style={{ paddingRight: '5px'}}>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle4}
                          style={{ lineHeight: '22px' }}
                          >
                          <OverflowTip>Total</OverflowTip>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                        <Typography
                          noWrap
                          component="div"
                          className={classes.typographyStyle4}
                          style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                        >
                          <OverflowTip>
                            {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                          </OverflowTip>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </>

            <ButtonsView selectedOrder={selectedApprovalRef.current} />
          </>
        :
          noDataFound()
      }

      <Dialog
        isopen={`${openChangeLog}`}
        open={openChangeLog}
        fullWidth={true}
        onClose={() => setOpenChangeLog(false)}
        className={classes.DialogContent}
      >
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>Date & Time</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>Status Update</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography noWrap component="div" className={classes.typographyStyle}>
                <OverflowTip>User</OverflowTip>
              </Typography>
            </Grid>
          </Grid>
          {logDataLoading?
            <CircularProgress key='load-progress-orders' className={classes.circular} style={{  color: '#67AC2D' }}/>
          :
            logData.length > 0
              ? logData.map((dataLog, index) => {
                return (
                  <Grid container spacing={0} key={index} className={classes.gridContainer3}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{dataLog.created_on ? moment(dataLog.created_on).format('DD/MM/YYYY hh:mm A') : ''}</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{orderEventType ? orderEventType[dataLog.type] : dataLog.type}</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: '#000000' }}>
                        <OverflowTip>{dataLog.owner ? dataLog.owner.first_name : ''} {dataLog.owner ? dataLog.owner.last_name : ''}</OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            : null
          }
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SelectedAwaitingApproval