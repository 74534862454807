import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress,
  Switch,
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize
} from '@material-ui/core';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

// const CustomTooltip = withStyles({
//   tooltip: {
//     fontSize: "16px",
//     maxWidth: 500,
//     backgroundColor: "grey"
//   }
// })(Tooltip);

let page = 1;

const Categories = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [categoriesData, setCategoriesData] = useState(null);

  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [dataCategory, setDataCategory, dataCategoryRef] = useStateRef({
    id: '', name: '', collection: '', field: '', field_template: '', show_category: false, show_as_textbox: false, required: false, custom_filter: ''
  })

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "name": { type: "desc", value: false },
    "collection": { type: "desc", value: false },
    "field": { type: "desc", value: false },
    "field_template": { type: "desc", value: false },
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const isMounted = useRef(true);

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }

    return () => {
      isMounted.current = false;
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = dataCategoryRef.current.name === '';

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.categories) {
        setSortField(sort_data.categories)
      }
    }
    loadCategories();
  }

  const loadCategories = async (data) => {
    let filterData;

    var orderByField = "name";

    var name_sort = sortFieldRef.current.name;
    var collection_sort = sortFieldRef.current.collection;
    var field_sort = sortFieldRef.current.field;
    var field_template_sort = sortFieldRef.current.field_template;

    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByField = "name"; }
      else if (name_sort.type === "desc") { orderByField = "-name"; }
    }

    if (collection_sort.value) {
      if (collection_sort.type === "asc") { orderByField = "collection"; }
      else if (collection_sort.type === "desc") { orderByField = "-collection"; }
    }

    if (field_sort.value) {
      if (field_sort.type === "asc") { orderByField = "field"; }
      else if (field_sort.type === "desc") { orderByField = "-field"; }
    }

    if (field_template_sort.value) {
      if (field_template_sort.type === "asc") { orderByField = "field_templat"; }
      else if (field_template_sort.type === "desc") { orderByField = "-field_templat"; }
    }

    filterData = {
      limit: pageNumberRef.current,
      page: page,
      filter: {
        status: { eq: 'published' },
      },
      meta: "filter_count",
      sort: orderByField
    };

    var result = await directus.getItems('order_category_type', filterData);

    if (isMounted.current) {
      setCategoriesData(result.data);
      setTotalPage(result.meta.filter_count);
      setLoadingPagination(false);
      setSortLoading(false);
    }
  };

  const handleClickOpen = (category, mode) => {
    setOpen(true);
    if (mode === "add") {
      setMode('add');
      setTitle('Add Category');
      setText('');
    }
    else if (mode === "edit") {
      setDataCategory({
        id: category.id, name: category.name, collection: category.collection, field: category.field, field_template: category.field_template,
        show_category: category.show_category, show_as_textbox: category.show_as_textbox, required: category.required, custom_filter: category.custom_filter ? JSON.stringify(category.custom_filter) : ''
      })
      setMode('edit');
      setTitle('Edit Category');
      setText('');
    } else {
      setDataCategory({
        id: category.id, name: category.name, collection: category.collection, field: category.field, field_template: category.field_template,
        show_category: category.show_category, show_as_textbox: category.show_as_textbox, required: category.required, custom_filter: category.custom_filter ? JSON.stringify(category.custom_filter) : ''
      })
      setMode('delete');
      setTitle('Delete Category');
      setText('Are you sure?');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setDataCategory({ id: '', name: '', field: '', field_template: '', show_category: false, show_as_textbox: false, required: false, custom_filter: '' })
  };

  const handleChangeInput = (e, type) => {
    if (type === "checkbox") {
      const { name, checked } = e.target;
      setDataCategory({ ...dataCategoryRef.current, [name]: checked })
    } else {
      const { name, value } = e.target;
      setDataCategory({ ...dataCategoryRef.current, [name]: value })
    }
  }

  const SaveData = async () => {
    setLoading(true);
    var custom_filter_edit = dataCategoryRef.current.custom_filter;
    var isFlag = true;
    if (custom_filter_edit) {
      try {
        custom_filter_edit = JSON.parse(custom_filter_edit);
      } catch (e) {
        isFlag = false;
        enqueueSnackbar('Incorrect JSON format for "Custom Filter" field', { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }

    if (isFlag) {
      if (mode === 'edit') {
        try {
          var new_data_edit = dataCategoryRef.current;
          var body_edit = {
            id: new_data_edit.id,
            name: new_data_edit.name,
            collection: new_data_edit.collection,
            field: new_data_edit.field,
            field_template: new_data_edit.field_template,
            show_category: new_data_edit.show_category,
            show_as_textbox: new_data_edit.show_as_textbox,
            required: new_data_edit.required,
            custom_filter: custom_filter_edit ? custom_filter_edit : null,
            status: 'published'
          }
          await directus.updateItem('order_category_type', body_edit.id, body_edit);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      else if (mode === 'add') {
        try {
          var new_data = dataCategoryRef.current;
          var body = {
            name: new_data.name,
            collection: new_data.collection,
            field: new_data.field,
            field_template: new_data.field_template,
            show_category: new_data.show_category,
            show_as_textbox: new_data.show_as_textbox,
            required: new_data.required,
            custom_filter: new_data.custom_filter ? JSON.parse(new_data.custom_filter) : null,
            status: 'published'
          }
          await directus.createItem('order_category_type', body);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      else {
        try {
          await directus.deleteItem('order_category_type', dataCategoryRef.current.id);
          enqueueSnackbar("Data deleted successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      loadCategories();
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  }


  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadCategories();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadCategories();
  };

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "name": { type: "desc", value: false },
      "collection": { type: "desc", value: false },
      "field": { type: "desc", value: false },
      "field_template": { type: "desc", value: false },
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data_ls = localStorage.sort_data;
        if (sort_data_ls) {
          sort_data = JSON.parse(sort_data_ls);
          sort_data.categories = sort_field_data;
        } else {
          sort_data = { categories: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadCategories();
  }

  return (
    <div >
      {categoriesData ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3}>
                      <div className={classes.headerSort} onClick={() => sortData("name")}>
                        <Typography noWrap variant="h5">Name</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['name'].value === true ?
                            sortFieldRef.current['name'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={classes.headerSort} onClick={() => sortData("collection")}>
                        <Typography noWrap variant="h5">Collection</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['collection'].value === true ?
                            sortFieldRef.current['collection'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={classes.headerSort} onClick={() => sortData("field")}>
                        <Typography noWrap variant="h5">Field</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['field'].value === true ?
                            sortFieldRef.current['field'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className={classes.headerSort} onClick={() => sortData("field_template")}>
                        <Typography noWrap variant="h5">Field Template</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['field_template'].value === true ?
                            sortFieldRef.current['field_template'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ textAlign: "center" }}><Typography noWrap variant="h5">Show Category</Typography></div>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ textAlign: "center" }}><Typography noWrap variant="h5">Show as Textbox</Typography></div>
                    </Grid>
                    <Grid item xs={1}>
                      <div style={{ textAlign: "center" }}><Typography noWrap variant="h5">Required</Typography></div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                {props.config_settings === true ?
                  <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                    <AddIcon />
                  </AddButton>
                  : ''}
              </Grid>

            </ListItem>
          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }} >


            {categoriesData.map((category, index) => {
              return (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{category.name ? category.name : ''}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{category.collection}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{category.field}</Typography>
                          </Grid>
                          <Grid item xs={2} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{category.field_template}</Typography>
                          </Grid>
                          <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>{category.show_category ? <CheckRoundedIcon style={{ color: "green" }} /> : <CloseRoundedIcon style={{ color: "#b7b7b7" }} />}</Typography>
                          </Grid>
                          <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>{category.show_as_textbox ? <CheckRoundedIcon style={{ color: "green" }} /> : <CloseRoundedIcon style={{ color: "#b7b7b7" }} />}</Typography>
                          </Grid>
                          <Grid item xs={1} style={{ overflowX: "hidden", textOverflow: "clip", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6" style={{ textAlign: "center" }}>{category.required ? <CheckRoundedIcon style={{ color: "green" }} /> : <CloseRoundedIcon style={{ color: "#b7b7b7" }} />}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    {props.config_settings === true ?
                      <Grid item xs={1} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(category, 'edit')} >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(category, 'delete')} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      : <Grid item xs={2} style={{ textAlign: 'right' }}></Grid>}
                    {/* <IconButton aria-label="edit" 
                          // onClick={() => handleClickOpen(supplier)}
                         >
                          <EditIcon fontSize="small" />
                        </IconButton> */}

                  </ListItem>
                  {categoriesData.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}

            <Dialog
              id="holiday_dialog"
              isopen={`${open}`}
              open={open}
              fullWidth={true}
              onClose={handleClose}
            >
              <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="holiday_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
              <DialogContent>
                <Typography variant="h6">{text ? text : ''}</Typography>
                {mode !== 'delete' ?
                  <div>
                    <TextField
                      id="name_text"
                      label="Name"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="name"
                      value={dataCategoryRef.current.name}
                      onChange={handleChangeInput}
                    />

                    <FormControl fullWidth style={{ marginTop: "16px", marginBottom: "8px" }}>
                      <InputLabel id="collection-input-label" style={{ marginLeft: "14px", marginTop: "-5px" }}>Collection</InputLabel>
                      <Select
                        data-testid="collection"
                        variant='outlined'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dataCategoryRef.current ? dataCategoryRef.current.collection ? dataCategoryRef.current.collection : '' : ''}
                        label="Collection"
                        name="collection"
                        onChange={(e) => handleChangeInput(e)}
                      >
                        <MenuItem value={''}>---</MenuItem>
                        <MenuItem value={'activities'}>Activities</MenuItem>
                        <MenuItem value={'resources'}>Resources</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      id="field_text"
                      label="Field"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="field"
                      placeholder='id'
                      value={dataCategoryRef.current.field}
                      onChange={handleChangeInput}
                    />
                    <TextField
                      id="field_template_text"
                      label="Field Template"
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      name="field_template"
                      placeholder='name'
                      value={dataCategoryRef.current.field_template}
                      onChange={handleChangeInput}
                    />
                    <div style={{ marginTop: "8px", marginBottom: "16px" }}>
                      <FormControlLabel
                        style={{ marginRight: "25px" }}
                        control={
                          <Switch name="show_category" checked={dataCategoryRef.current.show_category === true ? true : false} onClick={(e) => handleChangeInput(e, "checkbox")} />
                        }
                        label="Show Category"
                      />
                      <FormControlLabel
                        style={{ marginRight: "25px" }}
                        control={
                          <Switch name="show_as_textbox" checked={dataCategoryRef.current.show_as_textbox === true ? true : false} onClick={(e) => handleChangeInput(e, "checkbox")} />
                        }
                        label="Show as Textbox"
                      />
                      <FormControlLabel
                        control={
                          <Switch name="required" checked={dataCategoryRef.current.required === true ? true : false} onClick={(e) => handleChangeInput(e, "checkbox")} />
                        }
                        label="Required"
                      />
                    </div>
                    <TextareaAutosize
                      id="custom_filter_text"
                      data-testid="custom_filter"
                      label="Custom Filter"
                      minRows={2}
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      placeholder='{ "status": "published" }'
                      name="custom_filter"
                      value={dataCategoryRef.current.custom_filter ? dataCategoryRef.current.custom_filter : ''}
                      onChange={handleChangeInput}
                      style={{
                        maxWidth: '100%',
                        minWidth: '100%',
                        minHeight: '32px',
                        borderRadius: '8px',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        fontFamily: 'SF Pro',
                        padding: '10px 14px',
                        height: '55px',
                      }}
                    />
                  </div>
                  : ''}
              </DialogContent>
              <DialogActions style={{ height: "60px" }}>
                {loading ?
                  <CircularProgress className={classes.circular} />
                  :
                  <div>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id="save_btn"
                      disabled={mode === 'delete' ? false : disable}
                      size="small"
                      variant="contained"
                      onClick={() => SaveData()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                  </div>
                }

              </DialogActions>
            </Dialog>
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      {categoriesData ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '10px', fontFamily: 'SF Pro' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            variant="outlined"
            size="small"
            disabled={page === 1 || loadingPagination}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </div>
  );
}

export default Categories;