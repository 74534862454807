/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  withStyles,
  Button,
  MenuItem,
  TextField,
  List,
  ListItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogActions,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
  DialogContent,
  InputBase,
  InputAdornment
} from '@material-ui/core';
import TopBar from './TopBar';
import directus from 'src/services/directus';
import useStateRef from 'react-usestateref';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import moment from 'moment';
import Box from '@mui/material/Box';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Chip from '@mui/material/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

import Enumerable from 'linq';
import EventEmitter from 'src/utils/EventEmitter';

import { InvoiceAdminPermission, POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import { getEmployee } from 'src/utils/sessions';
import { CancelButton, CompletedButton } from 'src/components/ReusableStyle';
import { useStyles } from './style.js';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { getCache, removeCache, setCache } from 'src/utils/KeyValueCache';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { configurationFields } from 'src/components/ReusableFunction';

export const ResetButton = withStyles({
  root: {
    borderRadius: '10px',
    backgroundColor: '#F2F2F6',
    color: '#374355',
    height: '24px',
    width: 'calc(50% - 5px)',
    minWidth: 'calc(50% - 5px)',
    boxShadow: 'none',
    textTransform: 'initial',
    fontFamily: 'SF Pro',
    paddingLeft: '10px',
    paddingRight: '10px',
    whiteSpace: 'nowrap'
  }
})(Button);

export const FilterButton = withStyles({
  root: {
    borderRadius: '10px',
    backgroundColor: '#F2F2F6',
    color: '#374355',
    height: '24px',
    width: '100%',
    boxShadow: 'none',
    textTransform: 'initial',
    fontFamily: 'SF Pro',
    paddingLeft: '10px',
    paddingRight: '10px',
  }
})(Button);

export const EditableFilterButton = withStyles({
  root: {
    borderRadius: '10px',
    border: '1px solid #82CB43',
    backgroundColor: '#F2F2F6',
    color: '#374355',
    height: '24px',
    width: '100%',
    boxShadow: 'none',
    textTransform: 'initial',
    fontFamily: 'SF Pro',
    paddingLeft: '10px',
    paddingRight: '10px',
  }
})(Button);

var directusUser, user, company;

try {
  directusUser = JSON.parse(window.localStorage.getItem('directus_user'));
  company = JSON.parse(window.localStorage.getItem('company'));

  var role = directusUser.role.name;

  if (directusUser.role.name === 'Administrator') {
    role = 'Super Admin';
  }

  user = {
    avatar: directusUser.avatar ? directus.config.url + directusUser.avatar.data.asset_url : '',
    jobTitle: directusUser.email,
    role: role,
    name: (directusUser.first_name || '') + ' ' + (directusUser.last_name || '')
  };
} catch (error) {
  if (
    !window.localStorage.getItem('directus_user') &&
    !window.localStorage.getItem('directus_employee') &&
    !window.location.pathname.startsWith('/sign-in') &&
    !window.location.pathname.startsWith('/sign-up') &&
    !window.location.pathname.startsWith('/passwordreset')
  ) {
    window.location.href = `//${window.location.hostname}:${window.location.port}/sign-in`;
  }
}

const DashboardLayout = () => {
  /* eslint-disable no-unused-vars*/
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [selectedOrderType, setSelectedOrderType, selectedOrderTypeRef] = useStateRef(null);

  const orderDate = localStorage.orders_date ? JSON.parse(localStorage.orders_date) : null;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isFilter, setIsFilter, isFilterRef] = useStateRef(
    window.location.pathname === '/' || window.location.pathname.includes('order') || window.location.pathname === '/invoices' ? true : false
  );
  const [selectedDateIndex, setSelectedDateIndex, selectedDateIndexRef] = useStateRef(
    orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 6) : 6
  );
  const [customDateText, setCustomDateText, customDateTextRef] = useStateRef('Custom');

  const [dateRangeCustom, setDateRangeCustom, dateRangeCustomRef] = useStateRef(
    orderDate
      ? [orderDate.start_date, orderDate.end_date]
      : [
        moment()
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD 00:00:00'),
        moment()
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD 23:59:59')
      ]
  );
  const [dateRangeCustomTemp, setDateRangeCustomTemp, dateRangeCustomTempRef] = useStateRef(
    orderDate
      ? [orderDate.start_date, orderDate.end_date]
      : [
        moment()
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD 00:00:00'),
        moment()
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD 23:59:59')
      ]
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Date created state
  const dateCreatedListRef = React.useRef();
  const [anchorDateCreated, setAnchorDateCreated] = React.useState(null);
  const openDateCreated = Boolean(anchorDateCreated);
  const [selectedDateCreatedIndex, setSelectedDateCreatedIndex, selectedDateCreatedIndexRef] = useStateRef(
    orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 6) : 6
  );
  const [openSelectCustomDateCreated, setOpenSelectCustomDateCreated] = useState(false);
  const [dateCreatedRangeCustom, setDateCreatedRangeCustom, dateCreatedRangeCustomRef] = useStateRef(
    orderDate
      ? [orderDate.start_date, orderDate.end_date]
      : [
        moment()
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD 00:00:00'),
        moment()
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD 23:59:59')
      ]
  );
  const [dateCreatedRangeCustomTemp, setDateCreatedRangeCustomTemp, dateCreatedRangeCustomTempRef] = useStateRef(
    orderDate
      ? [orderDate.start_date, orderDate.end_date]
      : [
        moment()
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD 00:00:00'),
        moment()
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD 23:59:59')
      ]
  );

  // Date approved state
  const dateApprovedListRef = React.useRef();
  const [anchorDateApproved, setAnchorDateApproved] = React.useState(null);
  const openDateApproved = Boolean(anchorDateApproved);
  const [selectedDateApprovedIndex, setSelectedDateApprovedIndex, selectedDateApprovedIndexRef] = useStateRef(
    orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 1) : 1
  );
  const [openSelectCustomDateApproved, setOpenSelectCustomDateApproved] = useState(false);
  const [dateApprovedRangeCustom, setDateApprovedRangeCustom, dateApprovedRangeCustomRef] = useStateRef(
    orderDate
      ? [orderDate.start_date, orderDate.end_date]
      : [
        moment()
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD 00:00:00'),
        moment()
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD 23:59:59')
      ]
  );
  const [dateApprovedRangeCustomTemp, setDateApprovedRangeCustomTemp, dateApprovedRangeCustomTempRef] = useStateRef(
    orderDate
      ? [orderDate.start_date, orderDate.end_date]
      : [
        moment()
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD 00:00:00'),
        moment()
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD 23:59:59')
      ]
  );

  const [openSelectCustom, setOpenSelectCustom] = useState(false);
  const [isMyOrder, setIsMyOrder, isMyOrderRef] = useStateRef(POApproverPermission() ? false : true);
  const [includeArchivedOrders, setIncludeArchivedOrders, includeArchivedOrdersRef] = useStateRef(
    localStorage.includeArchivedOrders ? (localStorage.includeArchivedOrders === 'true' ? true : false) : false
  );
  const [includeArchivedInvoices, setIncludeArchivedInvoices, includeArchivedInvoicesRef] = useStateRef(
    localStorage.includeArchivedInvoices ? (localStorage.includeArchivedInvoices === 'true' ? true : false) : false
  );
  const [isMultiSelect, setIsMultiSelect, isMultiSelectRef] = useStateRef(
    localStorage.isMultiSelect ? (localStorage.isMultiSelect === 'true' ? true : false) : false
  );

  const [selectedOrderStatusIndex, setSelectedOrderStatusIndex, selectedOrderStatusIndexRef] = useStateRef([0]);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const [anchorElPopup2, setAnchorElPopup2] = React.useState(null);
  const openPopup2 = Boolean(anchorElPopup2);

  const [selectedInvoiceStatusIndex, setSelectedInvoiceStatusIndex, selectedInvoiceStatusIndexRef] = useStateRef([0]);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);
  const [anchorElPopup3, setAnchorElPopup3] = React.useState(null);
  const openPopup3 = Boolean(anchorElPopup3);

  const [customers, setCustomers, customersRef] = useStateRef(null);
  const [customers2, setCustomers2, customersRef2] = useStateRef(null);
  const [suppliers, setSuppliers, suppliersRef] = useStateRef(null);
  const [suppliers2, setSuppliers2, suppliersRef2] = useStateRef(null);

  const [employees, setEmployees, employeesRef] = useStateRef(null);
  const [employees2, setEmployees2, employeesRef2] = useStateRef(null);

  const [POCategories, setPOCategories, POCategoriesRef] = useStateRef(null);
  const [POCategories2, setPOCategories2, POCategoriesRef2] = useStateRef(null);

  const [changeTab, setChangeTab, changeTabRef] = useStateRef(window.location.pathname);

  //Supplier
  const [selectedSupplierIndex, setSelectedSupplierIndex, selectedSupplierIndexRef] = useStateRef(['0']);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const open4 = Boolean(anchorEl4);
  const [anchorElPopup4, setAnchorElPopup4] = React.useState(null);
  const openPopup4 = Boolean(anchorElPopup4);

  //Customer
  const [selectedCustomerIndex, setSelectedCustomerIndex, selectedCustomerIndexRef] = useStateRef(['0']);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const open5 = Boolean(anchorEl5);
  const [anchorElPopup5, setAnchorElPopup5] = React.useState(null);
  const openPopup5 = Boolean(anchorElPopup5);

  //Employee
  const [selectedEmployeeIndex, setSelectedEmployeeIndex, selectedEmployeeIndexRef] = useStateRef(['0']);
  const [anchorElEmployee, setAnchorElEmployee] = React.useState(null);
  const openEmployee = Boolean(anchorElEmployee);
  const [anchorElPopupEmployee, setAnchorElPopupEmployee] = React.useState(null);
  const openPopupEmployee = Boolean(anchorElPopupEmployee);
  const filterRefEmployee = React.useRef();
  const employeeListRef = React.useRef();

  //PO Category
  const [selectedPOCategoryIndex, setSelectedPOCategoryIndex, selectedPOCategoryIndexRef] = useStateRef(['0']);
  const [anchorElPOCategory, setAnchorElPOCategory] = React.useState(null);
  const openPOCategory = Boolean(anchorElPOCategory);
  const [anchorElPopupPOCategory, setAnchorElPopupPOCategory] = React.useState(null);
  const openPopupPOCategory = Boolean(anchorElPopupPOCategory);
  const filterRefPOCategory = React.useRef();
  const POCategoryListRef = React.useRef();

  // PO Category Description
  const [poCategoryDescription, setPOCategoryDescription, poCategoryDescriptionRef] = useStateRef('');

  const [selectedMatchedPOIndex, setSelectedMatchedPOIndex, selectedMatchedPOIndexRef] = useStateRef([0]);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const open6 = Boolean(anchorEl6);
  const [anchorElPopup6, setAnchorElPopup6] = React.useState(null);
  const openPopup6 = Boolean(anchorElPopup6);

  const [selectedMatchedInvoiceIndex, setSelectedMatchedInvoiceIndex, selectedMatchedInvoiceIndexRef] = useStateRef([0]);
  const [anchorEl7, setAnchorEl7] = React.useState(null);
  const open7 = Boolean(anchorEl7);
  const [anchorElPopup7, setAnchorElPopup7] = React.useState(null);
  const openPopup7 = Boolean(anchorElPopup7);

  const dateListRef = React.useRef();
  const orderListRef = React.useRef();
  const invoiceListRef = React.useRef();
  const supplierListRef = React.useRef();
  const filterRefSupplier = React.useRef();
  const filterRefCustomer = React.useRef();
  const customerListRef = React.useRef();
  const matchedPORef = React.useRef();
  const matchedInvoiceRef = React.useRef();

  const [openQuickFilter, setOpenQuickFilter] = useState(false);

  const [quickFilterName, setQuickFilterName] = useState('');

  const [searchingLoading, setSearchingLoading, searchingLoadingRef] = useStateRef({
    resetFilter: false,
    applyFilter: false,
    saveQuickFilter: false,
    editQuickFilter: false,
    deleteQuickFilter: false,
    selectedFilter: false,
    saveQuickFilterDialog: false
  });

  const [quickFilter, setQuickFilter, quickFilterRef] = useStateRef([]);
  const [quickFilterOnEditing, setQuickFilterOnEditing, quickFilterOnEditingRef] = useStateRef([]);
  const [selectedQuickFilter, setSelectedQuickFilter, selectedQuickFilterRef] = useStateRef(null);

  const [isEditQuickFilter, setIsEditQuickFilter, isEditQuickFilterRef] = useStateRef(false);
  const [quickFilterLoading, setQuickFilterLoading, quickFilterLoadingRef] = useStateRef(false);

  const [isAnyPrice, setIsAnyPrice, isAnyPriceRef] = useStateRef(true);

  const [priceFilter, setPriceFilter, priceFilterRef] = useStateRef({
    min: 0,
    max: 0
  });

  const options = ['Date', 'All Dates', 'Custom', 'This Month', 'Last Month', 'Last 30 days', 'Last 3 months'];

  const orderOptionsDefault = [
    { name: 'All Order Status', background: '#FFFFFF', value: 'all' },
    { name: 'Draft', background: '#D4E3F2', value: 'draft' },
    { name: 'Sent', background: '#B7FAF6', value: 'sent' },
    { name: 'Completed', background: '#DAF0DC', value: 'completed' },
    { name: 'Cancelled', background: '#FFDBD9', value: 'cancelled' }
  ];

  const orderOptionsDefaultWithApproval = [
    { name: 'All Order Status', background: '#FFFFFF', value: 'all' },
    { name: 'Draft', background: '#D4E3F2', value: 'draft' },
    { name: 'Awaiting Approval', background: '#FFF0DD', value: 'awaiting_approval' },
    { name: 'Sent', background: '#B7FAF6', value: 'sent' },
    { name: 'Completed', background: '#DAF0DC', value: 'completed' },
    { name: 'Rejected', background: '#ffdbd9', value: 'rejected' },
    { name: 'Cancelled', background: '#FFDBD9', value: 'cancelled' },
  ];

  const [options2, setOptions2, options2Ref] = useStateRef(orderOptionsDefault);

  const options3 = [
    { name: 'All Invoice Status', background: '#FFFFFF', value: 'all' },
    { name: 'New', background: '#FFF0DD', value: 'unmatched' },
    { name: 'Awaiting Approval', background: '#D4E3F2', value: 'matched' },
    { name: 'Awaiting Approval 2', background: '#D4E3F2', value: 'awaiting_approval_level_2' },
    { name: 'Complete', background: '#D0F2D4', value: 'approved' },
    { name: 'Disputed', background: '#FFDBD9', value: 'disputed' }
  ];

  const options6 = [
    { name: 'All', background: '#FFFFFF', value: 'all' },
    { name: 'Matched', background: '#D0F2D4', value: 'matched' },
    { name: 'Unmatched', background: '#FFF0DD', value: 'unmatched' }
  ];

  const [showInvoiceTab, setShowInvoiceTab, showInvoiceTabsRef] = useStateRef(false);
  const [isOrderTypeEqualsToSalesOrders, setIsOrderTypeEqualsToSalesOrders, isOrderTypeEqualsToSalesOrdersRef] = useStateRef(false);
  const [showCustomerFilter, setShowCustomerFilter, showCustomerFilterRef] = useStateRef(false);
  const [showCategoryFilter, setShowCategoryFilter, showCategoryFilterRef] = useStateRef(false);

  const [selectAllData, setSelectAllData, selectAllDataRef] = useStateRef({
    supplier: true,
    customer: true,
    employee: true,
    po_category: true
  })
  /* eslint-enable no-unused-vars*/

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadData();
      loadCustomer();
      loadSupplier();
      loadEmployee();
      loadQuickFilter();
      loadPOCategoryType();
      getConfigurationSettings();
    }

    setIsOrderTypeEqualsToSalesOrders(specifyOrderType() === 'sales-orders');
  });

  useEffect(() => {
    onCheckShowHideFilter();
  }, [window.location.pathname]);

  useEffect(() => {
    window.onpopstate = e => {
      onCheckShowHideFilter();
      
      let pathname = window.location.pathname;
      pathname = pathname.replace(/\/(purchase-orders|sales-orders|approvals-history|approvals)\/\d+$/, "/$1/:id");

      EventEmitter.emit('reload_item', { value: pathname });
    };
  });

  const onCheckShowHideFilter = args => {
    let emp_data = JSON.parse(window.localStorage.getItem('directus_employee'));

    let location = window.location.pathname;

    var show_filter = false;
    var show_filter_default = false;
    if (!localStorage.getItem('openFilter')) {
      localStorage.setItem('openFilter', true);
      show_filter_default = true;
    }

    if (localStorage.getItem('openFilter') === 'true' || show_filter_default) {
      if (args) {
        if (args.value && args.value !== true) {
          location = args.value;
        }
      }
      if (location === '/' || location.includes('order') || location === '/invoices') {
        show_filter = true;
      }
    }
    if (emp_data) {
      if (emp_data.filter_bar_always_displayed && (location === '/' || location.includes('order') || location === '/invoices')) {
        show_filter = true;
      }
    }

    setIsFilter(show_filter);
  };

  useEffect(() => {
    const onTabClick = eventData => {
      setChangeTab(eventData.value);
      if (eventData.value === '/settings') {
        setIsFilter(false);
      }
    };

    const listener = EventEmitter.addListener('tab_click', onTabClick);

    const onHideFilter = eventData => {
      setIsFilter(false);
    };

    const listener2 = EventEmitter.addListener('hideFilter', onHideFilter);

    const listener3 = EventEmitter.addListener('checkShowHideFilter', onCheckShowHideFilter);

    const stopSearchingLoading = () => {
      setSearchingLoading({
        resetFilter: false,
        applyFilter: false,
        saveQuickFilter: false,
        editQuickFilter: false,
        deleteQuickFilter: false,
        selectedFilter: false,
        saveQuickFilterDialog: false
      });
    };

    const listener4 = EventEmitter.addListener('searching_loading', stopSearchingLoading);

    const listener5 = EventEmitter.addListener('loadSupplier', loadSupplier);
    const listener6 = EventEmitter.addListener('loadCustomer', loadCustomer);

    const reloadProfile = eventData => {
      var role = directusUser.role.name;

      if (directusUser.role.name === 'Administrator') {
        role = 'Super Admin';
      }

      user.role = role;
    };
    const listener7 = EventEmitter.addListener('reload_profile', reloadProfile);

    const onUpdateCustomerSupplier = eventData => {
      loadCustomer();
      loadSupplier();
    };

    const listener8 = EventEmitter.addListener('update_filter_customer_supplier', onUpdateCustomerSupplier);

    const onChangeOrderType = eventData => {
      setIsOrderTypeEqualsToSalesOrders(eventData.type === 'sales-orders');
      loadQuickFilter(false, eventData.type);
    };

    const listener9 = EventEmitter.addListener('change_order_type', onChangeOrderType);

    const selectedOrderChange = () => {
      loadQuickFilter();
    };

    const listener10 = EventEmitter.addListener('set_selected_order_type', selectedOrderChange);

    return () => {
      listener.remove();
      listener2.remove();
      listener3.remove();
      listener4.remove();
      listener5.remove();
      listener6.remove();
      listener7.remove();
      listener8.remove();
      listener9.remove();
      listener10.remove();
    };
  }, []);

  const getConfigurationSettings = async () => {
    try {
      var result = await directus.getItems('configuration', { fields: configurationFields});
      window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
      if (result.data.length > 0) {
        if (result.data[0]) {
          if (result.data[0].show_invoices === true) {
            setShowInvoiceTab(true);
          }
          if (result.data[0].show_po_account === true) {
            setShowCustomerFilter(true);
          }
          if (result.data[0].show_po_internal_category === true) {
            setShowCategoryFilter(true);
          }
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  }

  const loadData = () => {
    let configuration = JSON.parse(window.localStorage.getItem('configuration'));

    if (configuration != null && configuration.enable_approvals) {
      setOptions2(orderOptionsDefaultWithApproval);
    } else {
      setOptions2(orderOptionsDefault);
    }

    var filterData = localStorage.filter_data;
    if (filterData) {
      filterData = JSON.parse(filterData);

      var selected_order = [];
      filterData.order.forEach(val => {
        let obj = options2Ref.current.find(o => o.value === val);
        let index = options2Ref.current.indexOf(obj);
        selected_order.push(index);
      });

      var selected_invoice = [];
      filterData.invoice.forEach(val => {
        var obj = options3.find(o => o.value === val);
        var index = options3.indexOf(obj);
        selected_invoice.push(index);
      });

      var selected_matched_po = [];
      if (filterData.matched_po) {
        filterData.matched_po.forEach(val => {
          var obj = options6.find(o => o.value === val);
          var index = options6.indexOf(obj);
          selected_matched_po.push(index);
        });
        if (filterData.matched_po.length === 0) {
          selected_matched_po.push(0);
        }
      } else {
        selected_matched_po.push(0);
      }

      var selected_matched_invoice = [];
      if (filterData.matched_invoice) {
        filterData.matched_invoice.forEach(val => {
          var obj = options6.find(o => o.value === val);
          var index = options6.indexOf(obj);
          selected_matched_invoice.push(index);
        });
        if (filterData.matched_po.length === 0) {
          selected_matched_invoice.push(0);
        }
      } else {
        selected_matched_invoice.push(0);
      }

      if (filterData.isAnyPrice == false) {
        setIsAnyPrice(false);
      } else {
        setIsAnyPrice(true);
      }

      if (filterData.price) {
        setPriceFilter({
          min: filterData.price.min,
          max: filterData.price.max
        });
      }

      var employee_filter = ['0'];
      if (filterData.employee) {
        employee_filter = filterData.employee;
      }

      var po_category_filter = ['0'];
      if (filterData.po_category) {
        po_category_filter = filterData.po_category;
      }

      if (filterData.poCategoryDescription) {
        setPOCategoryDescription(filterData.poCategoryDescription);
      }

      if (filterData.date_created) {
        setSelectedDateCreatedIndex(filterData.date_created);
      } else {
        setSelectedDateCreatedIndex(orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 6) : 6);
      }

      if (filterData.date_created_range) {
        setDateCreatedRangeCustom(filterData.date_created_range);
      } else {
        setDateCreatedRangeCustom(
          orderDate
            ? [orderDate.start_date, orderDate.end_date]
            : [
              moment()
                .clone()
                .startOf('isoWeek')
                .format('YYYY-MM-DD 00:00:00'),
              moment()
                .clone()
                .endOf('isoWeek')
                .format('YYYY-MM-DD 23:59:59')
            ]
        );
      }

      if (filterData.date_approved) {
        setSelectedDateApprovedIndex(filterData.date_approved);
      } else {
        setSelectedDateApprovedIndex(orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 1) : 1);
      }

      if (filterData.date_approved_range) {
        setDateApprovedRangeCustom(filterData.date_approved_range);
      } else {
        setDateApprovedRangeCustom(
          orderDate
            ? [orderDate.start_date, orderDate.end_date]
            : [
              moment()
                .clone()
                .startOf('isoWeek')
                .format('YYYY-MM-DD 00:00:00'),
              moment()
                .clone()
                .endOf('isoWeek')
                .format('YYYY-MM-DD 23:59:59')
            ]
        );
      }

      setSelectedDateIndex(filterData.date);
      setDateRangeCustom(filterData.date_range);
      setSelectedOrderStatusIndex(selected_order);
      setSelectedInvoiceStatusIndex(selected_invoice);
      setSelectedSupplierIndex(filterData.supplier);
      setSelectedCustomerIndex(filterData.customer);
      setSelectedEmployeeIndex(employee_filter);
      setSelectedPOCategoryIndex(po_category_filter);

      // if (POApproverPermission()) {
      setIsMyOrder(filterData.isMyOrder);
      // } else {
      //   setIsMyOrder(true);
      // }
      setSelectedMatchedPOIndex(selected_matched_po);
      setSelectedMatchedInvoiceIndex(selected_matched_invoice);
    }
  };

  const loadCustomer = async () => {
    var employee_id = await getEmployee();

    var Customers = null;
    if (POCreatorPermission()) {
      let Employee = JSON.parse(window.localStorage.getItem('directus_employee'));

      var customer_status = {
        "in": ["published"]
      }

      if (Employee) {
        if (Employee.search_deleted_customers) {
          customer_status = {
            "in": ["published", "deleted"]
          }
        }
      }

      var CustomersResult = await directus.api.get('/custom/settings/customers', {
        filter: {
          'customers.status': customer_status
        },
        fields: 'id, email, code, name, legal_name, selectable, access_level, accounting_system, status',
        meta: "filter_count",
        limit: -1,
        page: 1,
      });

      Customers = CustomersResult;

      Customers.data = Enumerable.from(Customers.data)
        .orderBy(o => o.code)
        .toArray();

      Customers = Customers.data;
    } else {
      Customers = await directus.getItems('customers_approvers_level1', {
        fields: 'id, customer.id, customer.selectable, customer.code, customer.name, customer.status',
        filter: {
          employee: { in: employee_id.id },
          'customer.id': { nnull: null },
          'customer.status': 'published'
        }
      });

      if (Customers.data.length > 0) {
        const result = Enumerable.from(Customers.data)
          .select(data => data.customer)
          .toArray();
        Customers = result;
      } else {
        Customers = [];
      }

      var Customers2 = await directus.getItems('customers_approvers_level2', {
        fields: 'id, customer.id, customer.selectable, customer.code, customer.name, customer.status',
        filter: {
          employee: { in: employee_id.id },
          'customer.id': { nnull: null },
          'customer.status': 'published'
        }
      });

      if (Customers2.data.length > 0) {
        const result = Enumerable.from(Customers2.data)
          .select(data => data.customer)
          .toArray();

        Customers2 = result;
      } else {
        Customers2 = [];
      }

      if (Customers.length === 0) {
        Customers = Customers2;
      } else {
        if (Customers2.length !== 0) {
          var Customers1_id = Enumerable.from(Customers)
            .select(data => data.id)
            .toArray();

          Customers2 = Enumerable.from(Customers2)
            .where(w => !Enumerable.from(Customers1_id).contains(w.id))
            .toArray();
          Customers = [...Customers, ...Customers2];
        }
      }

      if (Customers.length > 0) {
        Customers = Enumerable.from(Customers)
          .orderBy(o => o.name)
          .toArray();
      }
    }

    var customer_data = [];
    // customer_data.push({ id: '0', name: 'All Customers', code: 'All Customers' });
    if (Customers.length > 0) {
      customer_data = [...customer_data.concat(Customers)];
    }

    setCustomers(customer_data);
    setCustomers2(customer_data);

    loadFirstData("customer", customer_data)
  };

  const loadSupplier = async () => {
    var Suppliers = { data: [] };
    let Employee = JSON.parse(window.localStorage.getItem('directus_employee'));

    var supplier_status = {
      "in": ["published"]
    }

    if (Employee) {
      if (Employee.search_deleted_suppliers) {
        supplier_status = {
          "in": ["published", "deleted"]
        }
      }
    }

    var SuppliersResult = await directus.api.get('/custom/settings/suppliers', {
      filter: {
        'suppliers.status': supplier_status
      },
      fields: 'id, code, name, status, email',
      meta: "filter_count",
      limit: -1,
      page: 1,
    });


    Suppliers = SuppliersResult;

    Suppliers.data = Enumerable.from(Suppliers.data)
      .orderBy(o => o.code)
      .toArray();

    var supplier_data = [];
    if (Suppliers.data.length > 0) {
      supplier_data = [...supplier_data.concat(Suppliers.data)];
    }
    setSuppliers(supplier_data);
    setSuppliers2(supplier_data);

    loadFirstData("supplier", supplier_data)
  };

  const loadEmployee = async () => {
    var employeeCache = getCache('employees2');

    let Employees = { data: [] };
    if (!employeeCache) {
      Employees = await directus.getItems('employees', {
        fields: 'id, code, user.first_name, user.last_name, user.id, user.*.*',
        sort: 'code',
        limit: -1,
        filter: {
          status: 'published',
          'user.role.name': {
            in: ['PO Creator', 'PO Approver', 'Invoice Admin', 'Super Admin']
          },
          'user.status': {
            nin: ''
          }
        }
      });

      setCache('employees2', Employees.data);
    } else {
      Employees = { data: employeeCache };
    }

    var employee_data = [];
    // employee_data.push({ id: '0', name: 'All Employees', code: 'All Employees', user: { id: '0' } });
    if (Employees.data.length > 0) {
      employee_data = [...employee_data.concat(Employees.data)];
    }

    employee_data = Enumerable.from(employee_data)
      .where(x => x.user)
      .toArray();

    setEmployees(employee_data);
    setEmployees2(employee_data);

    loadFirstData("employee", employee_data)
  };

  const loadPOCategoryType = async () => {
    var POCategoryCache = getCache('order_category_type');

    var POCategoriesData = { data: [] };
    if (!POCategoryCache) {
      POCategoriesData = await directus.getItems('order_category_type', {
        fields: '*',
        sort: 'name'
      });

      setCache('order_category_type', POCategoriesData.data);
    } else {
      POCategoriesData = { data: POCategoryCache };
    }

    var po_category_data = [];
    // po_category_data.push({ id: '0', name: 'All Categories' });
    if (POCategoriesData.data.length > 0) {
      po_category_data = [...po_category_data.concat(POCategoriesData.data)];
    }

    setPOCategories(po_category_data);
    setPOCategories2(po_category_data);

    loadFirstData("po_category", po_category_data)
  };

  const loadFirstData = (type, data) => {
    var filterData = localStorage.filter_data;

    var isShowAllData = true;
    if (filterData) {
      filterData = JSON.parse(filterData);

      var filter_data_type = filterData[type];

      if (filter_data_type && filter_data_type != undefined) {
        if (filter_data_type.length > 0) {
          if (!filter_data_type.includes("0")) {
            isShowAllData = false;
          }
        }
      }
    }

    if (isShowAllData) {
      if (type === "supplier") setSelectedSupplierIndex(['0']);
      else if (type === "customer") setSelectedCustomerIndex(['0']);
      else if (type === "employee") setSelectedEmployeeIndex(['0']);
      else if (type === "po_category") setSelectedPOCategoryIndex(['0']);
    } else {
      setSelectAllData({ ...selectAllDataRef.current, [type]: false });
    }

  }

  const specifyOrderType = (selectedOrderType = null) => {
    let pathname = window.location.pathname;
    let pathnameLast = window.location.pathname.split('/').pop();

    if (selectedOrderType == null) {
      if (pathnameLast === '' || pathname.includes('purchase-orders') || pathnameLast == 'invoices') {
        selectedOrderType = 'purchase-orders';
      } else if (pathname.includes('sales-orders')) {
        selectedOrderType = 'sales-orders';
      }
    }

    return selectedOrderType;
  }


  const loadQuickFilter = async (forceReload = false, selectedOrderType = null) => {
    selectedOrderType = specifyOrderType(selectedOrderType);
    setSelectedOrderType(selectedOrderType);

    if (forceReload) {
      removeCache('directus_employee');
      removeCache('directus_user');
    }

    var employee_id = await getEmployee(forceReload);

    if (selectedOrderType == 'purchase-orders') {
      if (employee_id.quick_filter_po) {
        var quick_filter = employee_id.quick_filter_po;
        var quick_filter_key = Object.keys(employee_id.quick_filter_po);
        var quick_filter_body = [];
        quick_filter_key.forEach(value => {
          const filter = JSON.parse(quick_filter[value]);
          quick_filter_body.push({
            id: filter.id_filter ?? uuidv4(),
            name: value,
            filter: filter,
            checked: false
          });
        });

        setQuickFilter(quick_filter_body);
      } else {
        setQuickFilter([]);
      }
    } else if (selectedOrderType == 'sales-orders') {
      if (employee_id.quick_filter_so) {
        let quick_filter = employee_id.quick_filter_so;
        let quick_filter_key = Object.keys(employee_id.quick_filter_so);
        let quick_filter_body = [];
        quick_filter_key.forEach(value => {
          const filter = JSON.parse(quick_filter[value]);
          quick_filter_body.push({
            id: filter.id_filter ?? uuidv4(),
            name: value,
            filter: filter,
            checked: false
          });
        });

        setQuickFilter(quick_filter_body);
      } else {
        setQuickFilter([]);
      }
    }

    setSearchingLoading({ ...searchingLoadingRef.current, saveQuickFilterDialog: false });
  };

  const openFilter = () => {
    window.localStorage.setItem('openFilter', !isFilterRef.current);
    setIsFilter(!isFilterRef.current);
  };

  const handleChangeDate = value => {
    setDateRangeCustomTemp([moment(value[0]).format('YYYY-MM-DD 00:00:00'), moment(value[1]).format('YYYY-MM-DD 23:59:59')]);
  };

  const clickDateRangeChip = () => {
    setOpenSelectCustom(true);
    setDateRangeCustomTemp(dateRangeCustomRef.current);
    setAnchorEl(null);
  };

  const handleMenuItemClick = index => {
    setSelectedDateIndex(index);
    setAnchorEl(null);
  };

  const cancelSelectCustomDialog = () => {
    setOpenSelectCustom(false);
    setDateRangeCustomTemp(dateRangeCustomRef.current);
  };

  const saveSelectCustomDialog = () => {
    setOpenSelectCustom(false);
    setDateRangeCustom(dateRangeCustomTempRef.current);
  };

  const handleChangeDateCreated = value => {
    setDateCreatedRangeCustomTemp([moment(value[0]).format('YYYY-MM-DD 00:00:00'), moment(value[1]).format('YYYY-MM-DD 23:59:59')]);
  };

  const clickDateCreatedRangeChip = () => {
    setOpenSelectCustomDateCreated(true);
    setDateCreatedRangeCustomTemp(dateCreatedRangeCustomRef.current);
    setAnchorDateCreated(null);
  };

  const cancelSelectCustomDateCreatedDialog = () => {
    setOpenSelectCustomDateCreated(false);
    setDateCreatedRangeCustomTemp(dateCreatedRangeCustomRef.current);
  };

  const saveSelectCustomDateCreatedDialog = () => {
    setOpenSelectCustomDateCreated(false);
    setDateCreatedRangeCustom(dateCreatedRangeCustomTempRef.current);
  };

  const handleMenuItemDateCreatedClick = index => {
    setSelectedDateCreatedIndex(index);
    setAnchorDateCreated(null);
  };

  const handleChangeDateApproved = value => {
    setDateApprovedRangeCustomTemp([moment(value[0]).format('YYYY-MM-DD 00:00:00'), moment(value[1]).format('YYYY-MM-DD 23:59:59')]);
  };

  const clickDateApprovedRangeChip = () => {
    setOpenSelectCustomDateApproved(true);
    setDateApprovedRangeCustomTemp(dateApprovedRangeCustomRef.current);
    setAnchorDateApproved(null);
  };

  const cancelSelectCustomDateApprovedDialog = () => {
    setOpenSelectCustomDateApproved(false);
    setDateApprovedRangeCustomTemp(dateApprovedRangeCustomRef.current);
  };

  const saveSelectCustomDateApprovedDialog = () => {
    setOpenSelectCustomDateApproved(false);
    setDateApprovedRangeCustom(dateApprovedRangeCustomTempRef.current);
  };

  const handleMenuItemDateApprovedClick = index => {
    setSelectedDateApprovedIndex(index);
    setAnchorDateApproved(null);
  };

  const stopPropagation = e => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const checkKeyDown = (e, type, isFocusInput) => {
    var flag = false;

    if (!isFocusInput) {
      if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
        flag = true;
      }
    } else {
      if (e.key === 'Tab' || e.key === 'ArrowRight') {
        flag = true;
        e.stopPropagation();
        e.preventDefault();
      }
    }

    if (flag) {
      if (type === 1) {
        filterRefSupplier.current.focus();
      } else if (type === 2) {
        filterRefCustomer.current.focus();
      } else if (type === 3) {
        filterRefEmployee.current.focus();
      } else if (type === 4) {
        filterRefPOCategory.current.focus();
      }
    }
  };

  const displayData = type => {
    var selected_data_index = [];
    var data_ref_2 = [];
    var data_list = [];
    var anchor_data = null;
    var popup_name = null;

    const list = [];
    var countLeft = 0;
    var maxData = 2;

    if (type === 'supplier') {
      selected_data_index = selectedSupplierIndexRef.current;
      data_ref_2 = suppliersRef2.current;
      data_list = supplierListRef.current;
      popup_name = openPopup4;
      anchor_data = anchorElPopup4;
    } else if (type === 'customer') {
      selected_data_index = selectedCustomerIndexRef.current;
      data_ref_2 = customersRef2.current;
      data_list = customerListRef.current;
      popup_name = openPopup5;
      anchor_data = anchorElPopup5;
    } else if (type === 'employee') {
      selected_data_index = selectedEmployeeIndexRef.current;
      data_ref_2 = employeesRef2.current;
      data_list = employeeListRef.current;
      popup_name = openPopupEmployee;
      anchor_data = anchorElPopupEmployee;
    } else if (type === 'po_category') {
      selected_data_index = selectedPOCategoryIndexRef.current;
      data_ref_2 = POCategoriesRef2.current;
      data_list = POCategoryListRef.current;
      popup_name = openPopupPOCategory;
      anchor_data = anchorElPopupPOCategory;
    } else if (type === 'order_status') {
      selected_data_index = selectedOrderStatusIndexRef.current;
      data_list = orderListRef.current;
      popup_name = openPopup2;
      anchor_data = anchorElPopup2;

      if (selectedOrderStatusIndexRef.current.length > 1) {
        if (
          (selectedOrderStatusIndexRef.current[0] === 3 && selectedOrderStatusIndexRef.current[1] === 4) ||
          (selectedOrderStatusIndexRef.current[0] === 4 && selectedOrderStatusIndexRef.current[1] === 3)
        ) {
          maxData = 1;
        } else {
          if (selectedOrderStatusIndexRef.current.length > 2) {
            if (
              selectedOrderStatusIndexRef.current[0] === 3 ||
              selectedOrderStatusIndexRef.current[1] === 4 ||
              selectedOrderStatusIndexRef.current[0] === 4 ||
              selectedOrderStatusIndexRef.current[1] === 3
            ) {
              maxData = 1;
            } else {
            }
          }
        }
      }
    } else if (type === 'invoice_status') {
      selected_data_index = selectedInvoiceStatusIndexRef.current;
      data_list = invoiceListRef.current;
      popup_name = openPopup3;
      anchor_data = anchorElPopup3;

      if (selectedInvoiceStatusIndexRef.current.length > 1) {
        if (selectedInvoiceStatusIndexRef.current[1] === 3 || selectedInvoiceStatusIndexRef.current[0] === 3) {
          maxData = 1;
        } else {
          if (selectedInvoiceStatusIndexRef.current.length > 2) {
            maxData = 1;
          }
        }
      }
    } else if (type === 'matched_po') {
      selected_data_index = selectedMatchedPOIndexRef.current;
      data_list = matchedPORef.current;
      popup_name = openPopup6;
      anchor_data = anchorElPopup6;
    } else if (type === 'matched_invoice') {
      selected_data_index = selectedMatchedInvoiceIndexRef.current;
      data_list = matchedInvoiceRef.current;
      popup_name = openPopup7;
      anchor_data = anchorElPopup7;
    }

    if (type !== 'order_status' && type !== 'invoice_status' && type !== 'matched_po' && type !== 'matched_invoice') {
      if (selected_data_index.length > maxData) {
        countLeft = selected_data_index.length - 2;
      }
    }

    const showData = [];
    const hideData = [];

    selected_data_index.forEach((value, index) => {
      var data_name = '';
      var backgroundColor = '#D4E3F2';

      if (type === 'order_status') {
        data_name = options2Ref.current[value] ? options2Ref.current[value].name : '';
        backgroundColor = options2Ref.current[value] ? options2Ref.current[value].background : '';
      } else if (type === 'invoice_status') {
        data_name = options3[value] ? options3[value].name : '';
        backgroundColor = options3[value] ? options3[value].background : '';
      } else if (type === 'matched_po' || type === 'matched_invoice') {
        data_name = options6[value] ? options6[value].name : '';
        backgroundColor = options6[value] ? options6[value].background : '';
      } else {
        var data = data_ref_2.find(function (sup) {
          if (type === 'employee') {
            return sup.user ? sup.user.id === value : false;
          } else {
            return sup.id === value;
          }
        });

        if (type === 'po_category') {
          data_name = data ? data.name : '';
        } else if (type === 'employee') {
          data_name = data ? (data.code ? data.code : data.user ? data.user.first_name : '') : '';
        } else {
          data_name = data ? (data.code ? data.code : data.name) : '';
        }
      }

      if (maxData !== 0) {
        showData.push(
          <div
            key={index}
            className={classes.selectedOrderStatus}
            style={{
              marginLeft: index !== 0 ? '5px' : '0px',
              backgroundColor: backgroundColor
            }}
          >
            <div
              style={{
                marginRight: '4px',
                maxWidth:
                  type === 'order_status' || type === 'invoice_status' || type === 'matched_po' || type === 'matched_invoice' ? 'none' : '50px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {data_name}
            </div>
            <CancelIcon onClick={() => deleteData(index, type)} className={classes.cancelIcon} style={{ fontSize: '12px', height: '18px' }} />
          </div>
        );
        maxData--;
      } else {
        if (type === 'order_status' || type === 'invoice_status' || type === 'matched_po') {
          countLeft++;
        }
        hideData.push(
          <div
            key={index}
            className={classes.selectedOrderStatus}
            aria-describedby={popup_name ? 'simple-popover' : undefined}
            style={{
              marginLeft: index !== 0 ? '5px' : '0px',
              backgroundColor: backgroundColor,
              marginTop: '2.5px',
              marginBottom: '2.5px'
            }}
          >
            <div style={{ marginRight: '4px' }}>{data_name}</div>
            <CancelIcon onClick={() => deleteData(index, type)} className={classes.cancelIcon} style={{ fontSize: '12px', height: '18px' }} />
          </div>
        );
      }
    });

    if (countLeft) {
      showData.push(
        <div key="left_data" className={`${classes.selectedOrderStatus} ${classes.leftData}`} onClick={e => setAnchorData(data_list, type)}>
          <div style={{ marginRight: '4px' }}>+{countLeft}</div>
        </div>
      );
    }

    if (showData.length > 0) {
      list.push(showData);
    }

    if (hideData.length > 0) {
      list.push(
        <div key="popup">
          <Menu
            id="lock-menu"
            anchorEl={anchor_data ? anchor_data : null}
            open={popup_name}
            onClose={() => setAnchorData(null, type)}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}
          >
            <div
              style={{
                margin: '10px',
                marginLeft: '5px',
                display: 'flex',
                maxWidth: '240px',
                flexWrap: 'wrap'
              }}
            >
              {hideData}
            </div>
          </Menu>
        </div>
      );
    }

    return list;
  };

  const setAnchorData = (value, type) => {
    if (type === 'supplier') {
      setAnchorElPopup4(value);
    } else if (type === 'customer') {
      setAnchorElPopup5(value);
    } else if (type === 'employee') {
      setAnchorElPopupEmployee(value);
    } else if (type === 'po_category') {
      setAnchorElPopupPOCategory(value);
    } else if (type === 'order_status') {
      setAnchorElPopup2(value);
    } else if (type === 'invoice_status') {
      setAnchorElPopup3(value);
    } else if (type === 'matched_po') {
      setAnchorElPopup6(value);
    } else if (type === 'matched_invoice') {
      setAnchorElPopup7(value);
    }
  };

  const getAllData = type => {
    var allData = null;

    if (type === 'order_status') {
      allData = selectedOrderStatusIndexRef.current;
    } else if (type === 'invoice_status') {
      allData = selectedInvoiceStatusIndexRef.current;
    } else if (type === 'matched_invoice') {
      allData = selectedMatchedInvoiceIndexRef.current;
    } else if (type === 'matched_po') {
      allData = selectedMatchedPOIndexRef.current;
    } else if (type === 'supplier') {
      allData = selectedSupplierIndexRef.current;
    } else if (type === 'customer') {
      allData = selectedCustomerIndexRef.current;
    } else if (type === 'employee') {
      allData = selectedEmployeeIndexRef.current;
    } else if (type === 'po_category') {
      allData = selectedPOCategoryIndexRef.current;
    }

    return allData;
  };

  const setNewData = (type, newData) => {
    if (type === 'order_status') {
      setSelectedOrderStatusIndex(newData);
    } else if (type === 'invoice_status') {
      setSelectedInvoiceStatusIndex(newData);
    } else if (type === 'matched_invoice') {
      setSelectedMatchedInvoiceIndex(newData);
    } else if (type === 'matched_po') {
      setSelectedMatchedPOIndex(newData);
    } else if (type === 'supplier') {
      setSelectedSupplierIndex(newData);
    } else if (type === 'customer') {
      setSelectedCustomerIndex(newData);
    } else if (type === 'employee') {
      setSelectedEmployeeIndex(newData);
    } else if (type === 'po_category') {
      setSelectedPOCategoryIndex(newData);
    }

    if (type === "customer" || type === "supplier" || type === "employee" || type === "po_category") {
      setSelectAllData({ ...selectAllDataRef.current, [type]: false })
    }
  };

  const handleMenuItemClickData = (option, type) => {
    var selected_index = getAllData(type);

    var selected_data = option;
    var default_data = 0;

    if (type === 'order_status' || type === 'invoice_status' || type === 'matched_po' || type === 'matched_invoice') {
      default_data = 0;
    } else {
      if (type === 'employee') {
        if (option.user) {
          selected_data = option.user.id;
        }
      } else {
        selected_data = option.id;
      }
      default_data = '0';
    }

    var newData = [default_data];

    if (selected_data !== default_data) {
      if (selected_index.indexOf(selected_data) === -1) {
        if (selected_index[0] === default_data) {
          newData = [selected_data];
        } else {
          newData = [...selected_index.concat(selected_data)];
        }
      } else {
        var allData = selected_index;
        allData.splice(selected_index.indexOf(selected_data), 1);
        if (allData[0]) {
          newData = [...allData];
        }
      }
    }

    setNewData(type, newData);
  };

  const saveData = (e, type) => {
    if (e.key === 'Enter') {
      var data_ref = [];
      var data_ref_2 = [];

      if (type === 'supplier') {
        data_ref = suppliersRef.current;
        data_ref_2 = suppliersRef2.current;
      } else if (type === 'customer') {
        data_ref = customersRef.current;
        data_ref_2 = customersRef2.current;
      } else if (type === 'employee') {
        data_ref = employeesRef.current;
        data_ref_2 = employeesRef2.current;
      } else if (type === 'po_category') {
        data_ref = POCategoriesRef.current;
        data_ref_2 = POCategoriesRef2.current;
      }

      if (data_ref && data_ref_2) {
        if (data_ref.length > 0) {
          if (data_ref.length != data_ref_2.length || data_ref_2.length === 1) {
            handleMenuItemClickData(data_ref[0], type);
          }
        }
      }
    }
  };

  const deleteData = (index, type) => {
    var allData = getAllData(type);

    allData.splice(index, 1);
    var newData = ['0'];

    if (type === 'order_status' || type === 'invoice_status' || type === 'matched_po' || type === 'matched_invoice') {
      newData = [0];
    }

    if (allData[0]) {
      newData = [...allData];
    }

    setNewData(type, newData);
  };

  const searchData = (e, type) => {
    var value = e.target.value.toLowerCase();
    var data_ref_2 = [];

    if (type === 'supplier') {
      data_ref_2 = suppliersRef2.current;
    } else if (type === 'customer') {
      data_ref_2 = customersRef2.current;
    } else if (type === 'employee') {
      data_ref_2 = employeesRef2.current;
    } else if (type === 'po_category') {
      data_ref_2 = POCategoriesRef2.current;
    }

    var newData = data_ref_2;

    if (value) {
      var result = [];

      if (type === 'employee') {
        result = Enumerable.from(employeesRef2.current)
          .where(
            w =>
              (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
              (w.user
                ? w.user.first_name
                  ? w.user.first_name.toLowerCase().includes(value)
                  : ''.toLowerCase().includes(value)
                : ''.toLowerCase().includes(value)) ||
              (w.user
                ? w.user.last_name
                  ? w.user.last_name.toLowerCase().includes(value)
                  : ''.toLowerCase().includes(value)
                : ''.toLowerCase().includes(value)) ||
              (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
          )
          .toArray();
      } else if (type === 'po_category') {
        result = Enumerable.from(data_ref_2)
          .where(w => (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)))
          .toArray();
      } else {
        result = Enumerable.from(data_ref_2)
          .where(
            w =>
              (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
              (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
          )
          .toArray();
      }

      if (result.length === 0) {
        result = [];
      }

      newData = result;
    }

    if (type === 'supplier') {
      setSuppliers(newData);
    } else if (type === 'customer') {
      setCustomers(newData);
    } else if (type === 'employee') {
      setEmployees(newData);
    } else if (type === 'po_category') {
      setPOCategories(newData);
    }
  };

  const clickAllData = (type) => {
    setSelectAllData({ ...selectAllDataRef.current, [type]: true });

    if (type === "supplier") setSelectedSupplierIndex(['0']);
    else if (type === "customer") setSelectedCustomerIndex(['0']);
    else if (type === "employee") setSelectedEmployeeIndex(['0']);
    else if (type === "po_category") setSelectedPOCategoryIndex(['0']);
  }

  const handleClickAllData = (type, show_all_data, data_ref_2) => {
    var all_data = [];
    if (!show_all_data) {
      if (type === "employee") {
        all_data = Enumerable.from(data_ref_2)
          .where(x => x.user)
          .select(s => s.user.id)
          .toArray();
      } else {
        all_data = Enumerable.from(data_ref_2)
          .select(s => s.id)
          .toArray();
      }
    }

    setSelectAllData({ ...selectAllDataRef.current, [type]: show_all_data });
    if (type === "supplier") setSelectedSupplierIndex(all_data);
    else if (type === "customer") setSelectedCustomerIndex(all_data);
    else if (type === "employee") setSelectedEmployeeIndex(all_data);
    else if (type === "po_category") setSelectedPOCategoryIndex(all_data);

  }

  const displayMenuData = type => {
    var anchor_data = null;
    var popup_name = null;
    var key_down_num = 0;
    var data_ref = [];
    var filter_ref = null;
    var selected_data_index = [];
    var data_list_ref = null;
    var data_ref_2 = [];
    var default_menu = '';
    var default_value = '0';

    if (type === 'supplier') {
      anchor_data = anchorEl4;
      popup_name = open4;
      key_down_num = 1;
      data_ref = suppliersRef.current;
      data_ref_2 = suppliersRef2.current;
      filter_ref = filterRefSupplier;
      selected_data_index = selectedSupplierIndexRef.current;
      data_list_ref = supplierListRef;
    } else if (type === 'customer') {
      anchor_data = anchorEl5;
      popup_name = open5;
      key_down_num = 2;
      data_ref = customersRef.current;
      data_ref_2 = customersRef2.current;
      filter_ref = filterRefCustomer;
      selected_data_index = selectedCustomerIndexRef.current;
      data_list_ref = customerListRef;
    } else if (type === 'employee') {
      anchor_data = anchorElEmployee;
      popup_name = openEmployee;
      key_down_num = 3;
      data_ref = employeesRef.current;
      data_ref_2 = employeesRef2.current;
      filter_ref = filterRefEmployee;
      selected_data_index = selectedEmployeeIndexRef.current;
      data_list_ref = employeeListRef;
    } else if (type === 'po_category') {
      anchor_data = anchorElPOCategory;
      popup_name = openPOCategory;
      key_down_num = 4;
      data_ref = POCategoriesRef.current;
      data_ref_2 = POCategoriesRef2.current;
      filter_ref = filterRefPOCategory;
      selected_data_index = selectedPOCategoryIndexRef.current;
      data_list_ref = POCategoryListRef;
    }

    if (data_ref_2) {
      if (type === 'customer') default_menu = "All Customers"
      else if (type === 'supplier') default_menu = "All Suppliers"
      else if (type === 'employee') default_menu = "All Employees"
      else if (type === 'po_category') default_menu = "All Categories"
      else default_menu = data_ref_2[0].code;
    }

    var show_all_data = false;
    var show_all_data_display = false;
    if (type === "customer" || type === "supplier" || type === "employee" || type === "po_category") {
      if (data_ref_2) {
        if (selected_data_index.length === data_ref_2.length) {
          show_all_data = true;
          show_all_data_display = true
        }
        else if (selected_data_index.length > 0 && selected_data_index[0] != default_value) {
          show_all_data_display = true;
        }
      }
    } else {
      show_all_data = selected_data_index[0] === default_value;
    }

    return (
      <div key={type}>
        <Menu
          disableAutoFocus
          disableAutoFocusItem
          id="lock-menu"
          anchorEl={anchor_data}
          open={popup_name}
          onClose={() => {
            clickMenuData(type, null, true);
          }}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
            style: { paddingTop: '0px' }
          }}
          PaperProps={{
            style: {
              maxHeight: '300px',
              maxWidth: '260px',
              width: '260px'
            }
          }}
          style={{ paddingTop: '0px' }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onKeyDown={e => checkKeyDown(e, key_down_num)}
        >
          {data_ref ? (
            <div
              style={{ position: 'sticky', top: '0', zIndex: '9999', background: 'white', height: '40px' }}
              onKeyDown={e => checkKeyDown(e, key_down_num, true)}
            >
              <TextField
                autoFocus
                inputRef={filter_ref}
                onKeyDown={e => {
                  stopPropagation(e);
                  saveData(e, type);
                }}
                style={{ width: '100%', padding: '5px 16px 0', height: '40px' }}
                onChange={e => searchData(e, type)}
              />
            </div>
          ) : null}
          {type === "customer" || type === "supplier" || type === "employee" || type === "po_category" ?
            <MenuItem
              style={{ height: '30px', paddingLeft: '7px', width: '240px' }}
              onClick={() => clickAllData(type)}
            >
              <Checkbox checked={selectAllDataRef.current[type]} />
              <div
                style={{
                  textOverflow: 'ellipsis',
                  overflowX: 'clip',
                  fontSize: '12px',
                  color: "black"
                }}
              >
                {default_menu}
              </div>
            </MenuItem>
            : ''
          }

          {type === "customer" || type === "supplier" || type === "employee" || type === "po_category" ?
            <div style={{ height: "1px", backgroundColor: "#d9d9d9", width: "100%", marginTop: "5px", marginBottom: "5px" }}></div>
            : ''}

          {type === "customer" || type === "supplier" || type === "employee" || type === "po_category" ?
            <MenuItem
              style={{ height: '30px', paddingLeft: '7px', width: '240px' }}
              onClick={() => handleClickAllData(type, show_all_data, data_ref_2)}
            >
              <Checkbox checked={show_all_data} />
              <div
                style={{
                  textOverflow: 'ellipsis',
                  overflowX: 'clip',
                  fontSize: '12px',
                  color: "black"
                }}
              >
                Select All
              </div>
            </MenuItem>
            : ''
          }
          {data_ref
            ? data_ref.map((option, index) => (
              <MenuItem
                className="select_menu"
                style={{ height: '30px', paddingLeft: '7px', width: '240px' }}
                key={index}
                selected={selected_data_index.indexOf(getDataId(option, type)) > -1}
                onClick={() => handleMenuItemClickData(option, type)}
              >
                <Checkbox checked={selected_data_index.indexOf(getDataId(option, type)) > -1} />
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    overflowX: 'clip',
                    fontSize: '12px',
                    color: option.status === "deleted" ? "red" : "black"
                  }}
                >
                  {getMenuDataName(option, type)}
                </div>
              </MenuItem>
            ))
            : null}
        </Menu>

        <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
          <ListItem
            className={classes.selectDate}
            style={{ paddingLeft: show_all_data || show_all_data_display ? '5px' : '10px', paddingRight: '4px' }}
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-expanded={popup_name ? 'true' : undefined}
          >
            <div
              ref={data_list_ref}
              style={{
                fontSize: '12px',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                maxHeight: '26px'
              }}
            >
              <div style={{ overflowY: 'hidden', width: 'calc(100% - 20px)', fontWeight: '400' }}>
                {(type === "customer" || type === "supplier" || type === "employee" || type === "po_category") && selectAllDataRef.current[type] ?
                  <div style={{ lineHeight: '26px' }}>{default_menu}</div>
                  :
                  data_ref_2 ? (
                    show_all_data ? (
                      <div style={{ lineHeight: '26px' }}>{default_menu}</div>
                    ) :
                      show_all_data_display ?
                        (<div style={{ overflow: 'hidden', display: 'flex', marginTop: '4px' }}>{displayData(type)}</div>)
                        : ''
                  ) : (
                    ''
                  )}
              </div>
              <div className={classes.arrowDropDown} onClick={e => clickMenuData(type, data_list_ref.current)}>
                <ArrowDropDownIcon style={{ fontSize: '18px', height: '26px' }} />
              </div>
            </div>
          </ListItem>
        </List>
      </div>
    );
  };

  const clickMenuData = (type, value, isCloseMenu) => {
    if (type === 'supplier') {
      setAnchorEl4(value);
      if (isCloseMenu) {
        setSuppliers(suppliersRef2.current);
      }
    } else if (type === 'customer') {
      setAnchorEl5(value);
      if (isCloseMenu) {
        setCustomers(customersRef2.current);
      }
    } else if (type === 'employee') {
      setAnchorElEmployee(value);
      if (isCloseMenu) {
        setEmployees(employeesRef2.current);
      }
    } else if (type === 'po_category') {
      setAnchorElPOCategory(value);
      if (isCloseMenu) {
        setPOCategories(POCategoriesRef2.current);
      }
    } else if (type === 'order_status') {
      setAnchorEl2(value);
    } else if (type === 'invoice_status') {
      setAnchorEl3(value);
    } else if (type === 'matched_po') {
      setAnchorEl6(value);
    } else if (type === 'matched_invoice') {
      setAnchorEl7(value);
    }
  };

  const getMenuDataName = (option, type) => {
    var data_name = '';

    if (type === 'supplier' || type === 'customer') {
      data_name = option.id === '0' ? option.name : `${option.code} - ${option.name}`;
    } else if (type === 'employee') {
      if (option.id === '0') {
        data_name = option.name;
      } else {
        if (option.code !== null) {
          data_name = option.code;
        }

        if (option.user) {
          data_name += ' - ';
          if (option.user.first_name) {
            data_name += option.user.first_name + ' ';
          }

          if (option.user.last_name) {
            data_name += option.user.last_name + ' ';
          }
        }
      }
    } else if (type === 'po_category') {
      data_name = option.name;
    }

    return data_name;
  };

  const getDataId = (option, type) => {
    var data_id = '';

    if (type === 'employee') {
      if (option.user) {
        data_id = option.user.id;
      }
    } else {
      data_id = option.id;
    }

    return data_id;
  };

  const displayMenuDataWithoutSearch = type => {
    var selected_data_index = [];
    var popup_name = null;
    var anchor_data = null;
    var data_list_ref = null;
    var data_list = [];

    if (type === 'order_status') {
      selected_data_index = selectedOrderStatusIndexRef.current;
      popup_name = open2;
      anchor_data = anchorEl2;
      data_list_ref = orderListRef;
      data_list = options2Ref.current;
    } else if (type === 'invoice_status') {
      selected_data_index = selectedInvoiceStatusIndexRef.current;
      popup_name = open3;
      anchor_data = anchorEl3;
      data_list_ref = invoiceListRef;
      data_list = options3;
    } else if (type === 'matched_po') {
      selected_data_index = selectedMatchedPOIndexRef.current;
      popup_name = open6;
      anchor_data = anchorEl6;
      data_list_ref = matchedPORef;
      data_list = options6;
    } else if (type === 'matched_invoice') {
      selected_data_index = selectedMatchedInvoiceIndexRef.current;
      popup_name = open7;
      anchor_data = anchorEl7;
      data_list_ref = matchedInvoiceRef;
      data_list = options6;
    }

    return (
      <div>
        <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
          <ListItem
            className={classes.selectDate}
            style={{ paddingLeft: selected_data_index[0] !== 0 ? '5px' : '10px', paddingRight: '4px' }}
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-expanded={popup_name ? 'true' : undefined}
          >
            <div
              ref={data_list_ref}
              style={{
                fontSize: '12px',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                maxHeight: '24px'
              }}
            >
              <div style={{ overflowY: 'hidden', width: 'calc(100% - 20px)', fontWeight: '400' }}>
                {selected_data_index[0] === 0 ? (
                  <div style={{ lineHeight: '24px' }}>{data_list[0].name}</div>
                ) : (
                  <div style={{ overflow: 'hidden', display: 'flex', marginTop: '3px' }}>{displayData(type)}</div>
                )}
              </div>
              <div className={classes.arrowDropDown} onClick={e => clickMenuData(type, data_list_ref.current)}>
                <ArrowDropDownIcon style={{ fontSize: '18px', height: '24px' }} />
              </div>
            </div>
          </ListItem>
        </List>

        <Menu
          id="lock-menu"
          anchorEl={anchor_data}
          open={popup_name}
          onClose={() => clickMenuData(type, null)}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox'
          }}
          PaperProps={{
            style: {
              maxWidth: '240px',
              width: '240px'
            }
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {data_list.map((option, index) => (
            <MenuItem
              style={{ height: '30px', paddingLeft: '7px', fontSize: '12px' }}
              key={index}
              selected={selected_data_index.indexOf(index) > -1}
              onClick={() => handleMenuItemClickData(index, type)}
            >
              <Checkbox checked={selected_data_index.indexOf(index) > -1} /> {option.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const resetFilter = () => {
    setSelectedDateIndex(6);
    setSelectedDateCreatedIndex(6);
    setSelectedDateApprovedIndex(1);
    setSelectedOrderStatusIndex([0]);
    setSelectedInvoiceStatusIndex([0]);
    // handleClickAllData("supplier", false, suppliersRef2.current);
    // handleClickAllData("customer", false, customersRef2.current);
    // handleClickAllData("employee", false, employeesRef2.current);
    // handleClickAllData("po_category", false, POCategoriesRef2.current);
    setSelectedSupplierIndex(['0']);
    setSelectedCustomerIndex(['0']);
    setSelectedEmployeeIndex(['0']);
    setSelectedPOCategoryIndex(['0']);
    setSelectAllData({ ...selectAllDataRef.current, supplier: true, customer: true, employee: true, po_category: true });

    if (POApproverPermission()) {
      setIsMyOrder(false);
    } else {
      setIsMyOrder(true);
    }
    setSelectedQuickFilter(null);
    setSelectedMatchedPOIndex([0]);
    setSelectedMatchedInvoiceIndex([0]);
    setIsAnyPrice(true);
    setPriceFilter({
      min: 0,
      max: 0
    });
    setPOCategoryDescription('');
    filterData('resetFilter');
  };

  const filterData = params => {
    /* eslint-disable array-callback-return*/
    setSearchingLoading({
      ...searchingLoadingRef.current,
      [params]: true
    });

    var selected_order = [];
    selectedOrderStatusIndexRef.current.map(val => {
      selected_order.push(options2Ref.current[val].value);
    });

    var selected_invoice = [];
    selectedInvoiceStatusIndexRef.current.map(val => {
      selected_invoice.push(options3[val].value);
    });

    var selected_matched_po = [];
    selectedMatchedPOIndexRef.current.map(val => {
      selected_matched_po.push(options6[val].value);
    });

    var selected_matched_invoice = [];
    selectedMatchedInvoiceIndexRef.current.map(val => {
      selected_matched_invoice.push(options6[val].value);
    });

    var filter_data = {
      date: selectedDateIndexRef.current,
      date_range: dateRangeCustomRef.current,
      date_created: selectedDateCreatedIndexRef.current,
      date_created_range: dateCreatedRangeCustomRef.current,
      date_approved: selectedDateApprovedIndexRef.current,
      date_approved_range: dateApprovedRangeCustomRef.current,
      order: selected_order,
      invoice: selected_invoice,
      supplier: suppliersRef2.current ? selectedSupplierIndexRef.current.length != suppliersRef2.current.length ? selectedSupplierIndexRef.current : ['0'] : ['0'],
      customer: customersRef2.current ? selectedCustomerIndexRef.current.length != customersRef2.current.length ? selectedCustomerIndexRef.current : ['0'] : ['0'],
      employee: employeesRef2.current ? selectedEmployeeIndexRef.current.length != employeesRef2.current.length ? selectedEmployeeIndexRef.current : ['0'] : ['0'],
      po_category: POCategoriesRef2.current ? selectedPOCategoryIndexRef.current.length != POCategoriesRef2.current.length ? selectedPOCategoryIndexRef.current : ['0'] : ['0'],
      isMyOrder: isMyOrderRef.current,
      includeArchivedOrders: includeArchivedOrdersRef.current,
      includeArchivedInvoices: includeArchivedInvoicesRef.current,
      matched_po: selected_matched_po,
      matched_invoice: selected_matched_invoice,
      isAnyPrice: isAnyPriceRef.current,
      price: priceFilterRef.current,
      po_category_description: poCategoryDescriptionRef.current
    };

    EventEmitter.emit('filter_data', filter_data);

    localStorage.setItem('filter_data', JSON.stringify(filter_data));
    /* eslint-enable array-callback-return*/
  };

  const saveFilter = () => {
    setSearchingLoading({
      ...searchingLoadingRef.current,
      saveQuickFilter: true
    });

    setOpenQuickFilter(true);
    setQuickFilterName('');
  };

  const handleCloseSaveQuickFilterDialog = () => {
    setOpenQuickFilter(false);
    setSearchingLoading({
      ...searchingLoadingRef.current,
      saveQuickFilter: false
    });
  };

  const changeQuickFilterName = e => {
    setQuickFilterName(e.target.value);
  };

  const saveQuickFilter = async () => {
    setSearchingLoading({ ...searchingLoadingRef.current, saveQuickFilterDialog: true });
    var selected_order = [];
    selectedOrderStatusIndexRef.current.forEach(val => {
      selected_order.push(options2Ref.current[val].value);
    });

    var selected_invoice = [];
    selectedInvoiceStatusIndexRef.current.forEach(val => {
      selected_invoice.push(options3[val].value);
    });

    var selected_matched_po = [];
    if (selectedMatchedPOIndexRef.current.length === 1) {
      if (selectedMatchedPOIndexRef.current[0] !== 'all') {
        selectedMatchedPOIndexRef.current.forEach(val => {
          selected_matched_po.push(options6[val].value);
        });
      }
    }

    var selected_matched_invoice = [];
    if (selectedMatchedInvoiceIndexRef.current.length === 1) {
      if (selectedMatchedInvoiceIndexRef.current[0] !== 'all') {
        selectedMatchedInvoiceIndexRef.current.forEach(val => {
          selected_matched_invoice.push(options6[val].value);
        });
      }
    }

    var filter_data = {
      date: selectedDateIndexRef.current,
      date_range: dateRangeCustomRef.current,
      date_created: selectedDateCreatedIndexRef.current,
      date_created_range: dateCreatedRangeCustomRef.current,
      date_approved: selectedDateApprovedIndexRef.current,
      date_approved_range: dateApprovedRangeCustomRef.current,
      order: selected_order,
      invoice: selected_invoice,
      supplier: selectedSupplierIndexRef.current,
      customer: selectedCustomerIndexRef.current,
      employee: selectedEmployeeIndexRef.current,
      po_category: selectedPOCategoryIndexRef.current,
      po_category_description: poCategoryDescriptionRef.current,
      isMyOrder: isMyOrderRef.current,
      matched_po: selected_matched_po,
      matched_invoice: selected_matched_invoice,
      isAnyPrice: isAnyPriceRef.current,
      price: priceFilterRef.current
    };

    var employee_id = await getEmployee();
    var quick_filter_data = {};

    quickFilterRef.current.forEach(value => {
      value.filter.id_filter = uuidv4();
      quick_filter_data[value.name] = JSON.stringify(value.filter);
    });

    quick_filter_data[quickFilterName] = JSON.stringify(filter_data);

    if (selectedOrderTypeRef.current === 'purchase-orders') {
      await directus.updateItem('employees', employee_id.id, { quick_filter_po: quick_filter_data });
    } else {
      await directus.updateItem('employees', employee_id.id, { quick_filter_so: quick_filter_data });
    }

    setOpenQuickFilter(false);
    setSearchingLoading({
      ...searchingLoadingRef.current,
      saveQuickFilter: false
    });

    loadQuickFilter(true);
    setIsEditQuickFilter(false);
  };

  const onSelectedDateFilterChange = (value, index) => {
    setQuickFilterLoading(true);
    var selected_quick_filter = quickFilterRef.current;
    var checked = selected_quick_filter[index].checked;
    selected_quick_filter[index].checked = !checked;

    setQuickFilter(selected_quick_filter);
    setQuickFilterLoading(false);
  };

  const onQuickFilterClick = (value, index) => {
    var filterData = value.filter;
    if (filterData) {
      EventEmitter.emit('filter_data', filterData);
      localStorage.setItem('filter_data', JSON.stringify(filterData));

      var selected_order = [];
      filterData.order.forEach(val => {
        let obj = options2Ref.current.find(o => o.value === val);
        let index = options2Ref.current.indexOf(obj);
        selected_order.push(index);
      });

      var selected_invoice = [];
      filterData.invoice.forEach(val => {
        var obj = options3.find(o => o.value === val);
        var index = options3.indexOf(obj);
        selected_invoice.push(index);
      });

      var selected_matched_po = [];
      if (filterData.matched_po) {
        filterData.matched_po.forEach(val => {
          var obj = options6.find(o => o.value === val);
          var index = options6.indexOf(obj);
          selected_matched_po.push(index);
        });
        if (filterData.matched_po.length === 0) {
          selected_matched_po.push(0);
        }
      } else {
        selected_matched_po.push(0);
      }

      var selected_matched_invoice = [];
      if (filterData.matched_invoice) {
        filterData.matched_invoice.forEach(val => {
          var obj = options6.find(o => o.value === val);
          var index = options6.indexOf(obj);
          selected_matched_invoice.push(index);
        });
        if (filterData.matched_invoice.length === 0) {
          selected_matched_invoice.push(0);
        }
      } else {
        selected_matched_invoice.push(0);
      }

      var employee_filter = ['0'];
      if (filterData.employee) {
        employee_filter = filterData.employee;
      } else {
        filterData.employee = ['0'];
      }

      var po_category_filter = ['0'];
      if (filterData.po_category) {
        po_category_filter = filterData.po_category;
      } else {
        filterData.po_category = ['0'];
      }

      var show_all_supplier = false;
      if (filterData.supplier) {
        if (filterData.supplier.length === 1) {
          if (filterData.supplier[0] === "0") {
            show_all_supplier = true;
          }
        }
      }

      var show_all_customer = false;
      if (filterData.customer) {
        if (filterData.customer.length === 1) {
          if (filterData.customer[0] === "0") {
            show_all_customer = true;
          }
        }
      }

      var show_all_employee = false;
      if (employee_filter) {
        if (employee_filter.length === 1) {
          if (employee_filter[0] === "0") {
            show_all_employee = true;
          }
        }
      }

      var show_all_po_category = false;
      if (po_category_filter) {
        if (po_category_filter.length === 1) {
          if (po_category_filter[0] === "0") {
            show_all_po_category = true;
          }
        }
      }

      setSelectAllData({
        ...selectAllDataRef.current,
        supplier: show_all_supplier,
        customer: show_all_customer,
        employee: show_all_employee,
        po_category: show_all_po_category
      })

      if (filterData.date_created) {
        setSelectedDateCreatedIndex(filterData.date_created);
      } else {
        setSelectedDateCreatedIndex(orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 1) : 1);
      }

      if (filterData.date_created_range) {
        setDateCreatedRangeCustom(filterData.date_created_range);
      } else {
        setDateCreatedRangeCustom(
          orderDate
            ? [orderDate.start_date, orderDate.end_date]
            : [
              moment()
                .clone()
                .startOf('isoWeek')
                .format('YYYY-MM-DD 00:00:00'),
              moment()
                .clone()
                .endOf('isoWeek')
                .format('YYYY-MM-DD 23:59:59')
            ]
        );
      }

      if (filterData.date_approved) {
        setSelectedDateApprovedIndex(filterData.date_approved);
      } else {
        setSelectedDateApprovedIndex(orderDate ? (orderDate.custom ? 2 : orderDate.quick_filter ? orderDate.quick_filter_index : 1) : 1);
      }

      if (filterData.date_approved_range) {
        setDateApprovedRangeCustom(filterData.date_approved_range);
      } else {
        setDateApprovedRangeCustom(
          orderDate
            ? [orderDate.start_date, orderDate.end_date]
            : [
              moment()
                .clone()
                .startOf('isoWeek')
                .format('YYYY-MM-DD 00:00:00'),
              moment()
                .clone()
                .endOf('isoWeek')
                .format('YYYY-MM-DD 23:59:59')
            ]
        );
      }

      setSelectedDateIndex(filterData.date);
      setDateRangeCustom(filterData.date_range);
      setSelectedOrderStatusIndex(selected_order);
      setSelectedInvoiceStatusIndex(selected_invoice);

      setSelectedSupplierIndex(filterData.supplier);
      setSelectedCustomerIndex(filterData.customer);
      setSelectedEmployeeIndex(employee_filter);
      setSelectedPOCategoryIndex(po_category_filter);

      if (filterData.supplier === undefined || filterData.supplier === null || filterData.supplier.length === 0 || filterData.supplier.includes("0")) {
        loadFirstData("supplier", suppliersRef2.current);
      }

      if (filterData.customer === undefined || filterData.customer === null || filterData.customer.length === 0 || filterData.customer.includes("0")) {
        loadFirstData("customer", customersRef2.current);
      }

      if (employee_filter === undefined || employee_filter === null || employee_filter.length === 0 || employee_filter.includes("0")) {
        loadFirstData("employee", employeesRef2.current);
      }

      if (po_category_filter === undefined || po_category_filter === null || po_category_filter.length === 0 || po_category_filter.includes("0")) {
        loadFirstData("po_category", POCategoriesRef2.current);
      }

      setPOCategoryDescription(filterData.po_category_description);
      setIsMyOrder(filterData.isMyOrder);
      setSelectedMatchedPOIndex(selected_matched_po);
      setSelectedMatchedInvoiceIndex(selected_matched_invoice);
      setSelectedQuickFilter(value.id);
      setSearchingLoading({
        ...searchingLoadingRef.current,
        applyFilter: true,
        selectedFilter: true
      });
    }
  };

  const deleteQuickFilter = async () => {
    setSearchingLoading({ ...searchingLoadingRef.current, deleteQuickFilter: true });
    var quick_filter_data = {};
    var isDelete = false;
    var isSelected = { id_filter: selectedQuickFilterRef.current, status: true };

    await quickFilterRef.current.forEach((value, index) => {
      if (!value.checked) {
        if (selectedQuickFilterRef.current === value.id) {
          isSelected.id_filter = value.id;
        }
        quick_filter_data[value.name] = JSON.stringify(value.filter);
      } else {
        if (selectedQuickFilterRef.current === value.id) {
          isSelected.id_filter = value.id;
          isSelected.status = false;
        }
        isDelete = true;
      }
    });

    if (isDelete) {
      var employee_id = await getEmployee();

      if (selectedOrderTypeRef.current === 'purchase-orders') {
        await directus.updateItem('employees', employee_id.id, { quick_filter_po: quick_filter_data });
      } else {
        await directus.updateItem('employees', employee_id.id, { quick_filter_so: quick_filter_data });
      }
    }

    setSearchingLoading({ ...searchingLoadingRef.current, deleteQuickFilter: false });

    await loadQuickFilter(true);
    if (isSelected.status === false) {
      setSelectedQuickFilter(null);
    } else {
      setSelectedQuickFilter(isSelected.id_filter);
    }

    setIsEditQuickFilter(false);
  };

  const saveQuickFilterOrder = async () => {
    setSearchingLoading({ ...searchingLoadingRef.current, saveChanges: true });
    var quick_filter_data = {};
    var isSelected = { index: selectedQuickFilterRef.current, status: true };

    await quickFilterOnEditingRef.current.forEach((value, index) => {
      value.filter.id_filter = value.id
      quick_filter_data[value.name] = JSON.stringify(value.filter);
    });

    var employee_id = await getEmployee();

    if (selectedOrderTypeRef.current === 'purchase-orders') {
      await directus.updateItem('employees', employee_id.id, { quick_filter_po: quick_filter_data });
    } else {
      await directus.updateItem('employees', employee_id.id, { quick_filter_so: quick_filter_data });
    }

    setSearchingLoading({ ...searchingLoadingRef.current, saveChanges: false });

    await loadQuickFilter(true);
    if (isSelected.status === false) {
      setSelectedQuickFilter(null);
    } else {
      setSelectedQuickFilter(isSelected.index);
    }

    setIsEditQuickFilter(false);
    setQuickFilterOnEditing(null);
    setQuickFilterOnEditing([]);
  };

  const onChangeMultiSelect = () => {
    if (InvoiceAdminPermission()) {
      var is_multi_select = !isMultiSelectRef.current;
      setIsMultiSelect(is_multi_select);
      localStorage.setItem('isMultiSelect', is_multi_select);
      EventEmitter.emit('show_hide_multi_select', { value: is_multi_select });
    }
  };

  const handleAmount = (e, type) => {
    setPriceFilter({
      ...priceFilterRef.current,
      [type]: e.target.value
    });
  };

  const handlePODescription = value => {
    setPOCategoryDescription(value);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(quickFilterOnEditingRef.current);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setQuickFilterOnEditing(reorderedItems);
  };

  const pathname = window.location.pathname;
  const isOrderOrApprovalPage = pathname.includes('order') || pathname == '/' || pathname.includes('approval');

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        openFilter={() => openFilter()}
        openFilterBar={() => setIsFilter(true)}
        isFilter={isFilterRef.current}
        user={user}
        company={company}
        showInvoiceTab={showInvoiceTabsRef.current}
      />
      <div className={`${classes.wrapper} ${isFilterRef.current ? classes.wrapper2 : ''}`}>
        <div className={classes.contentContainer}>
          <div style={{ display: 'flex', width: '100%' }}>
            <div className={classes.content} style={{ marginTop: isOrderOrApprovalPage ? '81px' : '100px', paddingBottom: isOrderOrApprovalPage ? '0px' : '99px' }}>
              <Outlet />
            </div>
            {isFilterRef.current ? (
              <div className={classes.sidebar}>
                <div className={classes.sidebarContent}>
                  {changeTabRef.current === '/invoices' ? (
                    <>
                      <div className={classes.filterHeader} style={{ marginTop: '12px', marginBottom: '13px', position: 'initial' }}>
                        <div>Options</div>
                        <hr style={{ border: '1px solid #E5E5E5', marginTop: '12px' }} />
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div style={{ lineHeight: '27px' }}>Show/Hide Multi-Select</div>
                        <div>
                          <FormControlLabel
                            className={classes.myOrderSwitch}
                            style={{ height: '27px' }}
                            disabled={!InvoiceAdminPermission()}
                            control={
                              <Switch
                                name="Show/Hide Multi-Select"
                                checked={isMultiSelectRef.current ? true : false}
                                onChange={() => onChangeMultiSelect()}
                              />
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}

                  <div className={classes.filterHeader} style={{ marginTop: changeTabRef.current === '/invoices' ? '25px' : '0px' }}>
                    <div>Filters</div>
                    <hr style={{ border: '1px solid #E5E5E5', marginTop: '12px' }} />
                  </div>
                  {changeTabRef.current === '/' || changeTabRef.current.includes('order') ? (
                    POCreatorPermission() ? (
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div style={{ lineHeight: '27px' }}>Only Show My Orders</div>
                        <div>
                          <FormControlLabel
                            className={classes.myOrderSwitch}
                            style={{ height: '27px' }}
                            control={
                              <Switch
                                disabled={!POCreatorPermission()}
                                name="Only Show My Orders"
                                checked={isMyOrderRef.current ? true : false}
                                onChange={() => setIsMyOrder(!isMyOrderRef.current)}
                              />
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}

                  <div>
                    <div style={{ lineHeight: '27px' }}>Date</div>
                    <div>
                      <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                        <ListItem
                          className={classes.selectDate}
                          style={{
                            paddingLeft: selectedDateIndexRef.current !== 2 ? '10px' : '5px',
                            paddingRight: '4px'
                          }}
                          aria-haspopup="listbox"
                          aria-controls="lock-menu"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <div
                            ref={dateListRef}
                            style={{
                              fontSize: '12px',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between',
                              maxHeight: '24px'
                            }}
                          >
                            <div style={{ lineHeight: '24px', overflowY: 'hidden', fontWeight: '400' }}>
                              <div>
                                {selectedDateIndexRef.current !== 2 ? (
                                  options[selectedDateIndexRef.current]
                                ) : (
                                  <div style={{ overflow: 'hidden', display: 'flex', marginTop: '3px' }}>
                                    <Chip
                                      className={classes.dateChip}
                                      style={{ fontSize: '12px', height: '18px', backgroundColor: '#D4E3F2' }}
                                      onClick={() => clickDateRangeChip()}
                                      label={`Start: ${moment(dateRangeCustomRef.current[0]).format('DD/MM/YYYY')}`}
                                    />
                                    <Chip
                                      className={classes.dateChip}
                                      style={{
                                        fontSize: '12px',
                                        height: '18px',
                                        backgroundColor: '#D4E3F2',
                                        marginLeft: '2px'
                                      }}
                                      onClick={() => clickDateRangeChip()}
                                      label={`End: ${moment(dateRangeCustomRef.current[1]).format('DD/MM/YYYY')}`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={classes.arrowDropDown} onClick={e => setAnchorEl(dateListRef.current)}>
                              <ArrowDropDownIcon style={{ fontSize: '18px', height: '24px' }} />
                            </div>
                          </div>
                        </ListItem>
                      </List>

                      <Menu
                        id="lock-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'lock-button',
                          role: 'listbox'
                        }}
                        PaperProps={{
                          style: {
                            maxWidth: '240px',
                            width: '240px'
                          }
                        }}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      >
                        {options.map((option, index) => (
                          <MenuItem
                            style={{ height: '30px', fontSize: '12px' }}
                            key={option}
                            disabled={index === 0}
                            selected={index === selectedDateIndexRef.current}
                            onClick={() => handleMenuItemClick(index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                    <Dialog
                      className={classes.dateDialog}
                      onClose={() => cancelSelectCustomDialog()}
                      aria-labelledby="simple-dialog-title"
                      open={openSelectCustom}
                      fullWidth
                    >
                      <DialogTitle id="simple-dialog-title">Select Date</DialogTitle>
                      <List style={{ margin: '0 24px' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            value={[moment(dateRangeCustomTempRef.current[0]).format(), moment(dateRangeCustomTempRef.current[1]).format()]}
                            name="date_range"
                            onChange={e => handleChangeDate(e)}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                      </List>
                      <DialogActions>
                        <Button onClick={() => cancelSelectCustomDialog()} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => saveSelectCustomDialog()} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {window.location.pathname === '/invoices' ?
                      <>
                        <div style={{ lineHeight: '27px', marginTop: '10px' }}>Date Created</div>
                        <div>
                          <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                            <ListItem
                              className={classes.selectDate}
                              style={{
                                paddingLeft: selectedDateCreatedIndexRef.current !== 2 ? '10px' : '5px',
                                paddingRight: '4px'
                              }}
                              aria-haspopup="listbox"
                              aria-controls="lock-menu"
                              aria-expanded={openDateCreated ? 'true' : undefined}
                            >
                              <div
                                ref={dateCreatedListRef}
                                style={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  maxHeight: '24px'
                                }}
                              >
                                <div style={{ lineHeight: '24px', overflowY: 'hidden', fontWeight: '400' }}>
                                  <div>
                                    {selectedDateCreatedIndexRef.current !== 2 ? (
                                      options[selectedDateCreatedIndexRef.current]
                                    ) : (
                                      <div style={{ overflow: 'hidden', display: 'flex', marginTop: '3px' }}>
                                        <Chip
                                          className={classes.dateChip}
                                          style={{ fontSize: '12px', height: '18px', backgroundColor: '#D4E3F2' }}
                                          onClick={() => clickDateCreatedRangeChip()}
                                          label={`Start: ${moment(dateCreatedRangeCustomRef.current[0]).format('DD/MM/YYYY')}`}
                                        />
                                        <Chip
                                          className={classes.dateChip}
                                          style={{
                                            fontSize: '12px',
                                            height: '18px',
                                            backgroundColor: '#D4E3F2',
                                            marginLeft: '2px'
                                          }}
                                          onClick={() => clickDateCreatedRangeChip()}
                                          label={`End: ${moment(dateCreatedRangeCustomRef.current[1]).format('DD/MM/YYYY')}`}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className={classes.arrowDropDown} onClick={e => setAnchorDateCreated(dateCreatedListRef.current)}>
                                  <ArrowDropDownIcon style={{ fontSize: '18px', height: '24px' }} />
                                </div>
                              </div>
                            </ListItem>
                          </List>

                          <Menu
                            id="lock-menu"
                            anchorEl={anchorDateCreated}
                            open={openDateCreated}
                            onClose={() => setAnchorDateCreated(null)}
                            MenuListProps={{
                              'aria-labelledby': 'lock-button',
                              role: 'listbox'
                            }}
                            PaperProps={{
                              style: {
                                maxWidth: '240px',
                                width: '240px'
                              }
                            }}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          >
                            {options.map((option, index) => (
                              <MenuItem
                                style={{ height: '30px', fontSize: '12px' }}
                                key={option}
                                disabled={index === 0}
                                selected={index === selectedDateCreatedIndexRef.current}
                                onClick={() => handleMenuItemDateCreatedClick(index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                        <div style={{ lineHeight: '27px', marginTop: '10px' }}>Date Approved</div>
                        <div>
                          <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                            <ListItem
                              className={classes.selectDate}
                              style={{
                                paddingLeft: selectedDateApprovedIndexRef.current !== 2 ? '10px' : '5px',
                                paddingRight: '4px'
                              }}
                              aria-haspopup="listbox"
                              aria-controls="lock-menu"
                              aria-expanded={openDateApproved ? 'true' : undefined}
                            >
                              <div
                                ref={dateApprovedListRef}
                                style={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  maxHeight: '24px'
                                }}
                              >
                                <div style={{ lineHeight: '24px', overflowY: 'hidden', fontWeight: '400' }}>
                                  <div>
                                    {selectedDateApprovedIndexRef.current !== 2 ? (
                                      options[selectedDateApprovedIndexRef.current]
                                    ) : (
                                      <div style={{ overflow: 'hidden', display: 'flex', marginTop: '3px' }}>
                                        <Chip
                                          className={classes.dateChip}
                                          style={{ fontSize: '12px', height: '18px', backgroundColor: '#D4E3F2' }}
                                          onClick={() => clickDateApprovedRangeChip()}
                                          label={`Start: ${moment(dateApprovedRangeCustomRef.current[0]).format('DD/MM/YYYY')}`}
                                        />
                                        <Chip
                                          className={classes.dateChip}
                                          style={{
                                            fontSize: '12px',
                                            height: '18px',
                                            backgroundColor: '#D4E3F2',
                                            marginLeft: '2px'
                                          }}
                                          onClick={() => clickDateApprovedRangeChip()}
                                          label={`End: ${moment(dateApprovedRangeCustomRef.current[1]).format('DD/MM/YYYY')}`}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className={classes.arrowDropDown} onClick={e => setAnchorDateApproved(dateApprovedListRef.current)}>
                                  <ArrowDropDownIcon style={{ fontSize: '18px', height: '24px' }} />
                                </div>
                              </div>
                            </ListItem>
                          </List>

                          <Menu
                            id="lock-menu"
                            anchorEl={anchorDateApproved}
                            open={openDateApproved}
                            onClose={() => setAnchorDateApproved(null)}
                            MenuListProps={{
                              'aria-labelledby': 'lock-button',
                              role: 'listbox'
                            }}
                            PaperProps={{
                              style: {
                                maxWidth: '240px',
                                width: '240px'
                              }
                            }}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          >
                            {options.map((option, index) => (
                              <MenuItem
                                style={{ height: '30px', fontSize: '12px' }}
                                key={option}
                                disabled={index === 0}
                                selected={index === selectedDateApprovedIndexRef.current}
                                onClick={() => handleMenuItemDateApprovedClick(index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </>
                      : ''}

                    <Dialog
                      className={classes.dateDialog}
                      onClose={() => cancelSelectCustomDateCreatedDialog()}
                      aria-labelledby="simple-dialog-title"
                      open={openSelectCustomDateCreated}
                      fullWidth
                    >
                      <DialogTitle id="simple-dialog-title">Select Date</DialogTitle>
                      <List style={{ margin: '0 24px' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            value={[moment(dateCreatedRangeCustomTempRef.current[0]).format(), moment(dateCreatedRangeCustomTempRef.current[1]).format()]}
                            name="date_range"
                            onChange={e => handleChangeDateCreated(e)}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                      </List>
                      <DialogActions>
                        <Button onClick={() => cancelSelectCustomDateCreatedDialog()} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => saveSelectCustomDateCreatedDialog()} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      className={classes.dateDialog}
                      onClose={() => cancelSelectCustomDateApprovedDialog()}
                      aria-labelledby="simple-dialog-title"
                      open={openSelectCustomDateApproved}
                      fullWidth
                    >
                      <DialogTitle id="simple-dialog-title">Select Date</DialogTitle>
                      <List style={{ margin: '0 24px' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            value={[moment(dateApprovedRangeCustomTempRef.current[0]).format(), moment(dateApprovedRangeCustomTempRef.current[1]).format()]}
                            name="date_range"
                            onChange={e => handleChangeDateApproved(e)}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                      </List>
                      <DialogActions>
                        <Button onClick={() => cancelSelectCustomDateApprovedDialog()} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => saveSelectCustomDateApprovedDialog()} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ lineHeight: '27px' }}>Order Status</div>
                    {displayMenuDataWithoutSearch('order_status')}
                  </div>
                  {showInvoiceTabsRef.current === true && !isOrderTypeEqualsToSalesOrdersRef.current ?
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ lineHeight: '27px' }}>Invoice Status</div>
                      {displayMenuDataWithoutSearch('invoice_status')}
                    </div>
                    : ''}
                  {changeTabRef.current === '/invoices' && !isOrderTypeEqualsToSalesOrdersRef.current ? (
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ lineHeight: '27px' }}>Matched with PO</div>
                      {displayMenuDataWithoutSearch('matched_po')}
                    </div>
                  ) : (
                    ''
                  )}

                  {(changeTabRef.current === '/' || changeTabRef.current.includes('order')) && showInvoiceTabsRef.current === true && !isOrderTypeEqualsToSalesOrdersRef.current ? (
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ lineHeight: '27px' }}>Matched with Invoice</div>
                      {displayMenuDataWithoutSearch('matched_invoice')}
                    </div>
                  ) : (
                    ''
                  )}

                  {!isOrderTypeEqualsToSalesOrdersRef.current ?
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ lineHeight: '27px' }}>Supplier</div>
                      {displayMenuData('supplier')}
                    </div>
                    : ''}
                  {showCustomerFilterRef.current ?
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ lineHeight: '27px' }}>Customer</div>
                      {displayMenuData('customer')}
                    </div>
                    : ''}
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ lineHeight: '27px' }}>Price</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <Checkbox
                          style={{ padding: '0', marginRight: '8px' }}
                          onChange={e => setIsAnyPrice(true)}
                          checked={isAnyPriceRef.current}
                          icon={<RadioButtonUncheckedIcon style={{ color: '#E5E5E5' }} />}
                          checkedIcon={<RadioButtonCheckedIcon style={{ color: '#82CB43' }} />}
                        />
                      </div>
                      <div>Any Price</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                      <div>
                        <Checkbox
                          style={{ padding: '0', marginRight: '8px' }}
                          onChange={e => setIsAnyPrice(false)}
                          checked={!isAnyPriceRef.current}
                          icon={<RadioButtonUncheckedIcon style={{ color: '#E5E5E5' }} />}
                          checkedIcon={<RadioButtonCheckedIcon style={{ color: '#82CB43' }} />}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 'calc(50% - 15px)' }}>
                          <InputBase
                            id="min_amount"
                            name="min_amount"
                            style={{ marginRight: '20px', width: '100%' }}
                            variant="outlined"
                            placeholder="Min"
                            size="small"
                            type="number"
                            onChange={e => handleAmount(e, 'min')}
                            className={classes.searchbox}
                            value={priceFilterRef.current.min ? priceFilterRef.current.min : ''}
                            startAdornment={
                              <InputAdornment position="start">
                                <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>$</div>
                              </InputAdornment>
                            }
                          />
                        </div>
                        <div style={{ width: '30px', textAlign: 'center', fontWeight: 'bold' }}>-</div>
                        <div style={{ width: 'calc(50% - 15px)' }}>
                          <InputBase
                            id="max_amount"
                            name="max_amount"
                            style={{ marginRight: '20px', width: '100%' }}
                            variant="outlined"
                            placeholder="Max"
                            size="small"
                            type="number"
                            onChange={e => handleAmount(e, 'max')}
                            className={classes.searchbox}
                            value={priceFilterRef.current.max ? priceFilterRef.current.max : ''}
                            startAdornment={
                              <InputAdornment position="start">
                                <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>$</div>
                              </InputAdornment>
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {changeTabRef.current === '/' || changeTabRef.current.includes('order') ? (
                      <div style={{ marginTop: '10px' }}>
                        <div style={{ lineHeight: '27px' }}>Created By</div>
                        {displayMenuData('employee')}
                      </div>
                    ) : (
                      ''
                    )}

                    {!isOrderTypeEqualsToSalesOrdersRef.current && showCategoryFilterRef.current ?
                      <div style={{ marginTop: '10px' }}>
                        <div style={{ lineHeight: '27px' }}>PO Category</div>
                        {displayMenuData('po_category')}
                      </div>
                      : ''}

                    {!isOrderTypeEqualsToSalesOrdersRef.current && showCategoryFilterRef.current ?
                      <div style={{ marginTop: '10px' }}>
                        <div style={{ lineHeight: '27px' }}>PO Category Description</div>
                        <InputBase
                          id="po_category_description"
                          name="po_category_description"
                          style={{
                            marginRight: '20px',
                            width: '100%',
                            fontSize: '12px',
                            height: '26px'
                          }}
                          variant="outlined"
                          placeholder="Description"
                          size="small"
                          type="text"
                          onChange={e => {
                            handlePODescription(e.target.value);
                          }}
                          className={classes.searchbox}
                          value={poCategoryDescriptionRef.current}
                          startAdornment={
                            <InputAdornment position="start">
                              <div style={{ marginLeft: '2px' }}></div>
                            </InputAdornment>
                          }
                        />
                      </div>
                      : ''}
                  </div>
                  <div style={{ marginTop: '20px', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <FilterButton
                      variant="contained"
                      disabled={searchingLoadingRef.current.applyFilter}
                      onClick={() => filterData('applyFilter')}
                      style={{ backgroundColor: '#516573', color: '#FFFFFF' }}
                    >
                      <div className={classes.filterText}>Apply Filters</div>
                      <div style={{ height: '24px', paddingTop: '2px' }}>
                        {searchingLoadingRef.current.applyFilter ? (
                          <CircularProgress
                            style={{
                              width: '15px',
                              height: '15px',
                              marginLeft: '10px',
                              marginTop: '2px',
                              color: '#FFFFFF'
                            }}
                            className={classes.circular}
                          />
                        ) : null}
                      </div>
                    </FilterButton>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <ResetButton
                      style={{ marginRight: '5px' }}
                      variant="contained"
                      disabled={searchingLoadingRef.current.resetFilter}
                      onClick={() => resetFilter()}
                    >
                      <div style={{ display: 'flex' }}>
                        <div className={classes.filterText}>Reset Filters</div>
                        <div style={{ height: '24px', paddingTop: '2px' }}>
                          {searchingLoadingRef.current.resetFilter ? (
                            <CircularProgress
                              style={{
                                width: '15px',
                                height: '15px',
                                marginLeft: '5px',
                                marginTop: '2px',
                                color: '#82CB43'
                              }}
                              className={classes.circular}
                            />
                          ) : null}
                        </div>
                      </div>
                    </ResetButton>

                    <ResetButton
                      style={{ marginLeft: '5px' }}
                      variant="contained"
                      disabled={searchingLoadingRef.current.saveQuickFilter}
                      onClick={() => saveFilter()}
                    >
                      <div style={{ display: 'flex' }}>
                        <div className={classes.filterText}>Save Quick Filter</div>
                        <div style={{ height: '24px', paddingTop: '2px' }}>
                          {searchingLoadingRef.current.saveQuickFilter ? (
                            <CircularProgress
                              style={{
                                width: '15px',
                                height: '15px',
                                marginLeft: '5px',
                                marginTop: '2px',
                                color: '#82CB43'
                              }}
                              className={classes.circular}
                            />
                          ) : null}
                        </div>
                      </div>
                    </ResetButton>
                  </div>
                  <div className={classes.filterHeader} style={{ marginTop: '50px', marginBottom: '13px' }}>
                    <div>My Quick Filters</div>
                    <hr style={{ border: '1px solid #E5E5E5', marginTop: '12px' }} />
                  </div>

                  {isEditQuickFilterRef.current && quickFilterOnEditingRef.current ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {quickFilterOnEditingRef.current.map((value, index) => (
                              <Draggable key={value.id} draggableId={value.id} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{ userSelect: 'none', display: 'flex', alignItems: 'flex-start', ...provided.draggableProps.style }}
                                  >
                                    <div>
                                      <Checkbox
                                        style={{ padding: '0', marginRight: '8px' }}
                                        defaultChecked={quickFilterOnEditingRef.current[index].checked}
                                        onChange={(e) => onSelectedDateFilterChange(value, index)}
                                        icon={<RadioButtonUncheckedIcon style={{ color: '#E5E5E5' }} />}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#82CB43' }} />}
                                      />
                                    </div>
                                    <Box className={classes.box}>
                                      <EditableFilterButton
                                        style={{ minWidth: 'calc(100% - 52px)' }}
                                        variant="contained"
                                        disabled={searchingLoadingRef.current.selectedFilter}
                                        className={selectedQuickFilterRef.current === value.id ? classes.selectedQuickFilterEditable : classes.quickFilterEditable}
                                        onClick={() => onQuickFilterClick(value, index)}
                                      >
                                        {value.name}
                                      </EditableFilterButton>
                                    </Box>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <DragIndicatorIcon />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    quickFilterRef.current.map((value, index) => (
                      <div
                        key={value.id}
                        style={{ userSelect: 'none', display: 'flex', alignItems: 'flex-start' }}
                      >
                        <Box className={classes.box}>
                          <FilterButton
                            variant="contained"
                            disabled={searchingLoadingRef.current.selectedFilter}
                            className={selectedQuickFilterRef.current === value.id ? classes.selectedQuickFilter : classes.quickFilter}
                            onClick={() => onQuickFilterClick(value, index)}
                          >
                            <span className={classes.filterText}>{value.name}</span>
                          </FilterButton>
                        </Box>
                      </div>
                    ))
                  )}

                  {quickFilterRef.current.length > 0 ? (
                    <div style={{ marginTop: '10px' }}>
                      {!isEditQuickFilterRef.current ? (
                        <FilterButton
                          variant="contained"
                          disabled={searchingLoadingRef.current.editQuickFilter}
                          onClick={() => {
                            setIsEditQuickFilter(true);
                            setQuickFilterOnEditing(quickFilterRef.current);
                          }}
                          style={{ backgroundColor: '#F2F2F6', color: '#374355' }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div className={classes.filterText}>Edit Quick Filters</div>
                            <div style={{ height: '24px', paddingTop: '2px' }}>
                              {searchingLoadingRef.current.editQuickFilter ? (
                                <CircularProgress
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    marginLeft: '10px',
                                    marginTop: '2px',
                                    color: '#82CB43'
                                  }}
                                  className={classes.circular}
                                />
                              ) : null}
                            </div>
                          </div>
                        </FilterButton>
                      ) : (
                        <>
                          <FilterButton
                            variant="contained"
                            disabled={searchingLoadingRef.current.saveChanges}
                            onClick={() => saveQuickFilterOrder()}
                            style={{ backgroundColor: 'rgb(130, 203, 67)', color: '#FFFFFF', marginBottom: '10px' }}
                          >
                            <div style={{ display: 'flex' }}>
                              <div className={classes.filterText}>Save Quick Filters</div>
                              <div style={{ height: '24px', paddingTop: '2px' }}>
                                {searchingLoadingRef.current.saveChanges ? (
                                  <CircularProgress
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      marginLeft: '10px',
                                      marginTop: '2px',
                                      color: 'white'
                                    }}
                                    className={classes.circular}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </FilterButton>
                          <FilterButton
                            variant="contained"
                            disabled={searchingLoadingRef.current.deleteQuickFilter}
                            onClick={() => deleteQuickFilter()}
                            style={{ backgroundColor: '#E87777', color: '#FFFFFF' }}
                          >
                            <div style={{ display: 'flex' }}>
                              <div className={classes.filterText}>Delete Quick Filters</div>
                              <div style={{ height: '24px', paddingTop: '2px' }}>
                                {searchingLoadingRef.current.deleteQuickFilter ? (
                                  <CircularProgress
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      marginLeft: '10px',
                                      marginTop: '2px',
                                      color: '#82CB43'
                                    }}
                                    className={classes.circular}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </FilterButton>
                        </>
                      )}
                      {isEditQuickFilter ? (
                        <FilterButton
                          variant="contained"
                          disabled={searchingLoadingRef.current.deleteQuickFilter || searchingLoadingRef.current.saveChanges}
                          onClick={() => {
                            setIsEditQuickFilter(false);
                            setQuickFilterOnEditing(null);
                          }}
                          style={{ backgroundColor: '#F2F2F6', color: '#374355', marginTop: '10px' }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div className={classes.filterText}>Cancel</div>
                          </div>
                        </FilterButton>
                      ) : null}
                    </div>
                  ) : null}

                  <div></div>
                </div>
              </div>
            ) : null}
          </div>

          <Dialog open={openQuickFilter}>
            <DialogTitle>Save Quick Filter</DialogTitle>
            <DialogContent style={{ height: '80px', width: '350px' }}>
              <TextField
                variant="outlined"
                label="Quick Filter Name"
                style={{ width: '100%' }}
                onChange={e => changeQuickFilterName(e)}
                value={quickFilterName}
              />
            </DialogContent>
            <DialogActions style={{ height: '60px' }}>
              {searchingLoadingRef.current.saveQuickFilterDialog ? (
                <CircularProgress className={classes.circular} />
              ) : (
                <>
                  <CancelButton size="small" variant="contained" onClick={handleCloseSaveQuickFilterDialog}>
                    <Typography variant="h6">Back</Typography>
                  </CancelButton>
                  <CompletedButton variant="contained" onClick={() => saveQuickFilter()} disabled={!quickFilterName}>
                    <Typography variant="h6">Save</Typography>
                  </CompletedButton>
                </>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
