/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import directus from "../../services/directus";
import useStateRef from "react-usestateref";
import Enumerable from "linq";
import moment from "moment";
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import EventEmitter from "src/utils/EventEmitter";
import {
  formatNumber,
  checkEnableClick,
  generateDeliveryAddress,
  filterOption,
  generateDeliveryOption,
  configurationFields
} from 'src/components/ReusableFunction';
import { v4 as uuidv4 } from "uuid";
import { getCache, setCache } from 'src/utils/KeyValueCache';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getEmployee } from 'src/utils/sessions';
import { OverflowTip } from "src/components/OverflowTip";
import { IconButton, Divider, Box, Autocomplete } from "@mui/material";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ErrorMessage from 'src/components/ErrorMessage';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { createFilterOptions } from '@mui/material/Autocomplete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  theme,
  SubmitButton,
  CancelButton,
  AddItemButton,
  CustomButton,
  CustomButton2,
  StyledMenu,
} from 'src/components/ReusableStyle';
import {
  useStyles,
  NumberFormatCustom,
  NumberFormatCustomPercent,
  filterOptions,
  filterOptionsItemNumberAndDescription,
} from './style.js';
import {
  Grid,
  Typography,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  CircularProgress,
  Chip
} from "@material-ui/core";

const AddOrder = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [showTotalDetails, setShowTotalDetails] = useState(false);
  const [, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [notes, setNotes] = useState(null);
  const [, setDeliveryAddressOptionId, deliveryAddressOptionIdRef] = useStateRef(0);
  const [deliveryAddressOptionSelected, setDeliveryAddressOptionSelected] = useState([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState([{ id: -1, address_name: 'Custom', lable: 'Custom' }]);
  const [, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [, setSubTotal, subTotalRef] = useStateRef(0);
  const [, setTotalTax, totalTaxRef] = useStateRef(0);
  const [, setOrderDiscount, orderDiscountRef] = useStateRef(0);
  const [, setShipping, shippingRef] = useStateRef(0);
  const [, setSubTotalIncludingTax, subTotalIncludingTaxRef] = useStateRef(0);
  const [selectedDeliveryDate, handleDeliveryDateChange] = useState(new Date());
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [email, setEmail] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dataCustomEmail, setDataCustomEmail, dataCustomEmailRef] = useStateRef({ send_to_email: null, cc_email: null });
  const [customEmailLoading, setCustomEmailLoading] = useState(false);
  const [isCustomEmail, setIsCustomEmail] = useState(false);
  const [, setUploadFiles, uploadFilesRef] = useStateRef([]);
  const [anchorElOrdersType, setAnchorElOrdersType] = React.useState(null);
  const openActions = Boolean(anchorElOrdersType);

  const [itemsLoading, setItemsLoading] = useState(false);
  const [, setItemsOptions, itemsOptionsRef] = useStateRef([]);
  
  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  const [, setCustomerOptions, customerOptionsRef] = useStateRef([]);
  
  const [items, setItems, itemsRef] = useStateRef([]);
  const [itemCheck, setItemCheck] = useState(false);
  const [, setItemCount, itemCountRef] = useStateRef(false);
  
  const [, setInputAddressValue, inputAddressValueRef] = useStateRef('');
  const [, setSelectedDeliveryAddress, selectedDeliveryAddressRef] = useStateRef({
    AddressLine1: '',
    AddressLine2: '',
    AddressName: '',
    AddressCity: '',
    AddressState: '',
    AddressPostcode: '',
  });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadCustomer();
      loadItems();
      getConfigurationSettings();
      addItems();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCustomer = async () => {
    try {
      var customer_filter = { status: 'published' };
      var Customers = null;

      var fields = `id, code, email, name, selectable, access_level, accounting_system, legal_name, 
      suppliers.id, suppliers.status, suppliers.supplier.*, delivery_address_line_1, delivery_address_line_2, delivery_city, delivery_state, delivery_post_code, additional_address.*`;

      var customerCache = getCache('customers');
      if (!customerCache) {
        Customers = await directus.getItems('customers', {
          fields: fields,
          sort: 'name',
          filter: customer_filter
        });

        setCache('customers', Customers.data);
      } else {
        Customers = { data: customerCache };
      }

      Customers = Customers.data;
      var customers_data = Customers;
      var fav_customers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      customers_data.forEach((value, index) => {
        if (fav_customers.indexOf(value.id) >= 0) {
          customers_data[index].isFavourite = true;
        } else {
          customers_data[index].isFavourite = false;
        }
      });

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCustomerOptions(customers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const loadItems = async () => {
    setItemsLoading(true);
    try {
      var Items = null;
      var itemsCache = getCache('items');
      if (!itemsCache) {
        Items = await directus.getItems('items', {
          fields: '*',
          sort: 'name',
        });

        setCache('items', Items.data);
      } else {
        Items = { data: itemsCache };
      }

      Items = Items.data;
      var items_data = Items;

      items_data = Enumerable.from(items_data)
        .orderBy(item => item.name)
        .toArray();

      setItemsOptions(items_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setItemsLoading(false);
  };

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration', { fields: configurationFields});
    if (result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_sales_orders_total_details === true) {
          setShowTotalDetails(true);
        }
      }
    }
  }

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'notes') {
      setNotes(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    } else if (name === 'delivery_address') {
      setDeliveryAddress(value);
    } else if (name === 'order_discount') {
      setOrderDiscount(value);
      countTotal();
    } else if (name === 'shipping') {
      setShipping(value);
      countTotal();
    }
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const editSubmit = async (type, send_customer = false, custom_send = false) => {
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    setLoadingSubmit(true);

    var orderResult = null;

    if (custom_send) {
      orderResult = await directus.api.post(
        `/items/sales_orders`,
        {
          employee: directusUser.id,
          status: type,
          order_date: date,
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          farm_code: selectedCustomerRef.current ? selectedCustomerRef.current.id : null,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
          send_to_email: dataCustomEmailRef.current.send_to_email,
          cc_email: dataCustomEmailRef.current.cc_email,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current,
          notes: notes,
        },
        { send_customer: send_customer, custom_send: custom_send }
      );
    } else {
      orderResult = await directus.api.post(
        `/items/sales_orders`,
        {
          employee: directusUser.id,
          status: type,
          order_date: date,
          delivery_instructions: deliveryInstructions,
          delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
          delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
          delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
          delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
          delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
          delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
          delivery_address_option: deliveryAddressOptionIdRef.current,
          cc_email: email,
          notes: notes,
          order_discount: orderDiscountRef.current,
          shipping: shippingRef.current,
          farm_code: selectedCustomerRef.current ? selectedCustomerRef.current.id : null,
          due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null
        },
        { send_customer: send_customer }
      );
    }

    //--------------------------------------------------------------------------------------------------------
    let addItems = [];

    //eslint-disable-next-line array-callback-return
    itemsRef.current.map((data, index) => {
      addItems = [
        ...addItems,
        {
          id: uuidv4(),
          sales_order: { id: orderResult.data.id },
          sales_order_item: {
            id: data.id,
            description: data.description,
            quantity: data.quantity,
            unit_price: data.unit_price,
            sort: index,
            internal_code: data.internal_code,
            item_discount: data.item_discount ?? 0,
            sales_item: data.sales_item ? data.sales_item.id : null,
          }
        }
      ];
    });

    if (addItems.length > 0) {
      var addItemsBody = addItems;

      for (let index = 0; index < addItemsBody.length; index++) {
        var addItem = addItemsBody[index];
        delete addItem.id;
        delete addItem.sales_order_item.id;

        var orderItemResult = await directus.createItems('sales_order_items', addItem.sales_order_item);
        addItem.sales_order_item = { id: orderItemResult.data.id };

        addItemsBody[index] = addItem;
      }

      await directus.createItems('sales_orders_items', addItemsBody);
    }

    if (uploadFilesRef.current && uploadFilesRef.current.length > 0) {
      const promises = uploadFilesRef.current.map(async value => {
        if (value.formData) {
          let updateData = await directus.uploadFiles(value.formData, onUploadProgress);

          await directus.createItem('sales_order_files', {
            status: 'published',
            sales_order_id: orderResult.data.id,
            directus_file_id: updateData.data.id
          });
        }
      });

      await Promise.all(promises);
    }

    setLoadingSubmit(false);

    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
    handleCloseActions();
    EventEmitter.emit('load_all_orders', { value: true });
  };

  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  const changeDeliveryAddressOption = newValue => {
    let newDeliveryAddressOption = generateDeliveryOption(newValue);

    const selectedAddressFromOption = newDeliveryAddressOption.filter(address => address.id == deliveryAddressOptionIdRef.current);

    if (selectedAddressFromOption.length > 0) {
      setDeliveryAddress(selectedAddressFromOption[0]);
      formattingDeliveryAddress(selectedAddressFromOption[0]);
    } else {
      setDeliveryAddress('');
    }

    setDeliveryAddressOptions(newDeliveryAddressOption);
  }

  const formattingDeliveryAddress = (address) => {
    const formatting_delivery_address = generateDeliveryAddress(address);
    
    setSelectedDeliveryAddress({
      AddressName: formatting_delivery_address.AddressName,
      AddressLine1: formatting_delivery_address.AddressLine1,
      AddressLine2: formatting_delivery_address.AddressLine2,
      AddressCity: formatting_delivery_address.AddressCity,
      AddressState: formatting_delivery_address.AddressState,
      AddressPostcode: formatting_delivery_address.AddressPostcode,
    })
  }

  const onChangeAddress = async (data) => {
    setDeliveryAddressOptionSelected(data);
    if (data) {
      var address = data.label;
      var subpremise = '';
      var street_number = '';
      var route = '';
      var check_address = '';
      var Zip = '';
      var State = '';
      var City = '';

      setInputAddressValue(address);

      const results = await geocodeByAddress(address);

      if (results[0]) {

        /* eslint-disable array-callback-return */
        results[0].address_components.map((value) => {
          if (value.types) {
            value.types.map((types_value) => {
              if (types_value === "subpremise") {
                subpremise = "U" + value.long_name + '/';
                check_address = "U" + value.long_name + '/';
              }

              if (types_value === "street_number") {
                street_number = value.long_name + ' ';
                check_address = check_address + ' ' + value.long_name;
              }

              if (types_value === "route") {
                route = value.long_name + '';
                check_address = check_address + ' ' + value.long_name;
              }

              if (types_value === "postal_code") {
                Zip = value.long_name;
              }

              if (types_value === "administrative_area_level_1") {
                State = value.short_name;
              }

              if (types_value === "locality") {
                City = value.long_name;
              }

            })
          }
        })
        /* eslint-enable array-callback-return */
      }

      if (check_address.length <= 30) {
        setSelectedDeliveryAddress({
          ...selectedDeliveryAddressRef.current,
          AddressLine1: subpremise + '' + street_number + '' + route,
          AddressLine2: ''
        })
      } else {
        setSelectedDeliveryAddress({
          ...selectedDeliveryAddressRef.current,
          AddressLine1: subpremise,
          AddressLine2: ', ' + street_number + ' ' + route
        })
      }

      setSelectedDeliveryAddress({
        ...selectedDeliveryAddressRef.current,
        AddressName: 'Custom',
        AddressCity: City,
        AddressState: State,
        AddressPostcode: Zip,
      })
    } else {
      setInputAddressValue('');
    }
  }

  const resetCustomer = async e => {
    try {
      if (isClickFavouriteRef.current) {
        var fav_customers_data = Enumerable.from(customerOptionsRef.current)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_customers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_customers_po) {
            fav_customers = directus_employee.favourites_customers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_customers.length === 0 && fav_customers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_customers.length !== fav_customers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_customers_data.forEach(value => {
            if (fav_customers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_customers.forEach(value => {
              if (fav_customers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_customers_po = fav_customers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const clickCustomerFavorite = (option) => {
    if (checkEnableClick(option)) {
      setIsClickFavourite(true);
      let customerOptionsData = customerOptionsRef.current.map((data, index) => {
        if (data.id == option.id) {
          data.isFavourite = !option.isFavourite;
        }
        return data;
      })
      
  
      const updateCustomerOption = Enumerable.from(customerOptionsData)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      
      setCustomerOptions(updateCustomerOption);
    }
  };

  const countTotal = () => {
    if (itemsRef.current) {
      let subtotal = 0;
      
      //eslint-disable-next-line array-callback-return
      itemsRef.current.map(data => {
        if (data.statusValue !== 3) {
          if (data.unit_price && data.quantity) {
            let item_total = data.unit_price * data.quantity;
            if (data.item_discount) {
              if (data.item_discount !== '0' && data.item_discount !== 0) {
                const item_total_discount = (item_total * data.item_discount) / 100;
                item_total -= item_total_discount;
              }
            }
            subtotal = subtotal + parseFloat(item_total);
          }
        }
      });
      const tax = 10 * subtotal / 100;
      let total = subtotal;

      setSubTotalIncludingTax(total);

      if (total > 0) {
        if (orderDiscountRef.current > 0) {
          total -= parseFloat(orderDiscountRef.current);
        }
  
        if (shippingRef.current > 0) {
          total += parseFloat(shippingRef.current);
        }
      }
  
      setSubTotal(subtotal.toFixed(2));
      setTotalTax(tax.toFixed(2));
      setTotalOrderItem(total.toFixed(2));
    }
  };

  const addItemsCheck = itemCount => {
    itemsRef.current.forEach(data => {
      if (itemCount === 0) {
        if (
          data.quantity === undefined ||
          data.quantity === '' ||
          data.quantity === 0 ||
          data.quantity === '0' ||
          data.quantity < 0 ||
          data.description === undefined ||
          data.description === ''
        ) {
          setItemCheck(false);
        } else {
          setItemCheck(true);
        }
      } else {
        if (
          data.quantity === undefined ||
          data.quantity === '' ||
          data.quantity === 0 ||
          data.quantity === '0' ||
          data.quantity < 0 ||
          data.description === undefined ||
          data.description === ''
        ) {
          setItemCheck(false);
        } else {
          setItemCheck(true);
        }
      }
    });
  };

  const changeItems = (e, oldData, newData) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }
    
    setItems(
      items.map(dataList =>
        dataList.id === oldData.id
          ? { ...oldData, [name]: value }
          : dataList
      )
    );

    countTotal();
    addItemsCheck(itemCountRef.current);
  };

  const deleteItems = itemData => {
    setItems(itemsRef.current.filter(data => data.id !== itemData.id));

    deleteItemsCheck(itemData);
    addItemsCheck(itemCountRef.current);
    countTotal();
  };

  const addItems = () => {
    let data = [];
    data.id = uuidv4();
    if (itemsRef.current && itemsRef.current.length > 0) {
      data.count = itemsRef.current[itemsRef.current.length - 1].count + 1;
      setItems([...itemsRef.current, data]);
      setItemCount(data.count);
    } else {
      let data = [];
      data.id = uuidv4();
      data.count = 0;
      setItems([data]);
      setItemCount(data.count);
    }

    addItemsCheck(itemCountRef.current);
  };

  const deleteItemsCheck = itemData => {
    let items = [];
    items = itemsRef.current.filter(data => data.count !== itemData.count);

    if (
      items.quantity == '' ||
      items.quantity === 0 ||
      items.quantity === '0' ||
      items.description == '' ||
      items.quantity == undefined ||
      items.quantity < 0 ||
      items.description == undefined
    ) {
      setItemCheck(false);
    } else {
      setItemCheck(true);
    }
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmailRef.current, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmailRef.current.cc_email;
    var send_to_email = dataCustomEmailRef.current.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit('sent', false, true);
    }
  };

  const openCustomEmail = async () => {
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    var employee_data = await getEmployee();
    if (employee_data.email) {
      cc_email = employee_data.email;
    } else if (employee_data.user.email) {
      cc_email = employee_data.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const handleCloseActions = () => {
    setAnchorElOrdersType(null);
  };

  const handleOpenActions = event => {
    setAnchorElOrdersType(event.currentTarget);
  };

  const handleFileDelete = index => {
    var current_files = uploadFilesRef.current;
    var new_upload_files = [];

    current_files.map((value, file_index) => {
      if (file_index != index) {
        new_upload_files.push(value);
      }
    });

    setUploadFiles(new_upload_files);
  };

  const handleSubmission = async (e) => {
    try {
      handleCloseActions(false);
      e.preventDefault();

      
      var file_data = document.getElementById('fileInput');
      const form = document.querySelector('#my-form');
      const data = new FormData(form);
      
      
      var current_files = uploadFilesRef.current;
      var new_upload_files = [];
      new_upload_files = [
        ...current_files.concat({
          name: file_data.files.item(0).name,
          formData: data
        })
      ];
      
      setUploadFiles(new_upload_files);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  var disabledCheck = selectedCustomerRef.current === null || itemCheck === false;

  const itemTotalCount = (qty, unit_price, item_discount) => {
    let total = unit_price;
    
    if (qty && qty > 0) {
      total = qty * unit_price;
      if (item_discount) {
        if (item_discount !== '0' && item_discount !== 0) {
          const total_item_discount = (total * item_discount) / 100;
          total -= total_item_discount;
        }
      }
    }

    return isNaN(total) ? '$0.00' : '$' + formatNumber((Math.round(total * 100) / 100).toFixed(2));
  }

  const handleChangeSalesItem = (item, oldData) => {
    setItems(
      itemsRef.current.map(dataList =>
        dataList.id === oldData.id
          ? dataList.statusValue === 1
            ? { ...oldData, statusValue: 1, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
            : { ...oldData, statusValue: 2, internal_code: item.item_number, description: item.description, unit_price: item.price, sales_item: item }
          : dataList
      )
    );
    addItemsCheck(itemCountRef.current);
    countTotal();
  }

  let delivery_address_check = deliveryAddressOptionIdRef.current == -1 ? inputAddressValueRef.current : selectedDeliveryAddressRef.current.AddressLine1;

  return (
    <>
      <Grid container spacing={0} justifyContent="space-around">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography noWrap component="div" style={{ paddingRight: '20px', fontWeight: '590', fontSize: '20px' }}>
              <OverflowTip>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '5px' }}>
                  Sales Order
                  <InsertDriveFileIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#516573',
                      padding: '3px',
                      borderRadius: '50%',
                      fontSize: '20px',
                      marginRight: '5px'
                    }}
                  />
                </div>
              </OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexEnd}>  
          <OverflowTip>
            <div style={{ display: 'flex', columnGap: '10px' }}>
              <IconButton aria-label="delete" size="small" style={{  height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', paddingRight: '20px', paddingLeft: '20px' }}>
                <img src='/static/images/icons/draft.svg' alt='Draft Status Icon' />
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ paddingLeft: '10px', color: '#374355', lineHeight: '22px', textTransform: 'capitalize' }}
                >
                  <OverflowTip>Draft</OverflowTip>
                </Typography>
              </IconButton>
            </div>
          </OverflowTip>
        </Grid>
      </Grid>
        <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
          <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
            <>
              {/* -------------------- CUSTOMER DETAILS -------------------- */}
              <div className='supplier'>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle}
                  style={{ paddingLeft: '16px' }}
                >
                  <OverflowTip>Customer Details</OverflowTip>
                </Typography>
                <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', padding: '5px'}}>
                  <Grid container spacing={0}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Customer Name</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8}>    
                      <Autocomplete
                        id="customer-options"
                        options={customerOptionsRef.current}
                        className={classes.autocompleteEditable}
                        filterOptions={filterOptions}
                        disableClearable
                        popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
                        getOptionLabel={(option) => option.name ?? ''}
                        isOptionEqualToValue={(option, value) => option.id == value.id }
                        value={selectedCustomer ?? ''}
                        onClose={() => resetCustomer()}
                        sx={{
                          '& .MuiAutocomplete-input': { 
                            textAlign: 'right',
                            color: 'rgba(60, 60, 67, 0.6)',
                          },
                        }}
                        onChange={(event, newValue) => {
                          const enable_click = checkEnableClick(newValue);

                          if (enable_click) {
                            setSelectedCustomer(newValue);
                            setDeliveryAddressOptionId(0);
                            setSelectedDeliveryAddress({
                              AddressLine1: '',
                              AddressLine2: '',
                              AddressName: '',
                              AddressCity: '',
                              AddressState: '',
                              AddressPostcode: '',
                            });
                            changeDeliveryAddressOption(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder='Select Customer'
                          />
                        )}
                        renderOption={(props, option) => {
                          const enable_click = checkEnableClick(option)

                          let isSelected = false;
                          if (selectedCustomer) {
                            isSelected = option.id === selectedCustomer.id;
                          }

                          return (
                            <MenuItem
                              {...props}
                              selected={isSelected}
                              className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                              disabled={!enable_click}
                            >
                              <Box display="flex" justifyContent="space-between" width="100%"> 
                                <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                                <div onClick={e => clickCustomerFavorite(option)}>
                                  {option.isFavourite ? (
                                    <StarRoundedIcon fontSize="small" />
                                  ) : (
                                    <StarBorderRoundedIcon fontSize="small" />
                                  )}
                                </div>
                              </Box>
                            </MenuItem>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </>
          </Grid>
          {/* -------------------- DATE -------------------- */}
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <div className='supplier'>
              <Typography
                noWrap
                component="div"
                className={classes.typographyStyle}
                style={{ paddingLeft: '16px' }}
              >
                <OverflowTip>Date</OverflowTip>
              </Typography>
              <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ paddingLeft: '16px' }}
                >
                  <OverflowTip>{moment(Date.now()).format('DD MMMM YYYY')}</OverflowTip>
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* -------------------- TABLE HEADER -------------------- */}
        <Grid container spacing={0} justifyContent="space-around" style={{ paddingLeft: '5px', marginTop: '20px', width: 'calc(100% - 29px)' }}>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
              <OverflowTip>Code</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
              <OverflowTip>Description</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
              <OverflowTip>Qty</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
              <OverflowTip>Unit Price</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
              <OverflowTip>Item Discount</OverflowTip>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
            <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px', paddingRight: '16px', textAlign: 'right'}}>
              <OverflowTip>Total Price</OverflowTip>
            </Typography>
          </Grid>
        </Grid>

        {/* -------------------- TABLE BODY -------------------- */}
        { itemsRef.current ?
          itemsRef.current.map((dataItem, index) => {
            const isLastItem = index === itemsRef.current.length - 1;

            return (
              <Grid key={index} container spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
                <Grid item style={{ width: 'calc(100% - 29px)', background: 'white', paddingLeft: '5px', borderRadius: '10px' }}>
                  <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      {itemsLoading ?
                        <div style={{ margin: '18px 11px' }}>
                          <LinearProgress />
                        </div>
                      :
                        <Autocomplete
                          id={`order_item_code_${index}`}
                          options={itemsOptionsRef.current}
                          className={classes.autocompleteEditable}
                          filterOptions={filterOptionsItemNumberAndDescription}
                          disableClearable
                          classes={{
                            paper: classes.paperstyle
                          }}
                          popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
                          getOptionLabel={(option) => option.item_number ?? ''}
                          isOptionEqualToValue={(option, value) => option.id == value.id }
                          value={dataItem.sales_item ?? ''}
                          sx={{
                            '& .MuiAutocomplete-input': { 
                              textAlign: 'left',
                              color: 'black',
                            },
                          }}
                          onChange={(event, newValue) => {
                            handleChangeSalesItem(newValue, dataItem);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                            />
                          )}
                          renderOption={(props, option) => {
                            let isSelected = false;
                            if (dataItem.sales_item) {
                              isSelected = option.id === dataItem.sales_item.id;
                            }

                            return (
                              <MenuItem
                                {...props}
                                selected={isSelected}
                                key={`code-` + option.id}
                                className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                              >
                                <Box display="flex" justifyContent="space-between" width="100%">
                                  <Grid container spacing={0} justifyContent="space-around">
                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                      {option.item_number}
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridStyle}>
                                      {option.description}
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                      {isNaN(option.price) ? 0 : '$' + formatNumber((Math.round(option.price * 100) / 100).toFixed(2))}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </MenuItem>
                            )
                          }}
                        />
                      }
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                      {itemsLoading ?
                          <div style={{ margin: '18px 11px' }}>
                            <LinearProgress />
                          </div>
                        :
                          <Autocomplete
                            id={`order_item_description_${index}`}
                            options={itemsOptionsRef.current}
                            className={`${!dataItem.description ? classes.requiredAutoComplete : ''} ${classes.autocompleteEditable}`}
                            // className={classes.autocompleteEditable}
                            filterOptions={filterOptionsItemNumberAndDescription}
                            disableClearable
                            classes={{
                              paper: classes.paperstyle
                            }}
                            popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
                            getOptionLabel={(option) => option.description ?? ''}
                            isOptionEqualToValue={(option, value) => option.id == value.id }
                            value={dataItem.sales_item ?? ''}
                            sx={{
                              '& .MuiAutocomplete-input': { 
                                textAlign: 'left',
                                color: 'black',
                              },
                            }}
                            onChange={(event, newValue) => {
                              handleChangeSalesItem(newValue, dataItem);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                              />
                            )}
                            renderOption={(props, option) => {
                              let isSelected = false;
                              if (dataItem.sales_item) {
                                isSelected = option.id === dataItem.sales_item.id;
                              }

                              return (
                                <MenuItem
                                  {...props}
                                  selected={isSelected}
                                  key={`description-` + option.id}
                                  className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                >
                                  <Box display="flex" justifyContent="space-between" width="100%">
                                    <Grid container spacing={0} justifyContent="space-around">
                                      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                        {option.item_number}
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridStyle}>
                                        {option.description}
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridStyle}>
                                        {isNaN(option.price) ? 0 : '$' + formatNumber((Math.round(option.price * 100) / 100).toFixed(2))}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </MenuItem>
                              )
                            }}
                          />
                      }
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                      <TextField
                        id={`order_item_quantity_${index}`}
                        variant='outlined'
                        className={
                          dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                            ? classes.textfield3
                            : classes.textfield3Required
                        }
                        fullWidth
                        name="quantity"
                        type='number'
                        value={dataItem.quantity ? dataItem.quantity : ''}
                        onChange={event => changeItems(event, dataItem)}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      <TextField
                        id={`order_item_unit_price_${index}`}
                        variant='outlined'
                        className={classes.textfield3}
                        fullWidth
                        name="unit_price"
                        value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : ''}
                        onChange={event => changeItems(event, dataItem)}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          readOnly: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      <TextField
                        id={`order_item_discount_${index}`}
                        variant='outlined'
                        className={classes.textfield3}
                        fullWidth
                        name="item_discount"
                        value={dataItem.item_discount ? dataItem.item_discount : 0}
                        onChange={event => changeItems(event, dataItem)}
                        InputProps={{
                          inputComponent: NumberFormatCustomPercent
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                      <TextField
                        id={`order_item_total_price_${index}`}
                        variant='outlined'
                        className={classes.textfield4}
                        fullWidth
                        name="total_price"
                        value={itemTotalCount(dataItem.quantity, dataItem.unit_price, dataItem.item_discount)}
                        readOnly
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                  <IconButton onClick={() => deleteItems(dataItem)} aria-label="delete" size="small" style={{ width: '22px', height: '22px'}} >
                    <img src='/static/images/icons/remove.svg' alt='Remove Icon' />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })
        : ''}

        {/* -------------------- ADD ITEM -------------------- */}
        <div style={{ display: 'flex', paddingLeft: '6px', marginTop: '15px' }}>
          <AddItemButton
            variant="outlined"
            onClick={addItems}
            startIcon={<img src='/static/images/icons/add-item.svg' alt='Add Icon' />}
          >
            Add Item
          </AddItemButton>
        </div>

        {/* -------------------- DELIVERY -------------------- */}
        <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ marginTop: '17px', paddingLeft: '16px' }}
            >
              <OverflowTip>Delivery</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px 0px 5px 5px' }}>
              <Grid container spacing={0} style={{ paddingBottom: '5px', paddingRight: '5px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery by</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.datepickerEditable}
                        inputVariant='outlined'
                        id="date_picker"
                        inputProps={{
                          readOnly: true,
                        }}
                        format="dd MMMM yyyy"
                        placeholder="dd/MM/yyyy"
                        value={moment(selectedDeliveryDate).format()}
                        onChange={date => handleDeliveryDateChange(date)}
                        keyboardIcon={<img src='/static/images/icons/calendar.svg' alt='Calendar Icon' />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <div style={{ backgroundColor: 'white' }}>
                <Divider style={{ marginLeft: '11px' }} />
              </div>
              <Grid container spacing={0} style={{ padding: '5px 5px 5px 0px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery instructions</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>    
                  <TextField
                    variant="outlined"
                    className={classes.textfield2Editable}
                    value={deliveryInstructions ?? ''}
                    placeholder='Add delivery notes'
                    name="delivery_instructions"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <div style={{ backgroundColor: 'white' }}>
                <Divider style={{ marginLeft: '11px' }} />
              </div>
              <Grid container spacing={0} style={{ padding: '5px 5px 0px 0px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery address</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Autocomplete
                    id="delivery-address-options"
                    options={deliveryAddressOptions}
                    className={classes.autocompleteEditable2}
                    disableClearable
                    popupIcon={<img src='/static/images/icons/updown.svg' alt='Dropdown Icon' />}
                    getOptionLabel={(option) => option.lable || ''}
                    isOptionEqualToValue={(option, value) => option == value }
                    value={deliveryAddress ?? ''}
                    sx={{
                      height: '49px !important',
                      '& .MuiAutocomplete-input': { 
                        textAlign: 'right',
                        color: 'rgba(60, 60, 67, 0.6)',
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '49px !important',
                      }
                    }}
                    onChange={(event, newValue) => {
                      setDeliveryAddress(newValue);
                      formattingDeliveryAddress(newValue);
                      setDeliveryAddressOptionId(newValue.id);
                      setDeliveryAddressOptionSelected('');
                      setInputAddressValue('');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                    renderOption={(props, option) => {
                      let isSelected = false;
                      if (deliveryAddress) {
                        isSelected = option.id === deliveryAddress.id;
                      }

                      return (
                        <MenuItem
                          {...props}
                          selected={isSelected}
                          className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                        >
                          <Box display="flex" justifyContent="space-between" width="100%"> 
                            <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word', textOverflow: 'ellipsis', whiteSpace: 'normal', paddingRight: '5px' }}>
                              {option.lable}
                            </span>
                          </Box>
                        </MenuItem>
                      )
                    }}
                  />
                </Grid>
                {deliveryAddressOptionIdRef.current == -1 ?
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      autocompletionRequest={{
                        componentRestrictions: { country: "au" },
                        types: ['address'],
                        fields: ["address_components", "geometry", "icon", "name"]
                      }}
                      selectProps={{
                        inputValue: inputAddressValueRef.current,
                        className: `select-address-autocomplete address`,
                        placeholder: 'Search address...',
                        blurInputOnSelect: true,
                        onInputChange: (value, { action }) => {
                          if (action === "input-change") {
                            setInputAddressValue(value)
                          }
                        },
                        onChange: (data) => {
                          onChangeAddress(data);
                        },
                        styles: {
                          option: (provided, { isFocused, isSelected }) => ({
                            ...provided,
                            background: isSelected ? "#d5f2be !important" : isFocused ? "#d5f2be !important" : "white"
                          })
                        },
                        value: deliveryAddressOptionSelected,
                        controlShouldRenderValue: true,
                        isClearable: true,
                        filterOption: filterOption,
                      }}
                    />
                  </Grid>
                : ''}
              </Grid>
            </div>

            

            {/* Notes */}
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ marginTop: '17px', paddingLeft: '16px' }}
            >
              <OverflowTip>Notes</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px' }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>   
                  <TextField
                    variant="outlined"
                    className={`${classes.textfield2Editable} ${classes.leftAlignTextfield}`}
                    value={notes ?? ''}
                    placeholder='Insert note...'
                    name="notes"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          {/* --------------------- TOTALS --------------------- */}
          <Grid item xs={5} sm={5} md={5} lg={5} >
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ marginTop: '17px', paddingLeft: '16px' }}
            >
              <OverflowTip>Totals</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
              {showTotalDetails ?
                <>
                  <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                        <OverflowTip>
                          {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                        </OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                        <OverflowTip>
                          {isNaN(totalTaxRef.current) ? 0 : '$' + formatNumber((Math.round(totalTaxRef.current * 100) / 100).toFixed(2))}
                        </OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginLeft: '16px' }} />
                  <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '16px' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', justifyContent: 'center' }}>
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle4}
                        style={{ lineHeight: '22px' }}
                      >
                        <OverflowTip>Subtotal</OverflowTip>
                      </Typography>
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle2}
                        style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                      >
                        <OverflowTip>Including tax</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                      <Typography
                        noWrap
                        component="div"
                        className={classes.typographyStyle4}
                        style={{ color: 'rgba(60, 60, 67, 0.6)' }}
                      >
                        <OverflowTip>
                          {isNaN(subTotalIncludingTaxRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalIncludingTaxRef.current * 100) / 100).toFixed(2))}
                        </OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginLeft: '16px' }} />
                </>
              :
                <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '16px', height: '44px' }}>
                  <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                    <Typography className={classes.typographyStyle4} style={{ color: 'black' }}>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                    <Typography className={classes.typographyStyle4} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                      <OverflowTip>
                        {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                      </OverflowTip>
                    </Typography>
                  </Grid>
                </Grid>
              }
              <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Order Discount</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <TextField
                    variant="outlined"
                    className={classes.textfield2Editable}
                    value={orderDiscountRef.current ? parseFloat(orderDiscountRef.current).toFixed(2) : ''}
                    name="order_discount"
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '5px', height: '44px' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Shipping</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <TextField
                    variant="outlined"
                    className={classes.textfield2Editable}
                    value={shippingRef.current ? parseFloat(shippingRef.current).toFixed(2) : ''}
                    name="shipping"
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginLeft: '16px' }} />
              <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer2} style={{ paddingRight: '5px'}}>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle4}
                    style={{ lineHeight: '22px' }}
                    >
                    <OverflowTip>Total</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle4}
                    style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                  >
                    <OverflowTip>
                      {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                    </OverflowTip>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* -------------------- BUTTON -------------------- */}
        <div style={{ width: '100%', display: 'flex', marginTop: '30px'}}>
          <div style={{ width: '50%', flexWrap: 'wrap', display: 'flex', gap: '10px', overflowX: 'auto' }}>
            {uploadFilesRef.current && uploadFilesRef.current.length > 0
              ? uploadFilesRef.current.map((file, index) => {
                return (
                  <Grid key={index} span="true" style={{ display: 'flex' }}>
                    <Chip
                      icon={<InsertDriveFileIcon />}
                      label={file.name}
                      onDelete={() => handleFileDelete(index)}
                      variant="outlined"
                      style={{ marginRight: '5px' }}
                    />
                  </Grid>
                );
              })
            : ''}
          </div>
          <div style={{ width: '50%', maxWidth: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px', flexWrap: 'wrap' }}>
            {loadingSubmit ? (
              <span display="inline-flex" style={{ marginTop: '5px' }}>
                <CircularProgress size={20} />
              </span>
            ) : (
              <>
                <CustomButton
                  variant="contained"
                  onClick={handleOpenActions}
                >
                  Actions
                </CustomButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorElOrdersType}
                  keepMounted
                  open={Boolean(openActions)}
                  onClose={handleCloseActions}
                  className={classes.listStyle}
                >
                  <List>
                    <MenuItem className={classes.menuitem2} onClick={() => {}} >
                      <form id="my-form" className={classes.form}>
                        <input type="file" name="file" id="fileInput" onChange={e => handleSubmission(e)} className={classes.input} />
                        <label htmlFor="fileInput" className={classes.fileInputLabel}>Attach File</label>
                      </form>
                    </MenuItem>
                    {selectedCustomer ? (
                      selectedCustomer.id ? (
                        selectedCustomer.email ? (
                          <MenuItem
                            className={classes.menuitem}
                            disabled={disabledCheck || !delivery_address_check}
                            onClick={() => editSubmit('sent', true)}
                          >
                            Submit and Send
                          </MenuItem>
                        ) : null
                      ) : null
                    ) : null}

                    {selectedCustomer ? (
                      selectedCustomer.id ? (
                        selectedCustomer.email ? (
                          <MenuItem
                            className={classes.menuitem}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck || !delivery_address_check}
                          >
                            Submit and Custom Send
                          </MenuItem>
                        ) : null
                      ) : null
                    ) : null}
                  </List>
                </StyledMenu>

                <CustomButton
                  variant="contained"
                  onClick={() => editSubmit('draft')}
                >
                  Save Draft
                </CustomButton>
                <CustomButton2
                  variant="contained"
                  onClick={() => editSubmit('sent')}
                  disabled={disabledCheck || !delivery_address_check}
                >
                  Submit
                </CustomButton2>
              </>
            )}
          </div>
        </div>

        <Dialog open={isCustomEmail} onClose={closeCustomEmail}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Submit and Custom Send</h2>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px' }}>
          <TextField
            id="email_text"
            label="Email"
            variant="outlined"
            fullWidth={true}
            name="send_to_email"
            value={dataCustomEmail.send_to_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
          <br />
          <br />
          <TextField
            id="cc_email_text"
            label="CC Email"
            variant="outlined"
            fullWidth={true}
            name="cc_email"
            value={dataCustomEmail.cc_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
        </DialogContent>

        <DialogActions style={{ height: '60px', marginRight: '5px' }}>
          {customEmailLoading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => sendEmail()}
                disabled={!dataCustomEmail.send_to_email}
              >
                Save
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddOrder