import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    fontFamily: 'SF Pro',
    fontWeight: 400
  },
  box: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden"
  },
  button: {
    width: "100%",
    maxWidth: 700,

    "& .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "left",
      display: "block"
    }
  },
  wrapper: {
    width: "100%",
    display: 'flex',
    flex: '1 1 auto',
    overflowY: "auto",
    height: "100%",
    // paddingTop: "23px"
  },
  wrapper2: {
    // marginTop: 175
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    overflowX: "hidden"
  },
  filterBar: {
    display: "flex",
    flexWrap: "wrap",
    background: "white",
    width: "100%",
    boxShadow: "inset 0 0 3px rgb(0 0 0 / 30%)",
    paddingLeft: "33px",
    paddingBottom: "15px",
    zIndex: "999",
    marginTop: "75px"
  },
  selectDate: {
    height: "26px",
    borderRadius: "10px",
    border: "1px solid rgba(0,0,0,0.15)",
    paddingLeft: "10px"
  },
  dateDialog: {
    '& .MuiPaper-root.MuiDialog-paper': {
      maxWidth: "fit-content"
    }
  },
  myOrder: {
    backgroundColor: '#67C200',
    color: 'white',
    '&:hover': {
      backgroundColor: '#67C200',
    },
  },
  arrowDropDown: {
    height: "26px",
    width: "20px",
    textAlign: "center",
    cursor: "pointer"
  },
  selectedOrderStatus: {
    lineHeight: "18px",
    fontFamily: 'SF Pro',
    fontSize: "12px",
    height: "100%",
    display: "flex",
    padding: "0 6px",
    borderRadius: "16px",
  },
  cancelIcon: {
    '&:hover': {
      color: '#000',
    },
    cursor: "pointer",
    color: "#858585"
  },
  leftData: {
    marginLeft: "5px",
    backgroundColor: "#c5c5c5",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: '#a3a3a3',
    },
  },
  circular: {
    color: '#5bfa17'
  },
  sidebar: {
    width: "300px",
    background: "#FFFFFF",
    paddingTop: "105px",
    color: "#374355",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "16.39px",
  },
  sidebarContent: {
    padding: "0  20px 20px 20px",
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%"
  },
  filterHeader: {
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "20.49px",
    position: "sticky",
    top: "0",
    backgroundColor: "#FFFFFF",
    zIndex: "999",
    paddingBottom: "9px"
  },
  filterText: {
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "400"
  },
  myOrderSwitch: {
    marginRight: "0px",
    '& .MuiSwitch-root': {
      marginRight: "-12px"
    },
    '& .MuiSwitch-switchBase': {
      transitionDuration: '300ms',
      '&.Mui-checked': {
        color: '#82CB43',
        '& + .MuiSwitch-track': {
          backgroundColor: '#82CB43',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
  },
  dateChip: {
    '& .MuiChip-label': {
      paddingLeft: "10px",
      paddingRight: "10px"
    }
  },
  quickFilter: {
    backgroundColor: "#FFFFFF !important", 
    color: "#516573 !important", 
    border: "1px solid #516573",
    marginBottom: "10px",
    
    width: "100%",
    maxWidth: 700,

    "& .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "center",
      display: "block",
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: "400",
    }
  },
  quickFilterEditable: {
    backgroundColor: "#FFFFFF !important", 
    color: "#516573 !important", 
    border: "1px solid #516573",
    marginBottom: "10px",
    
    width: "100%",
    maxWidth: 700,

    "& .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "center",
      display: "block",
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: "400",
      color: '#82CB43',
    }
  },
  selectedQuickFilter: {
    backgroundColor: "#82CB43 !important", 
    color: "#FFFFFF !important", 
    border: "1px solid #82CB43",
    marginBottom: "10px",
    
    width: "100%",
    maxWidth: 700,

    "& .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "center",
      display: "block"
    }
  },
  selectedQuickFilterEditable: {
    backgroundColor: "#82CB43 !important", 
    color: "#FFFFFF !important", 
    border: "1px solid #82CB43",
    marginBottom: "10px",
    
    width: "100%",
    maxWidth: 700,

    "& .MuiButton-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "center",
      display: "block",
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: "400",
      color: 'white',
    }
  },
  radioFilter: {
    padding: "0",
    marginRight: "10px",
    height: "24px",
    '& .MuiIconButton-label .MuiSvgIcon-root': {
      color: "#E5E5E5"
    },
    '&.MuiRadio-colorSecondary.Mui-checked .MuiIconButton-label .MuiSvgIcon-root': {
      color: "#82CB43 !important"
    },
  } ,
  searchbox: {
    width: '100px',
    height: '26px',
    borderRadius: '8px',
    border: "1px solid rgba(0, 0, 0, 0.1)",
    // backgroundColor: 'rgba(142,142,147,0.12)',
    '& .MuiInputBase-input.MuiInputBase-inputAdornedStart': {
      paddingRight: "10px"
    }
  },
  IconSearch: {
    height: '15px',
    width: '15px',
    color: '#516573',
    marginLeft: '11px'
  },
  selectMenu: {
    '& .MuiListItem-root.Mui-focusVisible':{
      backgroundColor: "red !important"
    },
    '&.MuiListItem-root.Mui-focusVisible':{
      backgroundColor: "red !important"
    }
  }
}));