/* eslint-disable no-constant-condition */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import directus from "../../../services/directus";
import moment from "moment";
import { useSnackbar } from 'notistack';
import linq from "linq";
import ErrorMessage from 'src/components/ErrorMessage';
import { formatNumber, configurationFields } from 'src/components/ReusableFunction';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { useStyles } from '../approvaldetailstyle';
import { getStatusIconPath, LoadingButtonCustom } from 'src/components/ReusableStyle';
import { OverflowTip } from "src/components/OverflowTip";
import { Grid, Typography } from "@material-ui/core";
import { Divider, Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';

function AllAwaitingApproval() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [isAwaitingApprovalGroupOpen, setIsAwaitingApprovalGroupOpen] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [, setPage, pageRef] = useStateRef(1);
  const [showPOAccount, setShowPOAccount] = useState(false);
  const [, setTotalPage, totalPageRef] = useStateRef("");
  const [, setAwaitingApprovalsTotal, awaitingApprovalsTotalRef] = useStateRef(0);
  const [awaitingApprovalsData, setAwaitingApprovalsData] = useState(null);
  const [, setSelectedOrderId, selectedOrderIdRef] = useStateRef(window.location.pathname.split('/').pop());
  const [ordersLoading, setOrdersLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true; // component mount
    if (!initialized) {
      getConfigurationSettings();
      setInitialized(true);
      loadAwaitingApprovalTotal();
      loadAwaitingApproval();
    }

    const onSearch = async eventData => {
      setPage(1);
      await loadAwaitingApproval(eventData.text);
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener = EventEmitter.addListener('/approvals/:id', onSearch);

    const onReject = async () => {
      setPage(1);
      await loadAwaitingApprovalTotal();
      await loadAwaitingApproval('', false, true);
    };
    const listener2 = EventEmitter.addListener('action_approval', onReject);

    return () => {
      isMounted.current = false;
      listener.remove();
      listener2.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration', { fields: configurationFields});
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (isMounted.current && result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_po_account === true) {
          setShowPOAccount(true);
        }
      }
    }
  }

  const loadAwaitingApprovalTotal = async () => {
    try {
      var awaitingApprovalTotalValue = await directus.api.get('/custom/orders/awaiting_approval/total');      
      if (isMounted.current) {
        setAwaitingApprovalsTotal(awaitingApprovalTotalValue.total);
      }    
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const loadAwaitingApproval = async (searchWord, loadMoreStatus = false, rejectApprovalStatus = false) => {    
    if (!loadMoreStatus && !rejectApprovalStatus) {
      setOrdersLoading(true);
    }

    let currentPage = pageRef.current;

    const params_filter = [
      "*",
      "employee.id",
      "employee.supervisors.supervisor.id",
      "employee.supervisors.supervisor.user.first_name",
      "employee.supervisors.supervisor.user.last_name",
      "supplier.id",
      "supplier.code",
      "supplier.name",
      "farm_code.id",
      "farm_code.code",
      "farm_code.name",
      "invoices.orders_id.id",
      "order_items.order_item.quantity",
      "order_items.order_item.unit_price",
      "id",
      "status",
      "order_date",
      "order_number",
      "supplier",
      "farm_code",
      "approvals.*",
      "approvals.level.id",
      "approvals.level.level",
      "approvals.employee.id",
      "approvals.employee.user.id",
      "approvals.employee.user.first_name",
      "approvals.employee.user.last_name",
      "approvals.employee.supervisors.supervisor.id",
      "approvals.employee.supervisors.supervisor.user.first_name",
      "approvals.employee.supervisors.supervisor.user.last_name",
    ];

    if(loadMoreStatus) {
      currentPage+=1;
    }

    var request_header = {
      fields: params_filter,
      meta: "filter_count",
      limit: 100,
      page: currentPage,
    }

    if (searchWord) {
      request_header['q'] = searchWord;
    }
    
    var params = Object.entries(request_header).map(e => e.join('=')).join('&');
    let Orders = await directus.api.post(`/custom/orders/awaiting_approval?${params}`);

    if (Orders.data) {
      Orders.data.map(order => {
        if (order.approvals && order.approvals.length > 0) {
          // Find the approval with the highest level
          order.approvals = [order.approvals.reduce((highest, approval) => {
            const numericLevel = parseInt(approval.level.level, 10);
            return numericLevel > parseInt(highest.level.level, 10) ? approval : highest;
          })];
        }
        return order;
      });
    }
    

    if (isMounted.current) {
      setTotalPage(Orders.meta.page_count);
    }

    let SortOrder = Orders.data;

    SortOrder.forEach((value, index) => {
      var supplier_name = "";
      var customer_name = "";
      var matched_invoice_flag = false;

      if (value.supplier !== null) {
        if (value.supplier.name) {
          supplier_name = value.supplier.name;
        }
      }

      if (value.farm_code !== null) {
        if (value.farm_code.name) {
          customer_name = value.farm_code.name;
        }
      }

      SortOrder[index].supplier_name = supplier_name;
      SortOrder[index].customer_name = customer_name;

      let totalOrder = 0;
      //eslint-disable-next-line array-callback-return
      if (value.order_items) {
        value.order_items.forEach(data => {
          if (data.order_item !== null) {
            totalOrder = totalOrder + data.order_item.unit_price * data.order_item.quantity;
          }
        });
      }

      if (value.invoices && value.invoices.length > 0) {
        matched_invoice_flag = true;
      }
      SortOrder[index].total_order = totalOrder;
      SortOrder[index].matched_invoice_flag = matched_invoice_flag;

      SortOrder[index].statusIconPath = getStatusIconPath(value.status);
    });

    SortOrder = linq
      .from(SortOrder)
      .orderByDescending(o => o.last_approval_date)
      .toArray();

    let awaiting_approvals_data = SortOrder;

    if (SortOrder) {
      if(loadMoreStatus) {
        let currentOrders = awaitingApprovalsData;
        let newOrders = currentOrders.concat(SortOrder);
        awaiting_approvals_data = newOrders;
      }
    }

    if (rejectApprovalStatus) {
      if (awaiting_approvals_data && awaiting_approvals_data.length > 0) {
        const firstOrderID = awaiting_approvals_data[0].id;
        setSelectedOrderId(firstOrderID);
        navigate(`/approvals/${firstOrderID}`);
        EventEmitter.emit("selected_awaiting_approval");
      } else {
        navigate(`/approvals`);
        EventEmitter.emit("selected_awaiting_approval");
      }
    }
    
    if (isMounted.current) {
      setAwaitingApprovalsData(awaiting_approvals_data);
      setPage(currentPage);
      setLoadMore(false);
      setOrdersLoading(false);
    }
  };

  const handleClickOrder = (e, orderId) => {
    e.stopPropagation();
    navigate(`/approvals/${orderId}`);
    setSelectedOrderId(orderId);
    EventEmitter.emit("selected_awaiting_approval");
  }

  const handleClickAwaitingApprovalGroup = () => {
    setIsAwaitingApprovalGroupOpen(!isAwaitingApprovalGroupOpen);
  };

  const handleClickLoadMore = () => {
    setLoadMore(!loadMore);
    loadAwaitingApproval('', true);
  }

  return (
    <div style={{ paddingBottom: '99px' }}>
      {!ordersLoading ?
        awaitingApprovalsData ?
          awaitingApprovalsData.length > 0 ? (
            <div key='orde-awaiting_approval'>
              <div key='order-awaiting_approval' onClick={() => handleClickAwaitingApprovalGroup()}>
                <div className='month-header-wrapper' style={{ display: 'flex', marginBottom: '6px', cursor: 'pointer' }}>
                  <Typography noWrap component="div" className={classes.headerStyle}>
                    <OverflowTip>
                      Awaiting Approval
                      {awaitingApprovalsTotalRef.current && awaitingApprovalsTotalRef.current !== 0 && awaitingApprovalsTotalRef.current !== '0' ?
                        <span className={classes.badgeTotal}>{awaitingApprovalsTotalRef.current}</span>
                      : ''}
                    </OverflowTip>
                  </Typography>
                  <div className={classes.arrowDownWrapper}>
                    {isAwaitingApprovalGroupOpen ? 
                      <KeyboardArrowDownIcon style={{ color: 'rgba(0, 122, 255, 1)' }} />
                    :
                      <KeyboardArrowRightIcon style={{ color: 'black' }} />
                    }
                  </div>
                </div>
                <div style={{ display: isAwaitingApprovalGroupOpen ? 'block' : 'none' }}>
                  {awaitingApprovalsData && awaitingApprovalsData.length > 0 && awaitingApprovalsData.map((order, index) => {
                    const isHighlight = order.id == selectedOrderIdRef.current;
                    const isLastIndex = index === awaitingApprovalsData.length - 1;

                    return (
                      <div
                        key={`order-list-${index}`}
                        className='order-list-wrapper'
                        style={{ borderRadius: '10px', backgroundColor: 'white', border: isHighlight ? '2px solid #82CB43' : 'none', cursor: 'pointer', marginBottom: isLastIndex ? '25px' : '10px' }}
                        onClick={(e) => handleClickOrder(e, order.id)}
                      >   
                        <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                            <img src={"/static/images/icons/po-icon.svg"} alt='Purchase Order Icon' />
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem} style={{ paddingLeft: '5px' }}>
                            <Typography component="div" className={classes.typographyStyle3}>
                              <OverflowTip>
                                {order.approvals && order.approvals.length > 0 ?
                                  order.approvals[0].employee ?
                                    order.approvals[0].employee.supervisors && order.approvals[0].employee.supervisors.length > 0 ?
                                      order.approvals[0].employee.supervisors[0].supervisor.user ?
                                        `${order.approvals[0].employee.supervisors[0].supervisor.user.first_name} ${order.approvals[0].employee.supervisors[0].supervisor.user.last_name}`
                                      : ''
                                    : ''
                                  : ''
                                :
                                  order.employee ?
                                    order.employee.supervisors && order.employee.supervisors.length > 0 ?
                                      order.employee.supervisors[0].supervisor.user ?
                                        `${order.employee.supervisors[0].supervisor.user.first_name} ${order.employee.supervisors[0].supervisor.user.last_name}`
                                      : ''
                                    : ''
                                  : ''
                                }
                              </OverflowTip>
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                            >
                              <OverflowTip>{order.last_approval_date ? moment(order.last_approval_date).format('DD/MM/YYYY') : '-'}</OverflowTip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <div style={{ backgroundColor: 'white' }}>
                          <Divider style={{ marginLeft: '49px'}} />
                        </div>
                        <Grid container spacing={0} justifyContent="space-around" className={showPOAccount ? classes.gridContainer2 : classes.gridContainer}>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                          </Grid>
                          <Grid item xs={9} sm={9} md={9} lg={9} className={classes.gridItem} style={{ paddingLeft: '5px' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                            >
                              <OverflowTip>Supplier: {order.supplier_name ?? ''}</OverflowTip>
                              {showPOAccount ?
                                <OverflowTip>Account: {order.farm_code ? order.farm_code.name ?? '' : ''}</OverflowTip>
                              : ''}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <KeyboardArrowRightIcon style={{ color: 'rgba(60, 60, 67, 0.3)'}} />
                          </Grid>
                        </Grid>
                        <div style={{ backgroundColor: 'white' }}>
                          <Divider style={{ marginLeft: '49px'}} />
                        </div>
                        <Grid container spacing={0} justifyContent="space-around" className={classes.gridContainer}>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridItem}>
                            <img src={order.statusIconPath} alt='Status Icon' />
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={7} className={classes.gridItem} style={{ paddingLeft: '5px' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle3}
                              style={{ fontSize: '15px', textTransform: 'capitalize' }}
                            >
                              <OverflowTip>{order.status === 'awaiting_approval' ? 'Awaiting Approval' : order.status}</OverflowTip>
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                            <Typography
                              component="div"
                              className={classes.typographyStyle2}
                              style={{ color: 'rgba(60, 60, 67, 0.6)', fontSize: '15px' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                {order.invoices ?
                                  order.invoices.length > 0 ?
                                    <InsertDriveFileIcon
                                      style={{
                                        color: 'white',
                                        backgroundColor: 'rgba(60, 60, 67, 0.6)',
                                        padding: '3px',
                                        borderRadius: '50%',
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                        marginRight: '5px'
                                      }}
                                    />
                                  : ''
                                : ''}
                                <OverflowTip>{isNaN(order.total_order) ? 0 : '$' + formatNumber(order.total_order.toFixed(2))}</OverflowTip>
                              </div>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
          : ''
        : ''
      : (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="rounded" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
          </Grid>
        </Grid>
      )}

      {!ordersLoading && awaitingApprovalsData && awaitingApprovalsData.length > 0 ?
        pageRef.current < totalPageRef.current ?
          <LoadingButtonCustom
            loading={loadMore}
            loadingPosition="end"
            endIcon={<AddIcon />}
            variant="outlined"
            style={{ marginBottom: '20px' }}
            onClick={() => handleClickLoadMore()}
          >
            <span style={{ textTransform: 'capitalize', fontFamily: 'SF Pro' }}>Load More</span>
          </LoadingButtonCustom>
        :''
      :''
      }
    </div>
  )
}

export default AllAwaitingApproval