import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import PasswordReset from 'src/views/auth/ResetPasswordPage';
import Invoices from 'src/views/Invoice';
import Setting from 'src/views/setting';
import MatchInvoice from 'src/views/MatchInvoice/index';
import SupplierRequest from 'src/views/SupplierRequests/index';
import * as permission from 'src/utils/Permission';
import AllPurchaseOrders from './views/AllOrder/AllPurchaseOrders';
import AllSalesOrders from './views/AllOrder/AllSalesOrders';
import PurchaseOrderDetail from './views/PurchaseOrders/PurchaseOrderDetail';
import SalesOrderDetail from './views/SalesOrders/SalesOrderDetail';
import Approvals from './views/Approvals/Approvals';

let configuration = JSON.parse(window.localStorage.getItem('configuration'));
let disabledPurchaseOrders = false;
let disabledSalesOrders = false;

if (configuration) {
  if (!configuration.show_purchase_orders) {
    disabledPurchaseOrders = true;
  }

  if (!configuration.show_sales_orders) {
    disabledSalesOrders = true;    
  }
}

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: disabledPurchaseOrders ? <AllSalesOrders /> : <AllPurchaseOrders /> },
      { path: 'purchase-orders', element: disabledPurchaseOrders ? <Navigate to="/" /> : <AllPurchaseOrders /> },
      { path: 'purchase-orders/:id', element: disabledPurchaseOrders ? <Navigate to="/" /> : <PurchaseOrderDetail /> },
      { path: 'purchase-orders/new', element: disabledPurchaseOrders ? <Navigate to="/" /> : <PurchaseOrderDetail /> },
      { path: 'sales-orders', element: disabledSalesOrders ? <Navigate to="/" /> : <AllSalesOrders /> },
      { path: 'sales-orders/:id', element: disabledSalesOrders ? <Navigate to="/" /> : <SalesOrderDetail /> },
      { path: 'sales-orders/new', element: disabledSalesOrders ? <Navigate to="/" /> : <SalesOrderDetail /> },
      { path: 'invoices', element: permission.POCreatorPermission() && permission.ShowInvoiceTab() ? <Invoices /> : <Navigate to="/" /> },
      { path: 'invoice/:id', element: permission.POCreatorPermission() && permission.ShowInvoiceTab() ? <MatchInvoice /> : <Navigate to="/" /> },
      { path: 'approvals', element: <Approvals type='approvals' /> },
      { path: 'approvals/:id', element: <Approvals type='approvals' /> },
      { path: 'approvals-history', element: <Approvals type='approvals-history' /> },
      { path: 'approvals-history/:id', element: <Approvals type='approvals-history' /> },
      { path: 'settings', element: permission.AdminPermission() ? <Setting /> : <Navigate to="/" /> },
      { path: 'supplier_requests', element: <SupplierRequest /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'passwordreset', element: <PasswordReset /> },
      { path: 'sign-in', element: <LoginView /> },
      { path: 'sign-up', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/approvals" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
