/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import PageContent from 'src/components/Page';
import Paper from '@material-ui/core/Paper';
import { enableRipple } from '@syncfusion/ej2-base';
import directus from '../../services/directus';
import {
  ApprovedButton,
  ArrowButton,
  CancelMatchButton,
  CssTextField,
  CssTextField2,
  CssTextFieldQuantity,
  CustomTooltip,
  MatchButton,
  MatchedPOButton,
  RejectButton,
  UndoButton,
  useStyles,
  useStyles2
} from './style.js';
import useStateRef from 'react-usestateref';
import EventEmitter from 'src/utils/EventEmitter';
import linq from 'linq';
import Enumerable from 'linq';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ErrorMessage from 'src/components/ErrorMessage';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import useStyles3 from 'src/views/_styles/style1';
import { InvoiceAdminPermission, POApproverPermission, AdminPermission, POApproverPermissionOnly } from 'src/utils/Permission';
import { getEmployee } from 'src/utils/sessions';
import { getCache, setCache } from 'src/utils/KeyValueCache';

import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import AddIcon from '@material-ui/icons/Add';

import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import Box from '@mui/material/Box';

import { Col, ColsWrapper } from 'react-grid-resizable';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Bugsnag from '@bugsnag/js';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import {
  CancelledButton
} from 'src/views/_styles/style1';
import { getInvoiceApprovers } from './InvoiceApprovers';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

let page = 1;
let status;

var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search('interax') !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

var searchOrdersTimeout;
const IndexView = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const { state } = useLocation();

  var prevLocation = state ? (state.prevLocation ? state.prevLocation : '/invoices') : '/invoices';

  const [invoiceCurrentPosition, setInvoiceCurrentPosition, invoiceCurrentPositionRef] = useStateRef(state ? state.position : null);
  var saveSortData = state ? state.saveSortData : null;
  var saveFilterData = state ? state.saveFilterData : null;
  var totalPage = state ? state.totalPage : null;
  var pageNumber = state ? state.pageNumber : 100;
  var invoiceList = state ? state.invoiceList : 100;

  const [initialized, setInitialized] = useState(false);
  const [invoices, setInvoices, invoicesRef] = useStateRef(null);
  const [invoicesTemp, setInvoicesTemp, invoicesTempRef] = useStateRef(null);
  const [invoicesRaw, setInvoicesRaw, invoicesRawRef] = useStateRef({
    raw_supplier: '',
    raw_customer: '',
    raw_po_number: '',
    total_amount: ''
  });
  const [dataInvoice, setDataInvoice, dataInvoiceRef] = useStateRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const [orders, setOrders] = useState('');
  const [orderData, setOrderData, orderDataRef] = useStateRef([]);
  const [open, setOpen, openRef] = useStateRef(false);
  const [loadOrdersOptions, setLoadOrdersOptions, loadOrdersOptionsRef] = useStateRef({
    first_order: null,
    orders_id: null,
    filter_supplier: null,
    filter_customer: null
  });

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [matchedLoading, setMatchedLoading] = useState(false);
  const [rejectedLoading, setRejectedLoading] = useState(false);
  const [undoLoading, setUndoLoading] = useState(false);
  const [resolveLoading, setResolveLoading] = useState(false);
  const [matchedLoading2, setMatchedLoading2] = useState(false);
  const navigate = useNavigate();

  const [openApproved, setOpenApproved, openApprovedRef] = useStateRef(false);
  const [approvedLoading, setApprovedLoading] = useState(false);

  const [openUnapproved, setOpenUnapproved, openUnapprovedRef] = useStateRef(false);
  const [unapprovedLoading, setUnapprovedLoading, unapprovedLoadingRef] = useState(false);

  const [selectedApprovedData, setSelectedApprovedData] = useState('');

  const [openMatchInfo, setOpenMatchInfo, openMatchInfoRef] = useStateRef(false);

  const [loadingUpdateInvoice, setLoadingUpdateInvoice] = useState(false);

  const [selectedInvoice, setSelectedInvoice, selectedInvoiceRef] = useStateRef(null);
  const [openDeleteInvoice, setOpenDeleteInvoice, openDeleteInvoiceRef] = useStateRef(false);
  const [loadingDeleteInvoice, setLoadingDeleteInvoice] = useState(false);
  const [loadingOCR, setLoadingOCR] = useState(false);
  const [loadingProcessWithoutPO, setLoadingProcessWithoutPO] = useState(false);
  const [loadingUnmatchedPO, setLoadingUnmatchedPO] = useState(false);

  const [matchedInvoiceData, setMatchedInvoiceData, matchedInvoiceDataRef] = useStateRef(null);
  const [matchedInvoiceDataNew, setMatchedInvoiceDataNew, matchedInvoiceDataNewRef] = useStateRef(null);
  const [invoiceAtt, setInvoiceAtt, invoiceAttRef] = useStateRef({
    invoicestatus: ''
  });

  const [invoiceFile, setInvoiceFile, invoiceFileRef] = useStateRef(null);
  const [invoiceAttachedFile, setInvoiceAttachedFile, invoiceAttachedFileRef] = useStateRef(null);
  const [matchedInvoiceFile, setMatchedInvoiceFile, matchedInvoiceFileRef] = useStateRef(null);
  const [checkInvoiceDate, setCheckInvoiceDate, checkInvoiceDateRef] = useStateRef({
    date: false,
    raw_due_date: false
  });

  const [openRejectedDialog, setOpenRejectedDialog, openRejectedDialogRef] = useStateRef(false);
  const [disputeNotes, setDisputeNotes, disputeNotesRef] = useStateRef('');

  const [collectionData, setCollectionData, collectionDataRef] = useStateRef([]);
  const [collectionDataOrder, setCollectionDataOrder, collectionDataOrderRef] = useStateRef([]);

  const [selectedMatchedInvoice, setSelectedMatchedInvoice, selectedMatchedInvoiceRef] = useStateRef(null);

  const [approveFlag, setApproveFlag, approveFlagRef] = useStateRef(false);
  const [isApproverLevel2, setIsApproverLevel2, isApproverLevel2Ref] = useStateRef(false);
  const [invoice2ApprovalLevels, setInvoice2ApprovalLevels, invoice2ApprovalLevelsRef] = useStateRef(false);

  const [allowDeleteInvoice, setAllowDeleteInvoice, allowDeleteInvoiceRef] = useStateRef(false);

  const [openSplitInvoice, setOpenSplitInvoice, openSplitInvoiceRef] = useStateRef(false);
  const [selectedInvoiceFile, setSelectedInvoiceFile, selectedInvoiceFileRef] = useStateRef(null);

  const [orderDataList, setOrderDataList, orderDataListRef] = useStateRef([]);
  const [selectedOrderData, setSelectedOrderData, selectedOrderDataRef] = useStateRef(null);
  const [isDuplicate, setIsDuplicate, isDuplicateRef] = useStateRef(false);

  const [isClickFavourite, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);

  const [isProcessMatched, setIsProcessMatched, isProcessMatchedRef] = useStateRef(true);
  const [isComplete, setIsComplete, isCompleteRef] = useStateRef(false);

  //supplier
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier, selectedSupplierRef] = useStateRef(null);
  const [suppliers, setSuppliers, suppliersRef] = useStateRef(null);
  const [suppliers2, setSuppliers2, suppliersRef2] = useStateRef(null);

  const [anchorElSupplier, setAnchorElSupplier] = React.useState(null);
  const open2 = Boolean(anchorElSupplier);
  const supplierListRef = React.useRef();
  const filterRef = React.useRef();

  //customer
  const [customerLoading, setCustomerLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  const [customers, setCustomers, customersRef] = useStateRef(null);
  const [customers2, setCustomers2, customersRef2] = useStateRef(null);

  const [anchorElCustomer, setAnchorElCustomer] = React.useState(null);
  const open3 = Boolean(anchorElCustomer);
  const customerListRef = React.useRef();
  const filter2Ref = React.useRef();

  const [enableApprovalWarning, setEnableApprovalWarning, enableApprovalWarningRef] = useStateRef(true);
  const [quantityMatchedOrder, setQuantityMatchedOrder, quantityMatchedOrderRef] = useStateRef(null);
  const [quantityMatchedOrderDefault, setQuantityMatchedOrderDefault, quantityMatchedOrderDefaultRef] = useStateRef(null);

  const [invoicesOrderItemsData, setInvoicesOrderItemsData, invoicesOrderItemsDataRef] = useStateRef([]);
  const initialWidth = {
    left: localStorage.left_width && localStorage.left_width != "NaN%" ? localStorage.left_width : '70%',
    right: localStorage.right_width && localStorage.right_width != "NaN%" ? localStorage.right_width : '30%'
  };

  const [loadingFile, setLoadingFile] = React.useState(false);

  const [selectedAttachedFileInvoice, setSelectedAttachedFileInvoice, selectedAttachedFileInvoiceRef] = useStateRef(null);
  const [openDeleteAttachedFileInvoice, setOpenDeleteAttachedFileInvoice, openDeleteAttachedFileInvoiceRef] = useStateRef(false);

  const [loadingNaviation, setLoadingNavigation, loadingNavigationRef] = useStateRef(false);

  const [loadingSplitCopyInvoice, setLoadingSplitCopyInvoice] = useState(false);

  const isUpdateMatchedByLine = true;
  const [openDeleteInvoiceDialog, setOpenDeleteInvoiceDialog] = React.useState(false);

  const [loadingDeleteDialog, setLoadingDeleteDialog] = useState(false);
  const isMounted = useRef(true);

  const [showApproveButton, setShowApproveButton, showApproveButtonRef] = useStateRef(false);
  const [showAwaitingApprovalButton, setShowAwaitingApprovalButton, showAwaitingApprovalButtonRef] = useStateRef(false);
  /* eslint-enable no-unused-vars*/

  enableRipple(true);
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    isMounted.current = true; // component mount
    if (!initialized) {
      setInitialized(true);
      setInvoiceLoading(true);
      loadEmployeeData();
      loadSuppliers();
      loadCustomers();
      loadInvoices(null, true, false);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const onShowApprovalWarning = eventData => {
      setEnableApprovalWarning(eventData.value);
    };

    const listener = EventEmitter.addListener('show_approval_warning', onShowApprovalWarning);
    return () => {
      listener.remove();
    };
  }, []);

  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadEmployeeData = async () => {
    var employee_data = await getEmployee();
    if (isMounted.current && employee_data) {
      if (employee_data.enable_approval_warning === false) {
        setEnableApprovalWarning(false);
      }
    }
  };

  const loadSuppliers = async () => {
    setSupplierLoading(true);

    let cache = getCache('suppliers');

    try {
      var Suppliers = { data: [] };

      if (!cache) {
        Suppliers = await directus.getItems('suppliers', {
          fields: '*',
          sort: 'name',
          limit: -1,
          filter: {
            status: 'published'
          }
        });

        setCache('suppliers', Suppliers.data);
      } else {
        Suppliers.data = cache;
      }

      var suppliers_data = Suppliers.data;
      var fav_suppliers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_suppliers_po) {
          fav_suppliers = directus_employee.favourites_suppliers_po;
        }
      }

      suppliers_data.forEach((value, index) => {
        if (fav_suppliers.indexOf(value.id) >= 0) {
          suppliers_data[index].isFavourite = true;
        } else {
          suppliers_data[index].isFavourite = false;
        }
      });

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      if (isMounted.current) {
        setSuppliers(suppliers_data);
        setSuppliers2(suppliers_data);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    if (isMounted.current) {
      setSupplierLoading(false);
    }
  };

  const loadCustomers = async () => {
    setCustomerLoading(true);

    var customerCache = getCache('customers');
    try {
      var Customers = { data: [] };

      if (!customerCache) {
        // var employee_id = await getEmployee();
        var fields = `id, code, name, selectable, access_level, accounting_system, legal_name, 
        suppliers.id, suppliers.status, suppliers.supplier.*`;

        Customers = await directus.getItems('customers', {
          fields: fields,
          sort: 'name',
          filter: { status: 'published' }
        });

        setCache('customers', Customers.data);
      } else {
        Customers.data = customerCache;
      }

      Customers = Customers.data;

      var customers_data = Customers;
      var fav_customers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      customers_data.forEach((value, index) => {
        if (fav_customers.indexOf(value.id) >= 0) {
          customers_data[index].isFavourite = true;
        } else {
          customers_data[index].isFavourite = false;
        }
      });

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      if (isMounted.current) {
        setCustomers(customers_data);
        setCustomers2(customers_data);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    if (isMounted.current) {
      setCustomerLoading(false);
    }
  };

  const loadInvoices = async (dataId, first_load = false, show_duplicate_message = false) => {
    try {
      let InvoiceId = dataId ? dataId : window.location.pathname.split('/').pop();
      let filterId;
      let invoice_id = null;

      if (dataId) {
        invoice_id = dataId;
      } else {
        invoice_id = InvoiceId;
      }

      filterId = { id: { eq: invoice_id } };

      var invoice_fields =
        'orders.orders_id.order_items.order_item.category_type.*, orders.orders_id.order_items.order_item.*, orders.orders_id.order_items.*.*';
      invoice_fields += ', orders.orders_id.employee.*, orders.orders_id.farm_code.*, orders.orders_id.supplier.*';
      invoice_fields += ', orders.orders_id.files.*.*';
      invoice_fields +=
        ', orders.orders_id.invoices.invoices_id.files.*, orders.orders_id.invoices.invoices_id.files.directus_files_id.*,orders.orders_id.invoices.*,orders.orders_id.invoices.invoices_id.*';
      invoice_fields +=
        ', orders.orders_id.category_type.*, orders.orders_id.owner.first_name, orders.orders_id.owner.last_name, orders.orders_id.*,orders.*';
      invoice_fields += ', files.directus_files_id.*,files.*';
      invoice_fields += ', attached_files.directus_files_id.*,attached_files.*';
      invoice_fields += ', farm_code.accounting_system.*,farm_code.*';
      invoice_fields += ', *.*';

      var batch_body = {
        invoices: {
          fields: invoice_fields,
          meta: 'filter_count',
          filter: filterId
        }
      };

      if (isUpdateMatchedByLine) {
        batch_body.invoices_order_items = {
          filter: { invoice_id: invoice_id, status: 'published' },
          limit: -1,
          sort: '-created_on'
        };
      }

      var batch_result = await directus.api.post('custom/batch', batch_body);

      var Invoices = { data: batch_result.data.invoices[0] };

      var collection_list = [];
      let totalOrderInvoice = 0;
      let invoicestatus;
      var checkDuplicate = false;
      var value = null;

      if (Invoices.data == null) {
        navigate('/invoices');
        EventEmitter.emit('reload_item', { value: '/invoices' });
        EventEmitter.emit('checkShowHideFilter', { value: true });
      } else {
        if (Invoices.data) {
          var matched_invoice_data = [];

          value = Invoices.data;
          if (value.supplier) {
            if (isMounted.current) {
              setSelectedSupplier({ id: value.supplier.id, name: value.supplier.name });
            }
          } else {
            if (isMounted.current) {
              setSelectedSupplier(null);
            }
          }
          if (value.farm_code) {
            if (isMounted.current) {
              setSelectedCustomer({
                id: value.farm_code.id,
                name: value.farm_code.name,
                bypass_approval: value.farm_code.bypass_approval
              });
            }
          } else {
            if (isMounted.current) {
              setSelectedCustomer(null);
            }
          }

          checkDuplicate = await checkDuplicateInvoice(value);

          if (isUpdateMatchedByLine) {
            getInvoicesOrderItemsData(batch_result.data.invoices_order_items);
          }

          var order_number = '';
          if (value.orders) {
            var new_orders = [];
            value.orders.forEach(value => {
              if (value.orders_id) {
                new_orders.push(value);
              }
            });

            value.orders = new_orders;

            value.orders = linq
              .from(value.orders)
              .orderBy(o => o.orders_id.order_number)
              .toArray();

            if (isMounted.current && value.orders.length > 0) {
              setOrderDataList(value.orders);
              setSelectedOrderData(value.orders[0]);
            }

            value.orders.forEach(order_value => {
              var matched_order_data = [];
              var order_data = order_value.orders_id;

              if (order_data) {
                order_number = order_data.order_number;
                if (order_data.category_type) {
                  if (order_data.category_type.collection) {
                    collection_list.push(order_data.category_description);
                  }
                }

                if (order_data.order_items) {
                  order_data.order_items.forEach(order_items_value => {
                    if (order_items_value.order_item) {
                      if (order_items_value.order_item.category_type) {
                        if (order_items_value.order_item.category_type.collection) {
                          collection_list.push(order_items_value.order_item.category_description);
                        }
                      }
                    }
                  });
                }

                order_data.invoices.forEach(async invoice_value => {
                  var invoice_data = invoice_value.invoices_id;
                  let totalOrder = 0;

                  if (invoice_data) {
                    if (!invoice_data.total_amount) {
                      order_data.order_items.forEach(item => {
                        if (item.order_item) {
                          totalOrder = totalOrder + item.order_item.unit_price * item.order_item.quantity;
                        }
                      });
                    } else {
                      totalOrder = invoice_data.total_amount;
                    }

                    if (value.id !== invoice_data.id) {
                      var supplier_name = '';
                      if (invoice_data.raw_supplier) {
                        supplier_name = invoice_data.raw_supplier;
                      } else {
                        if (order_data.supplier) {
                          supplier_name = order_data.supplier.name;
                        }
                      }

                      matched_order_data.push({
                        date: invoice_data.date,
                        number: invoice_data.number,
                        invoice_amount: isNaN(totalOrder) ? 0 : '$' + formatNumber(totalOrder.toFixed(2)),
                        date_matched: order_value.date_matched,
                        raw_supplier: supplier_name,
                        id: invoice_data.id,
                        files: invoice_data.files,
                        order_number: order_number
                      });
                    }
                  } else {
                    await directus.deleteItem('invoices_orders', invoice_value.id);
                  }
                });

                if (matched_order_data.length > 0) {
                  matched_order_data = linq
                    .from(matched_order_data)
                    .orderBy(o => o.date)
                    .toArray();
                }
                matched_invoice_data.push({ order_id: order_data.id, order_data: matched_order_data });
              }
            });
          }

          if (matched_invoice_data.length > 0) {
            matched_invoice_data = linq
              .from(matched_invoice_data)
              .orderBy(o => o.id)
              .toArray();
          }

          if (isMounted.current) {
            setIsDuplicate(checkDuplicate);
          }

          if (show_duplicate_message && checkDuplicate) {
            var message = 'There is already another invoice with this number in the system';
            enqueueSnackbar(message, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'warning',
              autoHideDuration: 4200,
              action: CloseSnackbarAction
            });
          }

          if (isMounted.current) {
            setMatchedInvoiceData(matched_invoice_data);
          }
        }

        if (collection_list.length > 0) {
          var activities = getCache('activities', []);
          var resources = getCache('resources', []);

          if (resources.length === 0) {
            var resources_result = await directus.getItems('resources', { limit: -1 });
            setCache('resources', resources_result.data);
            resources = resources_result.data;
          }

          if (activities.length === 0) {
            var activities_result = await directus.getItems('activities', { limit: -1 });
            setCache('activities', activities_result.data);
            activities = activities_result.data;
          }

          var new_data = {};
          new_data.activities = activities.filter(value => collection_list.includes(value.id.toString()));
          new_data.resources = resources.filter(value => collection_list.includes(value.id.toString()));

          if (isMounted.current) {
            setCollectionData(new_data);
          }
        }

        if (value.invoice_status === 'unmatched') {
          // invoicestatus = { backgroundColor: '#FFF0DD' };
        } else if (value.invoice_status === 'disputed') {
          invoicestatus = { backgroundColor: '#FFDBD9' };
        } else if (value.invoice_status === 'matched' || value.invoice_status === 'awaiting_approval_level_2') {
          invoicestatus = { backgroundColor: '#D4E3F2' };
        } else if (value.invoice_status === 'approved') {
          invoicestatus = { backgroundColor: '#D0F2D4' };
        } else {
          invoicestatus = { backgroundColor: '#FFFFFF' };
        }

        if (Invoices.data != null) {
          var invoice_files = [];
          const promises = Invoices.data.files.map(async (file, index) => {
            const requestOptions = {
              method: 'HEAD'
            };
            var data_found = false;

            try {
              await fetch(`${react_app_url}${file.directus_files_id.data.asset_url}`, requestOptions).then(res => {
                if (res.status === 200) {
                  data_found = true;
                }
              });
            } catch (error) {
              data_found = false;
            }
            file.data_found = data_found;
            file.uploaded_on = file.directus_files_id ? file.directus_files_id.uploaded_on : '';

            invoice_files.push(file);
          });

          await Promise.all(promises);
          if (invoice_files.length > 0) {
            invoice_files = Enumerable.from(invoice_files)
              .orderBy(o => o.uploaded_on)
              .toArray();
          }



          var invoice_attached_files = [];
          const promises2 = Invoices.data.attached_files.map(async (file, index) => {
            const requestOptions2 = {
              method: 'HEAD'
            };
            var data_found = false;

            try {
              await fetch(`${react_app_url}${file.directus_files_id.data.asset_url}`, requestOptions2).then(res => {
                if (res.status === 200) {
                  data_found = true;
                }
              });
            } catch (error) {
              data_found = false;
            }
            file.data_found = data_found;
            file.uploaded_on = file.directus_files_id ? file.directus_files_id.uploaded_on : '';

            invoice_attached_files.push(file);

          });

          await Promise.all(promises2);
          if (invoice_attached_files.length > 0) {
            invoice_attached_files = Enumerable.from(invoice_attached_files)
              .orderBy(o => o.uploaded_on)
              .toArray();
          }
          if (isMounted.current) {
            setInvoiceAttachedFile(invoice_attached_files);
            setInvoiceFile(invoice_files);
          }
        }

        var invoices_data = Invoices.data;
        var first_order = null;
        var filter_supplier = null;
        var filter_customer = null;
        var filter_accounting = null;
        var orders_id = [];
        if (invoices_data) {
          if (invoices_data.orders) {
            if (invoices_data.orders.length > 0) {
              var new_orders = [];

              invoices_data.orders.forEach(value => {
                if (value.orders_id) {
                  new_orders.push(value);
                }
              });

              invoices_data.orders = new_orders;
              orders_id = linq
                .from(invoices_data.orders)
                .select(data => data.orders_id.id)
                .toArray();

              invoices_data.orders.forEach((order_value, index) => {
                var order_data = order_value.orders_id;
                totalOrderInvoice = 0;

                if (index === 0) {
                  first_order = order_value;
                }

                if (order_data) {
                  if (order_data.order_items) {
                    var order_items = order_data.order_items;
                    var new_order_items = [];

                    order_items.forEach(value => {
                      if (value.order_item) {
                        new_order_items.push(value);
                      }
                    });

                    new_order_items = linq
                      .from(new_order_items)
                      .orderBy(o => o.order_item.sort)
                      .toArray();
                    invoices_data.orders[index].orders_id.order_items = new_order_items;

                    order_data.order_items.forEach(order_items_value => {
                      if (order_items_value.order_item) {
                        totalOrderInvoice = totalOrderInvoice + order_items_value.order_item.unit_price * order_items_value.order_item.quantity;
                      }
                    });
                  }
                }
                invoices_data.orders[index].orders_id.total_order = totalOrderInvoice;
              });
            } else {
              if (invoices_data.supplier) {
                filter_supplier = invoices_data.supplier.id;
              }

              if (invoices_data.farm_code) {
                filter_customer = invoices_data.farm_code.id;

                if (invoices_data.farm_code.accounting_system) {
                  filter_accounting = invoices_data.farm_code.accounting_system.id;
                }
              }
            }
          }
        }

        if (isMounted.current) {
          setInvoices(invoices_data);
          setInvoicesTemp(invoices_data);
          getInvoicesRaw(invoices_data);
          setInvoiceAtt({ invoicestatus: invoicestatus });
        }

        // set allow to delete invoice if invoice is unmatched and has no matched orders
        // and user has admin permission
        if (isMounted.current) {
          if (!disableInvoice && value.invoice_status === 'unmatched' && (value.orders == null || value.orders.length === 0)) {
            setAllowDeleteInvoice(true);
          } else {
            setAllowDeleteInvoice(false);
          }
        }

        if (
          (invoices_data.invoice_status === 'matched' ||
            invoices_data.invoice_status === 'awaiting_approval_level_2' ||
            invoices_data.invoice_status === 'approved') &&
          POApproverPermission()
        ) {
          if (isMounted.current) {
            await loadRelatedCustomer(Invoices.data);
          }
        }

        if (isMounted.current) {
          setInvoiceLoading(false);
          setLoadOrdersOptions({
            first_order: first_order,
            orders_id: orders_id,
            filter_supplier: filter_supplier,
            filter_customer: filter_customer,
            filter_accounting: filter_accounting
          });
        }

        loadOrders(first_order, orders_id, filter_supplier, filter_customer, filter_accounting);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const reloadOrders = async (order_number_search) => {
    try {
      var first_order = null;
      var orders_id = [];
      var filter_supplier = null;
      var filter_customer = null;
      var filter_accounting = null;
      var invoices_data = invoicesRef.current;

      if (invoices_data.orders && invoices_data.orders.length > 0) {
        orders_id = linq
          .from(invoices_data.orders)
          .select(data => data.orders_id.id)
          .toArray();

        invoices_data.orders.forEach((order_value, index) => {
          var order_data = order_value.orders_id;

          if (index === 0) {
            first_order = order_value;
          }
        });
      } else {
        if (invoices_data.supplier) {
          filter_supplier = invoices_data.supplier.id;
        }

        if (selectedCustomerRef.current) {
          filter_customer = selectedCustomerRef.current.id;

          if (selectedCustomerRef.current.accounting_system) {
            filter_accounting = selectedCustomerRef.current.accounting_system.id;
          }
        } else {
          if (invoices_data.farm_code) {
            filter_customer = invoices_data.farm_code.id;

            if (invoices_data.farm_code.accounting_system) {
              filter_accounting = invoices_data.farm_code.accounting_system.id;
            }
          }
        }
      }

      loadOrders(first_order, orders_id, filter_supplier, filter_customer, filter_accounting, order_number_search);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const loadOrders = async (first_order, orders_id, filter_supplier, filter_customer, filter_accounting, order_number_search) => {
    try {
      var filter_data = {};
      filter_data = { status: { neq: 'deleted' } };
      var isFilter = false;
      if (first_order) {
        if (first_order.orders_id) {
          if (first_order.orders_id.farm_code) {
            filter_data['farm_code.accounting_system'] = first_order.orders_id.farm_code.accounting_system;
            filter_accounting = first_order.orders_id.farm_code.accounting_system;
            isFilter = true;
          }

          if (first_order.orders_id.supplier) {
            filter_data['supplier.id'] = first_order.orders_id.supplier.id;
            isFilter = true;
          }
        }
      }

      if (!isFilter) {
        if (filter_supplier) {
          filter_data['supplier.id'] = filter_supplier;
        }

        if (filter_customer) {
          filter_data['farm_code'] = filter_customer;
        }

        if (filter_accounting) {
          filter_data['farm_code.accounting_system'] = filter_accounting;
        }
      }

      if (orders_id) {
        if (orders_id.length > 0) {
          filter_data['id'] = { nin: orders_id };
        }
      }

      if (order_number_search) {
        filter_data['order_number'] = { contains: order_number_search }
      }

      var Orders = await directus.getItems('orders', {
        fields: 'id,order_number,invoices.id,farm_code.id,farm_code.accounting_system',
        limit: 100,
        sort: '-order_number',
        filter: filter_data
      });

      if (isMounted.current) {
        setOrders(Orders.data);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const checkDuplicateInvoice = async value => {
    var checkDuplicate = false;

    var other_invoices = await directus.getItems('invoices', {
      fields: 'id,number,raw_supplier,orders.orders_id.supplier.*,supplier.*',
      filter: {
        number: value.number,
        id: { nin: value.id }
      }
    });

    var supplier_name_current = '';
    if (value.orders) {
      if (value.orders.length > 0) {
        var order_data = value.orders[0].orders_id;
        if (order_data) {
          if (order_data.supplier) {
            supplier_name_current = order_data.supplier.name;
          }
        }
      }
    }

    if (!supplier_name_current && value.supplier) {
      supplier_name_current = value.supplier.name;
    }

    if (!supplier_name_current && value.raw_supplier) {
      supplier_name_current = value.raw_supplier;
    }

    if (other_invoices.data.length > 0) {
      other_invoices.data.forEach(other_value => {
        var supplier_name_other = '';

        if (other_value.orders) {
          if (other_value.orders.length > 0) {
            var order_data = other_value.orders[0].orders_id;
            if (order_data) {
              if (order_data.supplier) {
                supplier_name_other = order_data.supplier.name;
              }
            }
          }
        }

        if (!supplier_name_other && other_value.supplier) {
          supplier_name_other = other_value.supplier.name;
        }

        if (!supplier_name_other && other_value.raw_supplier) {
          supplier_name_other = other_value.raw_supplier;
        }

        if (supplier_name_current && supplier_name_other) {
          if (supplier_name_current === supplier_name_other) {
            checkDuplicate = true;
          }
        } else if (!supplier_name_current && !supplier_name_other) {
          checkDuplicate = true;
        }
      });
    }

    return checkDuplicate;
  };

  const getInvoicesOrderItemsData = async data => {
    if (isUpdateMatchedByLine) {
      try {
        if (isMounted.current) {
          setInvoicesOrderItemsData([...data]);
        }
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    }
  };

  //load related customer for PO Approver
  const loadRelatedCustomer = async invoice_data => {
    try {
      const approvals_data = await getInvoiceApprovers(invoice_data);

      var show_approve_button = false;
      var show_awaiting_approval_button = false;
      var invoice_2_approval_levels = false;
      var approve_flag = false;
      var approver_level_2 = false;

      if (approvals_data) {
        invoice_2_approval_levels = approvals_data.invoice_approvals_level === 2;

        if (invoicesRef.current.invoice_status === 'awaiting_approval_level_2') {
          if (approvals_data.is_approver_level_2 || approvals_data.is_approver_admin) {
            show_approve_button = true;
          }
        } else if (invoicesRef.current.invoice_status === "matched") {
          if (approvals_data.invoice_approvals_level !== 2) {
            if (approvals_data.is_approver_level_1 || approvals_data.is_approver_admin) {
              show_approve_button = true;
            }
          } else {
            if (approvals_data.is_approver_level_2) {
              show_approve_button = true;
            } else if (approvals_data.is_approver_level_1) {
              show_awaiting_approval_button = true;
            } else if (approvals_data.is_approver_admin) {
              show_awaiting_approval_button = true;
            }
          }
        }

        if (approvals_data.is_approver_admin || approvals_data.is_approver_level_1 || approvals_data.is_approver_level_2) {
          approve_flag = true;

          if (approvals_data.is_approver_admin || approvals_data.is_approver_level_2) {
            approver_level_2 = true;
          }
        }
      }

      setShowApproveButton(show_approve_button);
      setShowAwaitingApprovalButton(show_awaiting_approval_button);

      setInvoice2ApprovalLevels(invoice_2_approval_levels);

      setApproveFlag(approve_flag);
      setIsApproverLevel2(approver_level_2);

    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

  };

  const searchOrders = newValue => {
    clearTimeout(searchOrdersTimeout);
    searchOrdersTimeout = setTimeout(() => {
      reloadOrders(newValue);
    }, 500);
  }

  const changeOrders = async newValue => {
    try {
      resetCheckbox();
      var isDuplicateOrder = false;
      orderDataListRef.current.forEach(value => {
        if (value.orders_id) {
          if (value.orders_id.id === newValue.id) {
            isDuplicateOrder = true;
          }
        }
      });

      if (!isDuplicateOrder) {
        var orders_fields = 'employee.*, supplier.*, farm_code.*';
        orders_fields += ', order_items.order_item.category_type.*, order_items.order_item.*, order_items.*.*';
        orders_fields += ', invoices.invoices_id.files.*,invoices.invoices_id.files.directus_files_id.*,invoices.invoices_id.*,invoices.*';
        orders_fields += ', *.*';

        var selectedOrders = await directus.getItems('orders', {
          fields: orders_fields,
          filter: { id: { in: newValue.id } },
          limit: -1
        });

        var selected_data = selectedOrders.data[0];
        var order_number = selected_data.order_number;
        const quantity_matched_order = [];
        const quantity_matched_order_default = [];

        if (selected_data.order_items) {
          var order_items = selected_data.order_items;
          var new_order_items = [];

          order_items.forEach(value => {
            if (value.order_item) {
              new_order_items.push(value);
            }
          });

          new_order_items = linq
            .from(new_order_items)
            .orderBy(o => o.order_item.sort)
            .toArray();
          selected_data.order_items = new_order_items;

          var orderItemIds = Enumerable.from(selected_data.order_items)
            .select(s => s.order_item.id)
            .toArray();
          var invoiceOrderItems = await directus.items('invoices_order_items').readMany({
            limit: -1,
            filter: {
              status: { eq: 'published' },
              order_item_id: { in: orderItemIds }
            }
          });

          if (new_order_items.length > 0) {
            new_order_items.forEach(value => {
              var totalQuantity =
                Enumerable.from(invoiceOrderItems.data)
                  .where(w => w.order_item_id === value.order_item.id)
                  .sum(s => s.quantity) || 0;
              var quantity = value.order_item.quantity - totalQuantity;
              quantity_matched_order.push({
                order_item: value.order_item.id,
                quantity: quantity >= 0 ? quantity : 0
              });

              quantity_matched_order_default.push({
                order_item: value.order_item.id,
                quantity: quantity >= 0 ? quantity : 0
              });
            });
          }
        }

        if (isUpdateMatchedByLine) {
          setQuantityMatchedOrder(Object.assign({}, quantity_matched_order));
          setQuantityMatchedOrderDefault(Object.assign({}, quantity_matched_order_default));
        }
        setOrderData([selected_data]);

        var matched_invoice_data = [];
        if (selected_data.invoices) {
          selected_data.invoices.forEach(invoice_value => {
            var invoice_data = invoice_value.invoices_id;

            var totalOrder = 0;
            if (selected_data.order_items) {
              selected_data.order_items.forEach(item => {
                if (item.order_item) {
                  totalOrder = totalOrder + item.order_item.unit_price * item.order_item.quantity;
                }
              });
            }
            if (invoice_data) {
              if (invoice_data.id !== invoicesRef.current.id) {
                var supplier_name = '';
                if (selected_data.supplier) {
                  supplier_name = selected_data.supplier.name;
                }

                if (!supplier_name && invoice_data.supplier) {
                  supplier_name = invoice_data.supplier.name;
                }

                if (!supplier_name && invoice_data.raw_supplier) {
                  supplier_name = invoice_data.raw_supplier;
                }

                matched_invoice_data.push({
                  date: invoice_data.date,
                  invoice_amount: isNaN(totalOrder) ? 0 : '$' + formatNumber(totalOrder.toFixed(2)),
                  date_matched: invoice_value.date_matched,
                  raw_supplier: supplier_name,
                  number: invoice_data.number,
                  id: invoice_data.id,
                  files: invoice_data.files,
                  order_number: order_number
                });
              }
            }
          });
        }

        var collection_list = [];

        if (selected_data) {
          if (selected_data.category_type) {
            if (selected_data.category_type.collection) {
              collection_list.push(selected_data.category_description);
            }
          }

          if (selected_data.order_items) {
            selected_data.order_items.forEach(order_items_value => {
              if (order_items_value.order_item) {
                if (order_items_value.order_item.category_type) {
                  if (order_items_value.order_item.category_type.collection) {
                    collection_list.push(order_items_value.order_item.category_description);
                  }
                }
              }
            });
          }

          if (collection_list.length > 0) {
            var new_data = await directus.api.post('/custom/mobile/timesheets/batch-data', {
              activities: {
                filter: {
                  id: { in: collection_list }
                }
              },
              resources: {
                filter: {
                  id: { in: collection_list }
                }
              }
            });
            setCollectionDataOrder(new_data.data);
          }
        }

        setMatchedInvoiceDataNew(matched_invoice_data);
        setMatchedInvoiceFile(null);
        setSelectedMatchedInvoice(null);

        handleClickOrder();
      } else {
        enqueueSnackbar('This order number has been matched', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 4200,
          action: CloseSnackbarAction
        });
        setOpen(false);
        setOrderData([]);
        setQuantityMatchedOrder(null);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const getInvoicesRaw = data => {
    if (data) {
      var raw_supplier = data.raw_supplier;
      if (!raw_supplier) {
        if (data.orders) {
          if (data.orders.length > 0) {
            if (data.orders[0].orders_id) {
              if (data.orders[0].orders_id.supplier) {
                raw_supplier = data.orders[0].orders_id.supplier.name;
              }
            }
          }
        }
      }

      var raw_customer = data.raw_customer;
      if (!raw_customer) {
        if (data.orders) {
          if (data.orders.length > 0) {
            if (data.orders[0].orders_id) {
              if (data.orders[0].orders_id.farm_code) {
                raw_customer = data.orders[0].orders_id.farm_code.name;
              }
            }
          }
        }
      }

      var raw_po_number = data.raw_po_number;

      var total_amount = data.total_amount ? parseFloat(data.total_amount).toFixed(2) : '';
      if (!total_amount) {
        total_amount = getInvoiceTotalAmount(data);
      }

      setInvoicesRaw({
        raw_supplier: raw_supplier,
        raw_customer: raw_customer,
        raw_po_number: raw_po_number,
        total_amount: total_amount
      });
    }
  };

  const handleClickOrder = () => {
    if (orderDataRef.current) {
      setOpen(true);
      if (orderDataRef.current.status === 'draft') {
        status = { backgroundColor: '#D4E3F2' };
      } else if (orderDataRef.current.status === 'sent') {
        status = { backgroundColor: '#FFF0DD' };
      } else if (orderDataRef.current.status === 'cancelled') {
        status = { backgroundColor: '#FFDBD9' };
      } else if (orderDataRef.current.status === 'completed') {
        status = { backgroundColor: '#DAF0DC' };
      } else {
        status = { backgroundColor: '#FFFFFF' };
      }
    } else {
      setOpen(false);
    }
  };

  const handleClick = id => {
    window.open(id);
  };

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const onProcessMatchedPO = async (id, bypass_approval = false, is_match_order = false) => {
    try {
      setLoadingProcessWithoutPO(true);

      if (isDuplicateRef.current) {
        var message = "There are other invoice number '" + invoicesRef.current.number + "' ";
        if (invoicesRef.current.raw_supplier) {
          message += "with supplier '" + invoicesRef.current.raw_supplier + "' ";
        }
        message += 'that have been matched.';
        enqueueSnackbar(message, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          style: { width: '450px' },
          autoHideDuration: 4200,
          action: CloseSnackbarAction
        });
      } else {
        setMatchedLoading(true);

        var invoice_status = 'matched';
        if (bypass_approval) {
          invoice_status = 'approved';
        } else if (orderDataListRef.current) {
          if (orderDataListRef.current.length > 0) {
            var orders_id = orderDataListRef.current[0].orders_id;
            if (orders_id) {
              if (orders_id.farm_code) {
                invoice_status = orders_id.farm_code.bypass_approval ? 'approved' : 'matched';
              }
            }
          }
        }

        var isSuccess = true;
        var isApproved = false;
        if (invoice_status === 'approved') {
          let company = JSON.parse(window.localStorage.getItem('company'));
          let token = JSON.parse(window.localStorage.getItem('directus_data'));

          const requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token.token
            }
          };

          await fetch(`${react_app_url}${company}/custom/emails/test_send?id=${id}`, requestOptions)
            .then(response => response.blob())
            .then(async result => {
              var result_text = await result.text();
              if (result_text) {
                if (result_text !== 'success') {
                  isSuccess = false;
                  if (result_text == 'No system found to send invoice.') {
                    result_text = 'No accounts email configured for this customer.';
                  }

                  enqueueSnackbar(result_text, {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'error',
                    autoHideDuration: 3200,
                    action: CloseSnackbarAction
                  });
                  closeApprovedDialog();
                }
              }
            });

          if (isSuccess) {
            const message = 'Invoice is being processed and will be sent to the accounting system.';
            enqueueSnackbar(message, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'success',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
            isApproved = true;
          }
        }

        if (isSuccess) {
          setTimeout(async () => {
            var employee_id = await getEmployee();
            if (invoice_status === 'approved') {
              await directus.updateItem('invoices', id, {
                invoice_status: invoice_status,
                previous_invoice_status: invoicesRef.current.invoice_status,
                date_matched: moment().format('YYYY-MM-DD'),
                sent_to_accounting: true,
                approved_by: employee_id.id,
                approved_on: moment().format('YYYY-MM-DD'),
                matched_by: employee_id.id,
                matched_on: moment().format('YYYY-MM-DD'),
                modified_by: employee_id.user.id,
                modified_on: moment().format('YYYY-MM-DD HH:mm:ss')
              });
            } else {
              await directus.updateItem('invoices', id, {
                invoice_status: invoice_status,
                previous_invoice_status: invoicesRef.current.invoice_status,
                date_matched: moment().format('YYYY-MM-DD'),
                matched_by: employee_id.id,
                matched_on: moment().format('YYYY-MM-DD'),
                modified_by: employee_id.user.id,
                modified_on: moment().format('YYYY-MM-DD HH:mm:ss')
              });
            }

            var invoice_id = null;

            await navigateInvoiceId(1).then(async response => {
              invoice_id = response.invoice_id;
              var total_page = response.total_page;

              if (invoice_id) {
                setTimeout(
                  async () => {
                    resetInvoiceData();
                    navigate(`/invoice/${invoice_id}`, {
                      state: {
                        id: invoiceCurrentPositionRef.current,
                        position: invoiceCurrentPositionRef.current,
                        prevLocation: window.location.pathname,
                        saveFilterData: saveFilterData,
                        saveSortData,
                        totalPage: total_page,
                        pageNumber: pageNumber,
                        invoiceList: invoiceList
                      }
                    });
                    await loadInvoices(invoice_id);
                    setMatchedLoading(false);
                  },
                  isApproved ? 2000 : 0
                );
              } else {
                setTimeout(
                  () => {
                    navigate('/invoices');
                    EventEmitter.emit('reload_item', { value: '/invoices' });
                    EventEmitter.emit('checkShowHideFilter', { value: true });
                  },
                  isApproved ? 2000 : 0
                );
              }
            });
          }, 1000);
        } else if (is_match_order) {
          setOrderData([]);
          setQuantityMatchedOrder(null);
          loadInvoices();
          cancelMatchInvoice();
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setMatchedLoading(false);
    setLoadingProcessWithoutPO(false);
  };

  const matchInvoices = async (id, order_data) => {
    try {
      var isUpdate = true;
      var checkDuplicate = false;
      var supplier_name_current = '';
      if (matchedInvoiceDataNewRef.current) {
        if (matchedInvoiceDataNewRef.current.length > 0) {
          if (invoicesRef.current.orders) {
            if (invoicesRef.current.orders.length > 0) {
              var orders_id = invoicesRef.current.orders[0].orders_id;
              if (orders_id) {
                if (orders_id.supplier) {
                  supplier_name_current = orders_id.supplier.name;
                }
              }
            }
          }

          if (!supplier_name_current && invoicesRef.current.supplier) {
            supplier_name_current = invoicesRef.current.supplier.name;
          }

          if (!supplier_name_current && invoicesRef.current.raw_supplier) {
            supplier_name_current = invoicesRef.current.raw_supplier;
          }

          matchedInvoiceDataNewRef.current.forEach(value => {
            if (value.number === invoicesRef.current.number) {
              var supplier_name_other = value.raw_supplier;
              if (!supplier_name_current && !supplier_name_other) {
                checkDuplicate = true;
              } else if (supplier_name_current && supplier_name_other) {
                if (supplier_name_current === supplier_name_other) {
                  checkDuplicate = true;
                }
              }
            }
          });
        }
      }

      if (checkDuplicate || isDuplicateRef.current) {
        if (isProcessMatchedRef.current) {
          isUpdate = false;
        }

        if (isUpdate) {
          let message = "This PO is already matched with invoice '" + invoicesRef.current.number + "' ";
          if (supplier_name_current) {
            message += "and supplier '" + supplier_name_current + "' ";
          }
          enqueueSnackbar(message, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'warning',
            style: { width: '450px' },
            autoHideDuration: 4200,
            action: CloseSnackbarAction
          });
        } else {
          let message = "There are other invoice number '" + invoicesRef.current.number + "' ";
          if (supplier_name_current) {
            message += "with supplier '" + supplier_name_current + "' ";
          }
          message += 'that have been matched.';
          enqueueSnackbar(message, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
            style: { width: '450px' },
            autoHideDuration: 4200,
            action: CloseSnackbarAction
          });
        }
      }

      if (isUpdate) {
        setMatchedLoading(true);

        if (order_data && isCompleteRef.current) {
          if (order_data.status !== 'completed') {
            await directus.updateItem('orders', order_data.id, { status: 'completed' });
          }
        }

        await directus.createItem('invoices_orders', {
          date_matched: moment().format('YYYY-MM-DD'),
          invoices_id: id,
          orders_id: order_data.id
        });

        var selected_supplier = null;
        var selected_customer = null;

        if (orderDataListRef.current.length === 0) {
          if (!invoicesRef.current.supplier && order_data.supplier) {
            selected_supplier = order_data.supplier.id;
          } else {
            selected_supplier = invoicesRef.current.supplier.id;
          }

          if (!invoicesRef.current.farm_code && order_data.farm_code) {
            selected_customer = order_data.farm_code.id;
          } else {
            selected_customer = invoicesRef.current.farm_code.id;
          }

          if (order_data.supplier || order_data.farm_code) {
            await directus.updateItem('invoices', invoicesRef.current.id, {
              supplier: selected_supplier,
              farm_code: selected_customer
            });
          }
        }

        if (isUpdateMatchedByLine) {
          if (quantityMatchedOrderRef.current) {
            var new_quantity_matched = [];

            for (const key in quantityMatchedOrderRef.current) {
              var quantity_value = quantityMatchedOrderRef.current[key];
              new_quantity_matched.push({
                invoice_id: id,
                order_item_id: quantity_value.order_item,
                quantity: quantity_value.quantity === "-" || quantity_value.quantity === "" ? 0 : quantity_value.quantity,
                status: 'published'
              });
            }

            await directus.createItems('invoices_order_items', new_quantity_matched);
          }
        }

        if (!isProcessMatchedRef.current) {
          setOrderData([]);
          setQuantityMatchedOrder(null);
          loadInvoices();
          cancelMatchInvoice();
        } else {
          var bypass_approval = false;
          if (order_data.farm_code) {
            if (order_data.farm_code.bypass_approval) {
              bypass_approval = true;
            }
          }
          onProcessMatchedPO(invoicesRef.current.id, bypass_approval, true);
        }
      }

      setMatchedLoading(false);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const cancelMatchInvoice = () => {
    setOpen(false);
    setMatchedInvoiceDataNew(null);
    setMatchedInvoiceFile(null);
    setSelectedMatchedInvoice(null);
    setOrderData([]);
    setQuantityMatchedOrder(null);
    resetCheckbox();
  };

  const openRejectedInvoiceDialog = () => {
    setOpenRejectedDialog(true);
    setDisputeNotes('');
  };

  const closeRejectedDialog = () => {
    setOpenRejectedDialog(false);
  };

  const handleChangeDisputeNote = e => {
    setDisputeNotes(e.target.value);
  };

  const rejectInvoices = async id => {
    try {
      setRejectedLoading(true);
      await directus.updateItem('invoices', id, {
        invoice_status: 'disputed',
        dispute_notes: disputeNotesRef.current,
        previous_invoice_status: invoicesRef.current.invoice_status
      });

      // Send rejected invoice notification
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + directus.api.auth.config.token },
        body: JSON.stringify({
          invoice_id: invoicesRef.current.id
        })
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }

      let dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/invoices/send_rejected_invoice_notification`;

      try {
        await fetch(dataURL, requestOptions).then(resp => {
          if (resp.status) {
            if (resp.status === 200) {
              enqueueSnackbar('This invoice has been disputed', {
                style: { whiteSpace: 'pre-line' },
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'success',
                autoHideDuration: 3200,
                action: CloseSnackbarAction
              });
            } else {
              enqueueSnackbar(resp.statusText, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'error',
                autoHideDuration: 3200,
                action: CloseSnackbarAction
              });
            }
          } else {
            enqueueSnackbar(ErrorMessage(400), {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
          }
        });
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
      // End of send rejected invoice notification

      var invoice_id = null;

      // Navigate to next invoice
      await navigateInvoiceId(1).then(async response => {
        invoice_id = response.invoice_id;
        var total_page = response.total_page;

        if (invoice_id) {
          resetInvoiceData();
          closeRejectedDialog();
          setInvoiceLoading(true);
          navigate(`/invoice/${invoice_id}`, {
            state: {
              id: invoiceCurrentPositionRef.current,
              position: invoiceCurrentPositionRef.current,
              prevLocation: window.location.pathname,
              saveFilterData: saveFilterData,
              saveSortData,
              totalPage: total_page,
              pageNumber: pageNumber,
              invoiceList: invoiceList
            }
          });
          await loadInvoices(invoice_id);
          setRejectedLoading(false);
        } else {
          navigate('/invoices');
          EventEmitter.emit('reload_item', { value: '/invoices' });
          EventEmitter.emit('checkShowHideFilter', { value: true });
        }
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const resolveInvoices = async id => {
    setResolveLoading(true);

    var status = invoicesRef.current.previous_invoice_status ?? 'matched';
    await directus.updateItem('invoices', id, {
      invoice_status: status
    });

    loadInvoices();
    setResolveLoading(false);
  };

  const undoInvoices = async id => {
    setUndoLoading(true);
    await directus.updateItem('invoices', id, {
      invoice_status: 'unmatched',
      previous_invoice_status: invoicesRef.current.invoice_status,
      date_matched: null
    });

    setOrderData([]);
    setQuantityMatchedOrder(null);
    loadInvoices();
    setUndoLoading(false);
    setOpen(false);
  };

  const openApprovedDialog = async id => {
    setSelectedApprovedData(id);

    if (enableApprovalWarningRef.current) {
      setOpenApproved(true);
    } else {
      approvedInvoice();
    }
  };

  const closeApprovedDialog = async () => {
    setSelectedApprovedData('');
    setOpenApproved(false);
  };

  const closeUnapprovedDialog = async () => {
    setSelectedApprovedData('');
    setUnapprovedLoading(false);
    setOpenUnapproved(false);
  };

  const approvedInvoice = async invoice_id => {
    try {
      setApprovedLoading(true);
      var invoice_id2 = null;
      if (invoice_id) {
        invoice_id2 = invoice_id;
      } else if (selectedApprovedData) {
        invoice_id2 = selectedApprovedData;
      }

      let company = JSON.parse(window.localStorage.getItem('company'));
      let token = JSON.parse(window.localStorage.getItem('directus_data'));

      var employee_id = await getEmployee();

      var isSuccess = true;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.token
        },
        body: JSON.stringify({
          approved_invoice: true
        })
      };

      var is_approver_level_2 = false;

      if (invoicesRef.current.invoice_status === 'awaiting_approval_level_2') {
        is_approver_level_2 = true;
      } else if (invoicesRef.current.invoice_status === 'approved') {
        if (invoice2ApprovalLevelsRef.current && invoicesRef.current.approved_by) {
          is_approver_level_2 = true;
        }
      }

      var query_params = `id=${invoice_id2}`;
      query_params += `&employee=${employee_id.id}`;
      query_params += `&is_approver_level_2=${is_approver_level_2}`;
      query_params += `&approved_on_time=${moment().format('YYYY-MM-DD HH:mm:ss')}`;

      await fetch(`${react_app_url}${company}/custom/emails/test_send?${query_params}`, requestOptions)
        .then(response => response.blob())
        .then(async result => {
          var result_text = await result.text();
          if (result_text) {
            if (result_text !== 'success') {
              isSuccess = false;
              if (result_text === 'No system found to send invoice.') {
                result_text = 'No accounts email configured for this customer.';
              }

              closeApprovedDialog();
              enqueueSnackbar(result_text, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'error',
                autoHideDuration: 3200,
                action: CloseSnackbarAction
              });
            }
          }
        });

      if (isSuccess) {
        setTimeout(async () => {
          var message = 'Invoice is being processed and will be sent to the accounting system.';
          enqueueSnackbar(message, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });

          setOrderDataList([]);
          setOrderData([]);
          setQuantityMatchedOrder(null);
          setSelectedOrderData(null);
          setIsDuplicate(false);
          setOpen(false);
          cancelMatchInvoice();
          setSelectedInvoiceFile(null);
          if (!invoice_id) {
            var invoice_id_2 = null;
            setOpenApproved(false);
            setInvoiceLoading(true);
            await navigateInvoiceId(1).then(async response => {
              invoice_id_2 = response.invoice_id;
              var total_page = response.total_page;

              if (invoice_id_2) {
                resetInvoiceData();
                navigate(`/invoice/${invoice_id_2}`, {
                  state: {
                    id: invoiceCurrentPositionRef.current,
                    position: invoiceCurrentPositionRef.current,
                    prevLocation: window.location.pathname,
                    saveFilterData: saveFilterData,
                    saveSortData,
                    totalPage: total_page,
                    pageNumber: pageNumber,
                    invoiceList: invoiceList
                  }
                });
                await loadInvoices(invoice_id_2);
                setApprovedLoading(false);
              } else {
                loadInvoices();
                setApprovedLoading(false);
              }
            });
          } else {
            setApprovedLoading(false);
            loadInvoices();
          }
        }, 1000);
      } else {
        setApprovedLoading(false);
      }
    } catch (e) {
      setApprovedLoading(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const getOrderNumbers = () => {
    var list = '';

    orderDataListRef.current.forEach((value, index) => {
      if (value.orders_id) {
        list += value.orders_id ? value.orders_id.order_number : '';

        if (index < orderDataListRef.current.length - 1) {
          list += '-';
        }
      }
    });

    return list;
  };

  const exportInvoiceOrder = async invoice_id => {
    try {
      setApprovedLoading(true);
      var invoice_id2 = null;
      if (invoice_id) {
        invoice_id2 = invoice_id;
      } else if (selectedApprovedData) {
        invoice_id2 = selectedApprovedData;
      }

      let company = JSON.parse(window.localStorage.getItem('company'));
      let token = JSON.parse(window.localStorage.getItem('directus_data'));

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.token
        }
      };

      var query_params = `id=${invoice_id2}`;

      await fetch(`${react_app_url}${company}/custom/emails/export_invoice_order?${query_params}`, requestOptions)
        .then(response => response.blob())
        .then(async result => {
          setApprovedLoading(false);

          if (result.type === 'text/html') {
            enqueueSnackbar(JSON.stringify(result.text()), {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
          } else if (result.type === 'application/pdf') {
            const url = window.URL.createObjectURL(result);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            var file_name = 'Invoice-' + invoicesRef.current.number;
            var order_numbers = getOrderNumbers();
            if (order_numbers) {
              file_name += '-PO-' + order_numbers;
            }

            file_name += '.pdf';
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      setApprovedLoading(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const handleClickExportActionLog = async id => {
    const access_token = JSON.parse(window.localStorage.getItem("directus_data")).token;
    const react_app_url = process.env.REACT_APP_URL;
    downloadFile(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export_invoice/action_log/${id}?access_token=${access_token}`);
  };

  const downloadFile = url => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    link.click();
  };

  const resetToUnapproved = async invoice_id => {
    try {
      setUnapprovedLoading(true);

      let invoiceStatus = InvoiceAdminPermission() ? 'matched' : invoice2ApprovalLevelsRef.current ? 'awaiting_approval_level_2' : 'matched';

      // Update invoice status
      let response = await directus.items('invoices').updateOne(invoice_id, {
        invoice_status: invoiceStatus,
        previous_invoice_status: invoicesRef.current.invoice_status
      });

      // Reload invoice data
      await loadInvoices();
    } catch (e) {
      // Report error to Bugsnag
      Bugsnag.notify(e);

      // Show error message to user
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    // Hide loading indicator
    setUnapprovedLoading(false);

    // Close dialog
    setOpenUnapproved(false);
  };

  const approvedInvoiceFirst = async () => {
    try {
      setApprovedLoading(true);
      var invoice_id2 = selectedApprovedData;

      let company = JSON.parse(window.localStorage.getItem('company'));
      let token = JSON.parse(window.localStorage.getItem('directus_data'));

      var employee_id = await getEmployee();

      var isSuccess = true;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.token
        },
        body: JSON.stringify({
          approved_invoice: true
        })
      };

      var is_approver_level_2 = false;

      if (invoicesRef.current.invoice_status === 'awaiting_approval_level_2') {
        is_approver_level_2 = true;
      }

      var query_params = `id=${invoice_id2}`;
      query_params += `&employee=${employee_id.id}`;
      query_params += `&is_approver_level_2=${is_approver_level_2}`;
      query_params += `&approved_on_time=${moment().format('YYYY-MM-DD HH:mm:ss')}`;

      await fetch(`${react_app_url}${company}/custom/emails/test_send?${query_params}`, requestOptions)
        .then(response => response.blob())
        .then(async result => {
          var result_text = await result.text();
          if (result_text) {
            if (result_text !== 'success') {
              isSuccess = false;
              if (result_text === 'No system found to send invoice.') {
                result_text = 'No accounts email configured for this customer.';
              }

              closeApprovedDialog();
              enqueueSnackbar(result_text, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'error',
                autoHideDuration: 3200,
                action: CloseSnackbarAction
              });
            }
          }
        });

      if (isSuccess) {
        setTimeout(async () => {
          var message = 'Invoice is being processed and will be sent to the accounting system.';
          enqueueSnackbar(message, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'success',
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });

          setOrderDataList([]);
          setOrderData([]);
          setQuantityMatchedOrder(null);
          setSelectedOrderData(null);
          setIsDuplicate(false);
          setOpen(false);
          cancelMatchInvoice();
          setSelectedInvoiceFile(null);

          var invoice_id_2 = null;
          setOpenApproved(false);
          setInvoiceLoading(true);
          await navigateInvoiceId(1).then(async response => {
            invoice_id_2 = response.invoice_id;
            var total_page = response.total_page;

            if (invoice_id_2) {
              resetInvoiceData();
              navigate(`/invoice/${invoice_id_2}`, {
                state: {
                  id: invoiceCurrentPositionRef.current,
                  position: invoiceCurrentPositionRef.current,
                  prevLocation: window.location.pathname,
                  saveFilterData: saveFilterData,
                  saveSortData,
                  totalPage: total_page,
                  pageNumber: pageNumber,
                  invoiceList: invoiceList
                }
              });
              await loadInvoices(invoice_id_2);
              setApprovedLoading(false);
            } else {
              loadInvoices();
              setApprovedLoading(false);
            }
          });
        }, 1000);
      } else {
        setApprovedLoading(false);
      }
    } catch (e) {
      setApprovedLoading(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  // const getNextInvoice = () => {
  //   var status = false;
  //   var nextInvoiceIndex = null;
  //   if (invoiceData) {
  //     if (invoiceData.length > 0) {
  //       if (invoiceIndex !== null) {
  //         if (invoiceIndex < invoiceData.length - 1) {
  //           for (var i = invoiceIndex + 1; i < invoiceData.length; i++) {
  //             if (!status && invoiceData[i]) {
  //               if (invoiceData[i].invoice_status !== "approved") {
  //                 status = true;
  //                 nextInvoiceIndex = i;
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return { status: status, index: nextInvoiceIndex };

  // }

  const showMatchInfo = data => {
    setDataInvoice(data);
    setOpenMatchInfo(true);
  };

  const handleCloseMatchInfoDialog = () => {
    setOpenMatchInfo(false);
  };

  const handleChangeEditInvoice = (e, type, temp = false) => {
    if (type === 'date' || type === 'raw_due_date') {
      setCheckInvoiceDate({ ...checkInvoiceDateRef.current, [type]: false });
      setInvoices({ ...invoicesRef.current, [type]: e });
    } else {
      const { name, value } = e.target;
      if (temp) {
        setInvoicesRaw({ ...invoicesRawRef.current, [name]: value });
      } else {
        setInvoices({ ...invoicesRef.current, [name]: value });
      }
    }
  };

  const updateInvoice = async () => {
    try {
      setLoadingUpdateInvoice(true);

      var invoice_data = invoicesRef.current;
      var invoice_data_raw = invoicesRawRef.current;

      const invoice_body = {
        raw_po_number: invoice_data_raw.raw_po_number,
        number: invoice_data.number,
        date: invoice_data.date ? moment(invoice_data.date).format('YYYY-MM-DD') : null,
        supplier: selectedSupplierRef.current ? selectedSupplierRef.current.id : null,
        farm_code: selectedCustomerRef.current ? selectedCustomerRef.current.id : null,
        raw_due_date: invoice_data.raw_due_date ? moment(invoice_data.raw_due_date).format('YYYY-MM-DD') : null,
        total_amount: invoice_data_raw.total_amount,
        notes: invoice_data.notes
      };

      await directus.updateItem('invoices', invoice_data.id, invoice_body);

      loadInvoices(null, false, true);
      setLoadingUpdateInvoice(false);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setLoadingUpdateInvoice(false);
    }
  };

  const saveNotes = async () => {
    if (!loadingUpdateInvoice) {
      try {
        setLoadingUpdateInvoice(true);

        var invoice_data = invoicesRef.current;

        const invoice_body = {
          notes: invoice_data.notes
        };

        await directus.updateItem('invoices', invoice_data.id, invoice_body);

        loadInvoices(null, false, false);
        setLoadingUpdateInvoice(false);
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
        setLoadingUpdateInvoice(false);
      }
    }
  };
  //delete invoice files
  const openDeleteInvoiceFileDialog = file => {
    setSelectedInvoice({ file: file });
    setOpenDeleteInvoice(true);
  };

  const handleCloseDeleteInvoice = file => {
    setSelectedInvoice(null);
    setOpenDeleteInvoice(false);
  };

  const deleteInvoice = async () => {
    setLoadingDeleteInvoice(true);
    if (selectedInvoiceRef.current) {
      try {
        var is_matched_order = false;
        if (invoiceFileRef.current.length === 1) {
          if (invoicesRef.current.invoice_status === 'unmatched' && invoicesRef.current.orders.length > 0) {
            enqueueSnackbar('This invoice can only be deleted if the status is new and unmatched.', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });

            is_matched_order = true;
          } else {
            if (selectedInvoiceRef.current.file.is_attached_file) {
              await directus.deleteItem('invoices_attached_files', selectedInvoiceRef.current.file.id);
            } else {
              await directus.deleteItem('invoices_directus_files', selectedInvoiceRef.current.file.id);
            }
          }
        } else {
          if (selectedInvoiceRef.current.file.is_attached_file) {
            await directus.deleteItem('invoices_attached_files', selectedInvoiceRef.current.file.id);
          } else {
            await directus.deleteItem('invoices_directus_files', selectedInvoiceRef.current.file.id);
          }
        }

        if (invoiceFileRef.current.length > 1) {
          setLoadingDeleteInvoice(false);
          loadInvoices();
          handleCloseDeleteInvoice();
        } else {
          setLoadingDeleteInvoice(false);
          loadInvoices();
          handleCloseDeleteInvoice();
        }
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
        setLoadingDeleteInvoice(false);
      }
    }
  };

  //delete invoice attached files
  const openDeleteAttachedFileInvoiceDialog = file => {
    setSelectedAttachedFileInvoice(file);
    setOpenDeleteAttachedFileInvoice(true);
  };

  const handleCloseDeleteAttachedFileInvoice = () => {
    setSelectedAttachedFileInvoice(null);
    setOpenDeleteAttachedFileInvoice(false);
  };

  const deleteAttachedFileInvoice = async () => {
    setLoadingDeleteInvoice(true);
    if (selectedAttachedFileInvoiceRef.current) {
      try {
        await directus.deleteItem('invoices_attached_files', selectedAttachedFileInvoiceRef.current.id);
        setLoadingDeleteInvoice(false);
        loadInvoices();
        handleCloseDeleteAttachedFileInvoice();
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
        setLoadingDeleteInvoice(false);
      }
    }
  };

  const onReprocessOCR = async value => {
    if (value) {
      setLoadingOCR(true);
      try {
        let company = JSON.parse(window.localStorage.getItem('company'));
        let token = JSON.parse(window.localStorage.getItem('directus_data'));

        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.token
          }
        };

        await fetch(`${react_app_url}${company}/custom/emails/re-process/${value.id}`, requestOptions)
          .then(response => response.json())
          .then(async result => {
            if (result.data) {
              loadInvoices();
            }
          });
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
      setLoadingOCR(false);
    }
  };

  const onProcessWithoutPO = async data => {
    try {
      var supplierFlag = false;
      var customerFlag = false;

      if (selectedSupplierRef.current) {
        if (selectedSupplierRef.current.id) {
          supplierFlag = true;
        }
      }

      if (selectedCustomerRef.current) {
        if (selectedCustomerRef.current.id) {
          customerFlag = true;
        }
      }

      if (supplierFlag && customerFlag) {
        var invoice_status = 'matched';
        if (selectedCustomerRef.current.bypass_approval) {
          invoice_status = 'approved';
        }
        setLoadingProcessWithoutPO(true);

        var isSuccess = true;
        if (invoice_status === 'approved') {
          var checkEmail = false;
          if (data.farm_code) {
            if (data.farm_code.accounting_system) {
              if (data.farm_code.accounting_system.email) {
                checkEmail = true;
              }
            }
          }

          if (checkEmail) {
            let company = JSON.parse(window.localStorage.getItem('company'));
            let token = JSON.parse(window.localStorage.getItem('directus_data'));

            const requestOptions = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.token
              }
            };

            await fetch(`${react_app_url}${company}/custom/emails/test_send?id=${data.id}`, requestOptions)
              .then(response => response.blob())
              .then(async result => {
                var result_text = await result.text();
                if (result_text) {
                  if (result_text !== 'success') {
                    isSuccess = false;
                    if (result_text === 'No system found to send invoice.') {
                      result_text = 'No accounts email configured for this customer.';
                    }

                    enqueueSnackbar(result_text, {
                      anchorOrigin: { vertical: 'top', horizontal: 'right' },
                      variant: 'error',
                      autoHideDuration: 3200,
                      action: CloseSnackbarAction
                    });

                    closeApprovedDialog();
                  }
                }
              });
          } else {
            isSuccess = false;
            enqueueSnackbar('No accounts email configured for this customer.', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'error',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });
          }

          if (isSuccess) {
            var message = 'Invoice is being processed and will be sent to the accounting system.';
            enqueueSnackbar(message, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: 'success',
              autoHideDuration: 3200,
              action: CloseSnackbarAction
            });

            var employee_id = await getEmployee();
            await directus.updateItem('invoices', data.id, {
              invoice_status: invoice_status,
              previous_invoice_status: invoicesRef.current.invoice_status,
              date_matched: moment().format('YYYY-MM-DD'),
              sent_to_accounting: true,
              approved_by: employee_id.id,
              approved_on: moment().format('YYYY-MM-DD')
            });
          }
        } else {
          await directus.updateItem('invoices', data.id, {
            invoice_status: invoice_status,
            previous_invoice_status: invoicesRef.current.invoice_status,
            date_matched: moment().format('YYYY-MM-DD')
          });
        }

        if (isSuccess) {
          setOrderData([]);
          setQuantityMatchedOrder(null);
          await loadInvoices(null);
        }
        setLoadingProcessWithoutPO(false);
      } else {
        let message = 'Please select ';
        if (!supplierFlag && !customerFlag) {
          message += 'customer and supplier';
        } else if (!supplierFlag) {
          message += 'supplier';
        } else if (!customerFlag) {
          message += 'customer';
        }
        message += ' first.';
        enqueueSnackbar(message, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setLoadingProcessWithoutPO(false);
    }
  };

  const navigatePrevLocation = prevLocation => {
    if (prevLocation) {
      if (prevLocation.includes('/invoice/')) {
        prevLocation = '/invoices';
        navigate('/invoices');
      } else {
        navigate(prevLocation);
      }
    }

    EventEmitter.emit('checkShowHideFilter', { value: true });
    EventEmitter.emit('reload_item', { path: prevLocation });
  };

  const getInvoiceStatusName = data => {
    var invoice_status_name = '';
    if (data.invoice_status === 'matched') {
      invoice_status_name = 'Awaiting Approval';
    } else if (data.invoice_status === 'awaiting_approval_level_2') {
      invoice_status_name = 'Awaiting Approval Level 2';
    } else if (data.invoice_status === 'approved') {
      invoice_status_name = 'Complete';
    } else {
      invoice_status_name = data.invoice_status;
    }
    return invoice_status_name;
  };

  const getBackgroundColor = order_status => {
    var status;
    if (order_status === 'draft') {
      status = { backgroundColor: '#D4E3F2' };
    } else if (order_status === 'sent') {
      status = { backgroundColor: '#FFF0DD' };
    } else if (order_status === 'cancelled') {
      status = { backgroundColor: '#FFDBD9' };
    } else if (order_status === 'completed') {
      status = { backgroundColor: '#DAF0DC' };
    } else {
      status = { backgroundColor: '#FFFFFF' };
    }

    return status;
  };

  const getOrderDataTotalOrder = order_value => {
    var totalOrder = 0;
    if (order_value.order_items) {
      order_value.order_items.forEach(item => {
        if (item.order_item) {
          totalOrder = totalOrder + item.order_item.unit_price * item.order_item.quantity;
        }
      });
    }

    return totalOrder;
  };

  const getInvoiceTotalAmount = data => {
    var totalOrder = 0;
    if (data.orders) {
      if (data.orders.length > 0) {
        data.orders.forEach(order_data => {
          if (order_data.orders_id) {
            if (order_data.orders_id.order_items) {
              order_data.orders_id.order_items.forEach(item => {
                if (item.order_item) {
                  totalOrder = totalOrder + item.order_item.unit_price * item.order_item.quantity;
                }
              });
            }
          }
        });
      }
    }
    return totalOrder;
  };

  const getInvoiceDueDate = () => {
    var due_date = null;
    if (invoicesRef.current.raw_due_date) {
      due_date = invoicesRef.current.raw_due_date ? moment(invoicesRef.current.raw_due_date).format() : null;
    }
    return due_date;
  };

  const resetInvoice = data => {
    getInvoicesRaw(data);
    setInvoices(data);
    if (data.supplier) {
      setSelectedSupplier({ id: data.supplier.id, name: data.supplier.name });
    } else {
      setSelectedSupplier(null);
    }

    if (data.farm_code) {
      setSelectedCustomer({
        id: data.farm_code.id,
        name: data.farm_code.name,
        bypass_approval: data.farm_code.bypass_approval
      });
    } else {
      setSelectedCustomer(null);
    }
  };

  const getCategoryDescription = order => {
    var collection_name = order.category_type.collection;
    var collection_data = collectionDataRef.current[collection_name];
    var category_description = '';
    if (collection_data) {
      if (collection_data.length > 0) {
        let obj = collection_data.find(o => o.id == order.category_description);
        category_description = obj ? obj.name : '';
      }
    }
    return category_description;
  };

  const getCategoryDescription2 = order => {
    var collection_name = order.category_type.collection;
    var collection_data = collectionDataOrderRef.current[collection_name];
    var category_description = '';
    if (collection_data) {
      if (collection_data.length > 0) {
        let obj = collection_data.find(o => o.id == order.category_description);
        category_description = obj ? obj.name : '';
      }
    }
    return category_description;
  };

  // const disabledCheck = orderData === null;

  const handleChangeInvoice = async (files, invoice_id) => {
    var show_files = false;
    if (files) {
      if (files.length > 0) {
        show_files = true;
      }
    }
    if (!show_files) {
      setMatchedInvoiceFile(null);
      setSelectedMatchedInvoice(null);
    } else {
      var invoice_files = [];
      const promises = files.map(async file => {
        const requestOptions = {
          method: 'HEAD'
        };
        var data_found = false;

        try {
          await fetch(`${react_app_url}${file.directus_files_id.data.asset_url}`, requestOptions).then(res => {
            if (res.status === 200) {
              data_found = true;
            }
          });
        } catch (error) {
          data_found = false;
        }
        file.data_found = data_found;

        invoice_files.push(file);
      });

      await Promise.all(promises);
      setMatchedInvoiceFile(invoice_files);
      setSelectedMatchedInvoice(invoice_id);
    }
  };

  const getMatchedInvoiceData = isNewOrder => {
    var matched_data = null;
    if (isNewOrder) {
      matched_data = matchedInvoiceDataNewRef.current;
    } else {
      var selected_order_id = null;
      if (selectedOrderDataRef.current) {
        if (selectedOrderDataRef.current.orders_id) {
          if (selectedOrderDataRef.current.orders_id.id) {
            selected_order_id = selectedOrderDataRef.current.orders_id.id;
          }
        }
      }

      matchedInvoiceDataRef.current.forEach(value => {
        if (value.order_id === selected_order_id) {
          matched_data = value.order_data;
        }
      });
    }

    if (matched_data) {
      if (matched_data.length > 0) {
        return (
          <Grid container spacing={0} justifyContent="space-around" style={{ paddingTop: 'inherit', background: 'white', borderRadius: '4px' }}>
            <Grid item xs={12} style={{ marginTop: '8px' }}>
              <Typography style={{ padding: '10px 16px 10px 16px', fontSize: '16px' }}>
                <b>Other Matched Invoices</b>
              </Typography>
            </Grid>
            <Grid
              container
              style={{
                background: '#daf0dc',
                padding: '12.58px 16px 6px 16px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
              }}
            >
              <Grid item xs={3}>
                <Typography style={{ fontSize: '14px' }}>
                  <b>Invoice Date</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: '14px' }}>
                  <b>Invoice Number</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: '14px' }}>
                  <b>Invoice Amount</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontSize: '14px' }}>
                  <b>Date Matched</b>
                </Typography>
              </Grid>
            </Grid>
            {matched_data.map((value, index) => (
              <Grid
                container
                key={index}
                style={{
                  background: '#daf0dc',
                  padding: index === matched_data.length - 1 ? '4px 16px 14px 16px' : '4px 16px 4px 16px',
                  borderBottomLeftRadius: index === matched_data.length - 1 ? '8px' : '0px',
                  borderBottomRightRadius: index === matched_data.length - 1 ? '8px' : '0px'
                }}
              >
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '12px', lineHeight: '36px' }}>
                    {value.date ? moment(value.date).format('DD/MM/YYYY') : null}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '12px', lineHeight: '36px' }}>{value.number}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '12px', lineHeight: '36px' }}>{value.invoice_amount}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{ fontSize: '12px', lineHeight: '36px' }}>
                    {value.date_matched ? moment(value.date_matched).format('DD/MM/YYYY') : null}
                  </Typography>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'end' }}>
                  <IconButton size="medium" className={classes.iconbuttonarea2} onClick={() => navigateToOtherInvoice(value.id)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid container style={{ marginBottom: '10px' }}></Grid>
          </Grid>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getMatchedInvoiceTabs = () => {
    var matched_data = null;
    if (!selectedOrderDataRef.current) {
      matched_data = matchedInvoiceDataNewRef.current;
    } else {
      var selected_order_id = null;
      if (selectedOrderDataRef.current.orders_id) {
        if (selectedOrderDataRef.current.orders_id.id) {
          selected_order_id = selectedOrderDataRef.current.orders_id.id;
        }
      }

      if (matchedInvoiceDataRef.current) {
        matchedInvoiceDataRef.current.forEach(value => {
          if (value.order_id === selected_order_id) {
            matched_data = value.order_data;
          }
        });
      }
    }

    if (matched_data) {
      if (matched_data.length > 0) {
        return (
          <Grid container spacing={0} style={{ paddingTop: 'inherit', borderRadius: '4px', marginTop: '15px', flexWrap: 'wrap' }}>
            <div
              className={`${classes.invoiceTabs} ${!selectedMatchedInvoiceRef.current ? classes.selectedInvoiceTabs : ''}`}
              style={{ border: '1px solid #82CB43' }}
              onClick={() => handleChangeInvoice(null)}
            >
              {invoicesRef.current.number ? invoicesRef.current.number : ''}{' '}
              {invoicesRef.current.orders
                ? invoicesRef.current.orders.length > 0
                  ? invoicesRef.current.orders[0].orders_id
                    ? '- ' + invoicesRef.current.orders[0].orders_id.order_number
                    : ''
                  : ''
                : ''}
            </div>
            {matched_data.map((value, index) => (
              <div
                key={index}
                className={`${classes.invoiceTabs} ${value.id === selectedMatchedInvoiceRef.current ? classes.selectedInvoiceTabs : ''}`}
                onClick={() => handleChangeInvoice(value.files, value.id)}
              >
                {value.number ? value.number : ''} {value.order_number ? '- ' + value.order_number : ''}
              </div>
            ))}
          </Grid>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const handleChangeQuantityMatchedOrder = (e, index, default_value) => {
    if (isUpdateMatchedByLine) {
      var value = e.target.value ? e.target.value === "-" ? e.target.value : parseInt(e.target.value) : e.target.value;
      let new_quantity_matched = Object.assign({}, quantityMatchedOrderRef.current);


      if (value != "-" && value != "") {
        if (new_quantity_matched != null) {
          if (value > parseInt(default_value) && new_quantity_matched[index].isShowWarning != true) {
            var message = "Quantity matched is greater than " + default_value + ". Are you sure?";
            var isNotice = window.confirm(message)

            if (isNotice) {
              new_quantity_matched[index].quantity = value;
              new_quantity_matched[index].isShowWarning = true;
              setQuantityMatchedOrder(new_quantity_matched);
            }

          } else {
            new_quantity_matched[index].quantity = value;
            setQuantityMatchedOrder(new_quantity_matched);
          }
        }
      } else {
        new_quantity_matched[index].quantity = value;
        setQuantityMatchedOrder(new_quantity_matched);
      }

    }
  };

  const getOrderData = (order_value, index) => {
    return (
      <Grid item xs={12} key={`order-${index}-${order_value.id}`}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.orderhead} style={{ borderRadius: '0' }}>
            <Grid container spacing={0} justifyContent="space-around">
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Typography>
                  <b>Date</b>
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Typography>
                  <b>Order No.</b>
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography>
                  <b>Supplier</b>
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography>
                  <b>Customer</b>
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography style={{ paddingLeft: '5px' }}>
                  <b>Status</b>
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'inline-flex' }}>
                <Typography align="center">
                  <b style={{ paddingLeft: '103px' }}>Amount</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '6px', borderRadius: '0 0 8px 8px' }}>
            <Grid container spacing={0} justifyContent="space-around" style={{ padding: '6px 0px 6px 16px' }}>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Typography noWrap>
                  {moment(order_value.order_date).format('DD/MM/YYYY') !== null ? moment(order_value.order_date).format('DD/MM/YYYY') : ''}
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Typography noWrap>{order_value.order_number}</Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography noWrap>{order_value.supplier ? order_value.supplier.name : ''}</Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <div style={{ display: "flex" }}>
                  <Typography noWrap>{order_value.farm_code ? order_value.farm_code.name : ''}</Typography>
                  {order_value.farm_code ? order_value.farm_code.legal_name ?
                    <CustomTooltip title={<Typography variant="h6"> {order_value.farm_code.legal_name}</Typography>}>
                      <InfoIcon
                        fontSize="small"
                        style={{ cursor: 'pointer', height: '15px', marginRight: "15px", marginTop: "3px" }}
                      />
                    </CustomTooltip>
                    : '' : ''}
                </div>
                {order_value.farm_code ? order_value.farm_code.legal_name ?
                  <Typography style={{ marginTop: "15px" }}>
                    <b>Invoice To:</b> {order_value.farm_code.legal_name}
                  </Typography>
                  : '' : ''}
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div style={{ marginTop: '-7px' }}>
                  <Paper elevation={0} style={getBackgroundColor(order_value.status)} className={classes.statusarea}>
                    <Typography style={{ paddingLeft: '5px' }}>{order_value.status}</Typography>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={2} style={{ display: 'inline-flex' }}>
                <div style={{ marginTop: '-7px', width: '100%' }}>
                  <Paper elevation={0} style={getBackgroundColor(order_value.status)} className={classes.amountarea}>
                    <Paper elevation={0} className={classes.amountdetail}>
                      <Typography>
                        {isNaN(getOrderDataTotalOrder(order_value)) ? 0 : '$' + formatNumber(getOrderDataTotalOrder(order_value).toFixed(2))}
                      </Typography>
                    </Paper>
                  </Paper>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={0} justifyContent="space-between" style={{ backgroundColor: '#8AA9CA' }} className={classes3.categorydetail}>
              <Grid className={classes3.internalcategory} style={{ width: '16%' }}>
                <Typography>INTERNAL</Typography>
              </Grid>
              <Grid className={classes3.internalcategory} style={{ width: '12%' }}>
                <Typography>Category</Typography>
              </Grid>
              <Grid item style={{ width: '30%' }}>
                <Paper elevation={0} className={classes3.categorycolumn}>
                  <Typography id="order_category" style={{ paddingLeft: '6px' }}>
                    {order_value.category_type ? order_value.category_type.name : ''}
                  </Typography>
                </Paper>
              </Grid>
              <Grid className={classes3.internalcategory} style={{ width: '12%' }}>
                <Typography>Details</Typography>
              </Grid>
              <Grid item style={{ width: '30%' }}>
                <Paper elevation={0} className={classes3.categorycolumn}>
                  <Typography id="order_category_description" style={{ paddingLeft: '6px' }}>
                    {order_value.category_type
                      ? !order_value.category_type.collection
                        ? order_value.category_description
                        : getCategoryDescription2(order_value)
                      : ''}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={0} justifyContent="space-around" style={{ backgroundColor: '#D4E3F2' }} className={classes3.orderitemdetail}>
              <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '7%' : '8%' }}>
                <Typography className={classes3.orderitem_header_left} style={{ marginLeft: '18px' }}>
                  Qty
                </Typography>
              </Grid>
              <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '11%' : '12%' }}>
                <Typography className={classes3.orderitem_header_left} style={{ marginLeft: '12px' }}>
                  Supplier Code
                </Typography>
              </Grid>
              <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '28%' : '30%' }}>
                <Typography className={classes3.orderitem_header_left} style={{ marginLeft: '12px' }}>
                  Description
                </Typography>
              </Grid>
              <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '10%' : '12%' }}>
                <Typography className={classes3.orderitem_header_left} style={{ marginRight: '18px', textAlign: 'right' }}>
                  Unit Price
                </Typography>
              </Grid>
              <Grid item className={classes3.itemcategory_header}>
                <Typography className={classes3.orderitem_header_right}>Item Category</Typography>
              </Grid>
              <Grid item className={classes3.itemdetails_header}>
                <Typography className={classes3.orderitem_header_right}>Item Details</Typography>
              </Grid>
              {isUpdateMatchedByLine ? (
                <Grid item className={classes3.orderitem_header} style={{ width: '6%', marginTop: '6px', paddingTop: '0px' }}>
                  <Typography className={classes3.orderitem_header_left} style={{ textAlign: 'center', lineHeight: '0px' }}>
                    <CustomTooltip arrow title={<Typography variant="h6">Quantity Matched</Typography>}>
                      <PlaylistAddCheckIcon style={{ color: 'green', cursor: 'pointer' }} />
                    </CustomTooltip>
                  </Typography>
                </Grid>
              ) : (
                ''
              )}
              <Grid item className={classes3.itemclose}></Grid>
            </Grid>

            {order_value.order_items.map((item, index) => {
              return (
                <div key={index}>
                  <Grid
                    container
                    spacing={0}
                    justifyContent="space-between"
                    className={index + 1 !== order_value.order_items.length ? classes.itemarea : classes3.itemarealast}
                    style={{
                      backgroundColor: '#D4E3F2',
                      borderRadius: index === order_value.order_items.length - 1 ? '0px 0px 8px 8px' : '0px'
                    }}
                  >
                    <Grid item style={{ width: isUpdateMatchedByLine ? '7%' : '8%', paddingLeft: '6px' }}>
                      <Paper elevation={0} className={classes3.itemcolumn2}>
                        <Typography id={`order_item_quantity_${index}`} style={{ marginLeft: '2px' }}>
                          {item.order_item.quantity}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item style={{ width: isUpdateMatchedByLine ? '11%' : '12%' }}>
                      <Paper elevation={0} className={classes3.itemcolumn2}>
                        <Typography id={`order_item_supplier_code_${index}`} style={{ marginLeft: '2px' }}>
                          {item.order_item.supplier_code}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item style={{ width: isUpdateMatchedByLine ? '28%' : '30%' }}>
                      <Paper elevation={0} className={classes3.itemcolumn2}>
                        <Typography id={`order_item_description_${index}`} style={{ marginLeft: '2px' }}>
                          {item.order_item.description}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item style={{ width: isUpdateMatchedByLine ? '10%' : '12%' }}>
                      <Paper elevation={0} className={classes3.itemcolumn2}>
                        <Typography id={`order_item_unit_price_${index}`} style={{ textAlign: 'right', marginRight: '2px' }}>
                          {isNaN(item.order_item.unit_price) ? 0 : '$' + formatNumber(item.order_item.unit_price.toFixed(2))}
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item style={{ width: '17%' }}>
                      <div
                        className={classes3.itemcategory_content}
                        style={{
                          height: '100%',
                          borderBottomLeftRadius: index === order_value.order_items.length - 1 ? '6px' : '0px'
                        }}
                      >
                        <Paper elevation={0} className={classes3.itemcolumn2} style={{ marginRight: '0px' }}>
                          <Typography id={`order_item_category_type_${index}`} style={{ marginLeft: '2px' }}>
                            {item.order_item.category_type ? item.order_item.category_type.name : ''}
                          </Typography>
                        </Paper>
                      </div>
                    </Grid>
                    <Grid item className={classes3.itemdetails}>
                      <div
                        className={classes3.itemdetails_content}
                        style={{
                          height: '100%',
                          borderBottomLeftRadius: index === order_value.order_items.length - 1 ? '6px' : '0px'
                        }}
                      >
                        <Paper elevation={0} className={classes3.itemcolumn2} style={{ marginRight: '0px' }}>
                          <Typography id={`order_item_description_${index}`} style={{ marginLeft: '2px' }}>
                            {item.order_item.category_type
                              ? !item.order_item.category_type.collection
                                ? item.order_item.category_description
                                : getCategoryDescription2(item.order_item)
                              : ''}
                          </Typography>
                        </Paper>
                      </div>
                    </Grid>
                    {isUpdateMatchedByLine ? (
                      <Grid item style={{ width: isUpdateMatchedByLine ? 'calc(6% + 35px)' : '7%', paddingLeft: '6px' }}>
                        <Paper elevation={0} style={{ background: 'transparent', display: "flex", alignItems: "center" }}>
                          {/* <Typography id={`order_item_quantity_${index}`} style={{ marginLeft: "2px" }}>{item.order_item.quantity}</Typography> */}
                          <CssTextFieldQuantity
                            className={classes.textfield2}
                            style={{ minWidth: "45px", width: "calc(100% - 35px)" }}
                            variant="outlined"
                            fullWidth={true}
                            name="quantity_matched"
                            value={quantityMatchedOrderRef.current[index] ? quantityMatchedOrderRef.current[index].quantity : ''}
                            onChange={e => handleChangeQuantityMatchedOrder(e, index, quantityMatchedOrderDefaultRef.current[index] ? quantityMatchedOrderDefaultRef.current[index].quantity : 0)}
                            disabled={disableInvoice}
                          />
                          <div style={{ whiteSpace: "nowrap" }}>/ {quantityMatchedOrderDefaultRef.current[index] ? quantityMatchedOrderDefaultRef.current[index].quantity : 0}</div>
                        </Paper>
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item className={classes3.itemclose}></Grid>
                  </Grid>
                </div>
              );
            })}

            <Grid container spacing={1} justifyContent="space-between" style={{ paddingTop: '6px' }}>
              <Grid item xs={2} md={2} lg={2}>
                <div
                  style={{
                    display: 'flex',
                    border: '1px solid rgba(142,142,147,0.3)',
                    borderRadius: '10px',
                    height: '35px'
                  }}
                >
                  <div
                    style={{
                      marginRight: '10px',
                      fontFamily: 'SF Pro',
                      lineHeight: '35px',
                      marginLeft: '10.82px',
                      fontSize: '14px',
                      overflow: 'hidden'
                    }}
                  >
                    Delivery by: {order_value.due_date ? moment(new Date(order_value.due_date)).format('DD/MM/YYYY') : ''}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <TextareaAutosize
                  id="order_notes"
                  minRows={2}
                  maxRows={2}
                  variant="outlined"
                  name="note"
                  placeholder="Notes: "
                  value={getNotes(order_value)}
                  disabled
                  style={{
                    maxWidth: '100%',
                    minWidth: '100%',
                    minHeight: '35px',
                    borderRadius: '8px',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    fontFamily: 'SF Pro',
                    padding: '7px 14px',
                    height: '32px',
                    fontSize: '14px'
                  }}
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <TextareaAutosize
                  minRows={2}
                  maxRows={2}
                  variant="outlined"
                  name="delivery_instructions"
                  placeholder="Delivery Instructions: "
                  value={order_value.delivery_instructions ? order_value.delivery_instructions : ''}
                  disabled
                  style={{
                    maxWidth: '100%',
                    minWidth: '100%',
                    maxHeight: '54%',
                    minHeight: '35px',
                    borderRadius: '8px',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    fontFamily: 'SF Pro',
                    padding: '7px 14px',
                    height: '32px',
                    fontSize: '14px'
                  }}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                {order_value ? (
                  order_value.owner ? (
                    <div
                      style={{
                        textAlign: 'right',
                        fontSize: '14px',
                        marginRight: '5px',
                        marginTop: '5px',
                        marginBottom: '5px'
                      }}
                    >
                      <span style={{ fontWeight: '600' }}>Created by : </span> {order_value.owner.first_name} {order_value.owner.last_name}
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            {order_value ? (
              order_value.status === 'completed' ? (
                <Grid container spacing={0} justifyContent="space-between" style={{ backgroundColor: '#DAF0DC' }} className={classes.firstnote}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    Previous Note:
                    <p>{getPreviousNotes(order_value)}</p>
                  </Grid>
                </Grid>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            {matchedInvoiceDataNewRef.current ? (matchedInvoiceDataNewRef.current.length > 0 ? getMatchedInvoiceData(true) : null) : null}
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const navigateToOtherInvoice = id => {
    setInvoiceLoading(true);
    resetInvoiceData();
    setInvoiceCurrentPosition(null);
    navigate(`/invoice/${id}`, { state: { prevLocation: window.location.pathname } });
    loadInvoices(id);
  };

  const updateToAwaitingApprovalLevel2 = async (id, status) => {
    try {
      setMatchedLoading2(true);

      var employee_id = await getEmployee();
      await directus.updateItem('invoices', id, {
        invoice_status: status,
        previous_invoice_status: invoicesRef.current.invoice_status,
        approved_by: employee_id.id,
        approved_on: moment().format('YYYY-MM-DD')
      });

      var invoice_id = null;

      await navigateInvoiceId(1).then(async response => {
        invoice_id = response.invoice_id;
        var total_page = response.total_page;

        if (invoice_id) {
          resetInvoiceData();
          navigate(`/invoice/${invoice_id}`, {
            state: {
              id: invoiceCurrentPositionRef.current,
              position: invoiceCurrentPositionRef.current,
              prevLocation: window.location.pathname,
              saveFilterData: saveFilterData,
              saveSortData,
              totalPage: total_page,
              pageNumber: pageNumber,
              invoiceList: invoiceList
            }
          });
          await loadInvoices(invoice_id);
          setMatchedLoading2(false);
        } else {
          navigate('/invoices');
          EventEmitter.emit('reload_item', { value: '/invoices' });
          EventEmitter.emit('checkShowHideFilter', { value: true });
        }
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  // split invoice
  const handleOpenSplitInvoice = file => {
    setOpenSplitInvoice(true);
    setSelectedInvoiceFile(file);
  };

  const handleCloseSplitCopyInvoice = () => {
    setOpenSplitInvoice(false);
    setSelectedInvoiceFile(null);
  };

  const splitInvoice = async () => {
    try {
      if (selectedInvoiceFileRef.current) {
        setLoadingSplitCopyInvoice(true);
        var result = await directus.createItem('invoices', {
          status: 'published',
          date: invoicesRef.current.date,
          invoice_status: 'unmatched',
          previous_invoice_status: invoicesRef.current.invoice_status
        });

        await directus.updateItem('invoices_directus_files', selectedInvoiceFileRef.current.id, {
          invoices_id: result.data.id
        });

        setTimeout(() => {
          resetInvoiceData();
          setInvoiceCurrentPosition(null);
          cancelMatchInvoice();
          setSelectedInvoiceFile(null);
          navigate(`/invoice/${result.data.id}`);
          loadInvoices(result.data.id);
          setOpenSplitInvoice(false);
          setLoadingSplitCopyInvoice(false);
        }, 2000);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const undoMatchInvoice = async selected_invoice => {
    try {
      setLoadingUnmatchedPO(true);

      if (selected_invoice) {
        if (isUpdateMatchedByLine) {
          var deleted_invoices_order_items = [];

          if (selected_invoice && invoicesOrderItemsDataRef.current.length > 0) {
            if (selected_invoice.orders_id) {
              if (selected_invoice.orders_id.order_items.length > 0) {
                var order_items = selected_invoice.orders_id.order_items;
                order_items.forEach(value => {
                  if (value.order_item) {
                    var result = Enumerable.from(invoicesOrderItemsDataRef.current)
                      .where(w => w.order_item_id === value.order_item.id)
                      .toArray();

                    if (result.length > 0) {
                      deleted_invoices_order_items.push(...result);
                    }
                  }
                });
              }
            }
          }

          if (deleted_invoices_order_items.length > 0) {
            var new_result = Enumerable.from(deleted_invoices_order_items)
              .select(data => data.id)
              .toArray();

            await directus.deleteItems('invoices_order_items', new_result);
          }
        }
        await directus.deleteItem('invoices_orders', selected_invoice.id);
        if (orderDataListRef.current.length === 1) {
          setOrderDataList([]);
        }
        loadInvoices();
        onChangeOrderTabs(null);
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setLoadingUnmatchedPO(false);
  };

  const getOrderDataList = () => {
    var list = [];

    orderDataListRef.current.forEach((value, index) => {
      if (value.orders_id) {
        list.push(
          <div
            key={index}
            style={{ marginLeft: '5px', marginRight: '5px' }}
            className={`${classes.orderTabs} ${selectedOrderDataRef.current ? (value.orders_id.id === selectedOrderDataRef.current.orders_id.id ? classes.selectedOrderTabs : '') : ''
              }`}
            onClick={() => {
              onChangeOrderTabs(value);
            }}
          >
            {value.orders_id ? value.orders_id.order_number : ''}
          </div>
        );
      }
    });

    return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{list}</div>;
  };

  const onChangeOrderTabs = value => {
    setSelectedOrderData(value);
    setOrderData([]);
    if (isUpdateMatchedByLine) {
      setQuantityMatchedOrder(null);
    }
    setOpen(false);
    setMatchedInvoiceDataNew(null);
    setMatchedInvoiceFile(null);
    setSelectedMatchedInvoice(null);
  };

  const addNewMatch = () => {
    setMatchedInvoiceFile(null);
    setSelectedOrderData(null);
  };

  const getFileIFrame = (index, file) => {
    var iframe_height = window.innerHeight - 200;

    if (iframe_height < 500) {
      iframe_height = 500;
    }

    var extension = file.directus_files_id.data.full_url.split('.').pop();
    if (extension === "pdf") {
      return (
        <iframe
          title={`invoice_file_${index}`}
          id={`invoice_file_${index}`}
          key={index}
          style={{
            marginTop: '10px',
            width: '100%',
            height: iframe_height,
            borderRadius: '8px',
            marginBottom: '20px',
          }}
          src={file.directus_files_id.data.full_url}
        />
      );
    } else {
      return (
        <iframe
          srcDoc="<div>This file type can't be previewed.</div>"
          title={`invoice_file_${index}`}
          key={index}
          style={{
            marginTop: '10px',
            width: '100%',
            height: iframe_height,
            borderRadius: '8px',
            marginBottom: '20px'
          }}
        />
      )
    }
  };

  const getFileNotFoundIFrame = index => {
    var iframe_height = window.innerHeight - 200;

    if (iframe_height < 500) {
      iframe_height = 500;
    }
    return (
      <iframe
        srcDoc="<div>File not found</div>"
        title={`invoice_file_${index}`}
        key={index}
        style={{
          marginTop: '10px',
          width: '100%',
          height: iframe_height,
          borderRadius: '8px',
          marginBottom: '20px'
        }}
      />
    );
  };

  const stopPropagation = e => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const saveSupplier = e => {
    if (e.key === 'Enter') {
      if (suppliersRef.current && suppliersRef2.current) {
        if (suppliersRef.current.length > 0) {
          if (suppliersRef.current.length != suppliersRef2.current.length || suppliersRef2.current.length === 1) {
            setSelectedSupplier(suppliersRef.current[0]);
            setAnchorElSupplier(null);
            setSuppliers(suppliersRef2.current);
          }
        }
      }
    }
  };

  const saveCustomer = e => {
    if (e.key === 'Enter') {
      if (customersRef.current && customersRef2.current) {
        if (customersRef.current.length > 0) {
          if (customersRef.current.length != customersRef2.current.length || customersRef2.current.length === 1) {
            setSelectedCustomer(customersRef.current[0]);
            setAnchorElCustomer(null);
            setCustomers(customersRef2.current);
          }
        }
      }
    }
  };

  const checkKeyDown = (e, type) => {
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
      if (type === 1) {
        filterRef.current.focus();
      } else if (type === 2) {
        filter2Ref.current.focus();
      }
    }
  };

  const moveFocusToInput = (e, type) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      if (type === 1) {
        filterRef.current.focus();
      } else if (type === 2) {
        filter2Ref.current.focus();
      }
    }
  };

  //--------------------supplier--------------------
  const clickSupplierFavourite = data => {
    setIsClickFavourite(true);
    if (suppliersRef.current) {
      let suppliers_data = suppliersRef.current;
      const index = suppliers_data.map(o => o.id).indexOf(data.id);
      if (index >= 0) {
        var isFavourite = suppliers_data[index].isFavourite;
        suppliers_data[index].isFavourite = !isFavourite;
      }

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setSuppliers(suppliers_data);
    }
  };

  const searchSupplier = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(suppliersRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setSuppliers(result);
    } else {
      setSuppliers(suppliersRef2.current);
    }
  };

  const resetSupplier = async e => {
    setAnchorElSupplier(null);
    try {
      let suppliers_data2 = suppliersRef2.current;
      suppliers_data2 = Enumerable.from(suppliers_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setSuppliers(suppliers_data2);

      if (isClickFavouriteRef.current) {
        var fav_suppliers_data = Enumerable.from(suppliers_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_suppliers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_suppliers_po) {
            fav_suppliers = directus_employee.favourites_suppliers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_suppliers.length === 0 && fav_suppliers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_suppliers.length !== fav_suppliers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_suppliers_data.forEach(value => {
            if (fav_suppliers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_suppliers.forEach(value => {
              if (fav_suppliers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_suppliers_po = fav_suppliers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_suppliers_po: fav_suppliers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  //--------------------customer--------------------
  const clickCustomerFavourite = data => {
    setIsClickFavourite(true);
    if (customersRef.current) {
      let customers_data = customersRef.current;
      const index = customers_data.map(o => o.id).indexOf(data.id);
      if (index >= 0) {
        var isFavourite = customers_data[index].isFavourite;
        customers_data[index].isFavourite = !isFavourite;
      }

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCustomers(customers_data);
    }
  };

  const searchCustomer = e => {
    var value = e.target.value.toLowerCase();
    if (value) {
      var result = Enumerable.from(customersRef2.current)
        .where(
          w =>
            (w.name ? w.name.toLowerCase().includes(value) : ''.toLowerCase().includes(value)) ||
            (w.code ? w.code.toLowerCase().includes(value) : ''.toLowerCase().includes(value))
        )
        .toArray();

      setCustomers(result);
    } else {
      setCustomers(customersRef2.current);
    }
  };

  const resetCustomer = async e => {
    setAnchorElCustomer(null);
    try {
      let customers_data2 = customersRef2.current;
      customers_data2 = Enumerable.from(customers_data2)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setCustomers(customers_data2);

      if (isClickFavouriteRef.current) {
        var fav_customers_data = Enumerable.from(customers_data2)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_customers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_customers_po) {
            fav_customers = directus_employee.favourites_customers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_customers.length === 0 && fav_customers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_customers.length !== fav_customers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_customers_data.forEach(value => {
            if (fav_customers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_customers.forEach(value => {
              if (fav_customers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_customers_po = fav_customers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const resetCheckbox = () => {
    setIsProcessMatched(true);
    setIsComplete(false);
  };

  const getDisputeInvoiceButton = () => {
    if (rejectedLoading) {
      return (
        <div style={{ marginTop: '6px' }}>
          <CircularProgress size={25} />
        </div>
      );
    } else {
      return (
        <RejectButton
          style={{ width: '160px' }}
          variant="contained"
          className={classes.buttonreject}
          component="span"
          disabled={openRef.current || loadingProcessWithoutPO || matchedLoading2 || undoLoading || approvedLoading}
          onClick={() => openRejectedInvoiceDialog()}
        >
          <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
            DISPUTE INVOICE
          </Typography>
        </RejectButton>
      );
    }
  };

  const handleClickOrderFile = id => {
    window.open(id);
  };

  const getSelectedOrderFiles = selected_order_data => {
    const list = [];
    if (selected_order_data.files) {
      if (selected_order_data.files.length > 0) {
        selected_order_data.files.forEach((file, index) => {
          if (file.directus_file_id) {
            list.push(
              <Grid key={index} span="true" style={{ marginBottom: '5px' }}>
                <Chip
                  icon={<InsertDriveFileIcon />}
                  label={file.directus_file_id.filename_download}
                  onClick={() => handleClickOrderFile(file.directus_file_id.data.full_url)}
                  variant="outlined"
                  style={{ marginRight: '5px' }}
                />
              </Grid>
            );
          }
        });
      }
    }

    return <Grid container>{list}</Grid>;
  };

  const getQuantityMatched = order_item => {
    var quantity = order_item.quantity;
    if (order_item.id) {
      var result = Enumerable.from(invoicesOrderItemsDataRef.current)
        .where(w => w.order_item_id == order_item.id)
        .orderByDescending(o => o.created_on)
        .toArray();

      if (result.length > 0) {
        quantity = result[0].quantity;
      }
    }
    return quantity;
  };

  const handleSubmission = async (e, order) => {
    try {
      setLoadingFile(true);
      e.preventDefault();

      const form = document.querySelector('#my-form');
      const data = new FormData(form);

      let updateData = await directus.uploadFiles(data, onUploadProgress);

      await directus.createItem('invoices_attached_files', {
        status: 'published',
        invoices_id: invoicesRef.current.id,
        directus_files_id: updateData.data.id
      });

      loadInvoices();
      setLoadingFile(false);
      var message = 'File uploaded successfully';
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      setLoadingFile(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const onListKeyDown = (e, type) => {
    if (e.key === 'Enter') {
      if (type === 'supplier') {
        setAnchorElSupplier(supplierListRef.current);
      } else if (type === 'customer') {
        setAnchorElCustomer(customerListRef.current);
      }
    }
  };

  const resetInvoiceData = () => {
    setOrderDataList([]);
    setOrderData([]);
    setQuantityMatchedOrder(null);
    setOpen(false);
    setSelectedOrderData(null);
    setIsDuplicate(false);
    setMatchedInvoiceData(null);
    setInvoice2ApprovalLevels(false);
    setApproveFlag(false);
    setIsApproverLevel2(false);
  };

  const navigateInvoice = async step => {
    try {
      setLoadingNavigation(true);
      if (invoiceCurrentPositionRef.current >= 0) {
        var invoice_id = null;
        await navigateInvoiceId(step).then(async response => {
          invoice_id = response.invoice_id;
          var total_page = response.total_page;

          if (invoice_id) {
            setInvoiceLoading(true);
            resetInvoiceData();
            navigate(`/invoice/${invoice_id}`, {
              state: {
                id: invoiceCurrentPositionRef.current,
                position: invoiceCurrentPositionRef.current,
                prevLocation: window.location.pathname,
                saveFilterData: saveFilterData,
                saveSortData,
                totalPage: total_page,
                pageNumber: pageNumber,
                invoiceList: invoiceList
              }
            });
            await loadInvoices(invoice_id);
          } else {
            navigate('/invoices');
            EventEmitter.emit('reload_item', { value: '/invoices' });
            EventEmitter.emit('checkShowHideFilter', { value: true });
          }
        });
      }

      setLoadingNavigation(false);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setLoadingNavigation(false);
      setInvoiceLoading(false);
    }
  };

  const navigateInvoiceId = async step => {
    var invoice_id = null;
    var total_page = null;
    try {
      if (invoiceCurrentPositionRef.current >= 0 && invoiceCurrentPositionRef.current !== null) {

        var position = invoiceCurrentPositionRef.current;
        var select_invoice_with_key = null;

        if ((step === 1 && position < pageNumber - 1) || (step === -1 && position != 0)) {
          var selected_invoice_by_position = Enumerable.from(invoiceList)
            .where(w => w.key === position + step)
            .toArray();

          if (selected_invoice_by_position.length > 0) {
            select_invoice_with_key = selected_invoice_by_position[0].id;
          }
        }

        setInvoiceCurrentPosition(invoiceCurrentPositionRef.current + step);

        var selected_Invoices = [];
        var new_total_page = 0;

        if (select_invoice_with_key) {
          selected_Invoices = await directus.api.get('/custom/invoices', {
            fields: '*',
            meta: 'filter_count',
            filter: { "invoices.id": select_invoice_with_key },
            limit: 1,
            other_invoice: false
          });
          new_total_page = totalPage;

        } else {
          selected_Invoices = await directus.api.get('/custom/invoices', {
            fields: '*',
            meta: 'filter_count',
            filter: saveFilterData,
            limit: 1,
            page: position + step + 1,
            sort: saveSortData,
            other_invoice: false
          });

          new_total_page = selected_Invoices.meta.filter_count;

          totalPage = selected_Invoices.meta.filter_count;
        }


        if (selected_Invoices.data.length > 0) {
          invoice_id = selected_Invoices.data[0].id;
          total_page = new_total_page;
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }

    return {
      invoice_id: invoice_id,
      total_page: total_page
    };
  };

  const disableInvoice = !InvoiceAdminPermission();

  const getNotes = value => {
    var order_note = '';

    if (value) {
      if (value.status === 'completed') {
        if (value.additional_notes) {
          order_note = value.additional_notes;
        }
      } else {
        order_note = value.notes;
      }
    }
    return order_note;
  };

  const getPreviousNotes = value => {
    var order_note = '';

    if (value) {
      if (value.status === 'completed') {
        if (value.notes) {
          order_note = value.notes;
        }
      }
    }
    return order_note;
  };


  const handleOpenDeleteInvoiceDialog = () => {
    if (invoicesRef.current.orders.length > 0 || invoicesRef.current.invoice_status !== 'unmatched') {
      enqueueSnackbar("This invoice can only be deleted if the status is new and unmatched.", {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } else {
      setOpenDeleteInvoiceDialog(true);
    }
  };

  const handleCloseDeleteInvoiceDialog = () => {
    setOpenDeleteInvoiceDialog(false);
  };

  const delete_invoice = async () => {
    try {
      setLoadingDeleteDialog(true);
      await directus.deleteItem('invoices', invoicesRef.current.id);
      enqueueSnackbar('Invoice data has been deleted', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setTimeout(() => {
        navigate('/invoices');
        EventEmitter.emit('reload_item', { value: '/invoices' });
        EventEmitter.emit('checkShowHideFilter', { value: true });
      }, 1000);

    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setLoadingDeleteDialog(false);
    }
    setLoadingDeleteDialog(false);

  }

  return (
    <PageContent className={classes.root} title="Invoice Detail" style={{ paddingTop: '0px' }}>
      {invoiceLoading ? (
        <div>
          <CircularProgress className={classes.circular} style={{ marginLeft: '50%' }} />
        </div>
      ) : (
        <>
          <Container maxWidth={false} style={{ padding: '0px' }}>
            {invoicesRef.current ? (
              <div>
                <Paper
                  elevation={0}
                  className={classes.invoiceslisttrue}
                  style={invoiceAttRef.current.invoicestatus ? invoiceAttRef.current.invoicestatus : {}}
                >
                  <Grid container spacing={0} justifyContent="space-around" style={{ paddingTop: 'inherit', paddingBottom: '50px' }}>
                    <Grid item xs={12} style={{ margin: '5px 6px 5px 6px', display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <IconButton size="medium" className={classes.iconbuttonarea} onClick={() => navigatePrevLocation(prevLocation)}>
                          <ArrowBackIosIcon fontSize="small" />
                        </IconButton>
                        {invoicesRef.current.invoice_status === 'unmatched' ? (
                          <>
                            {!disableInvoice ? (
                              <>
                                {getDisputeInvoiceButton()}
                                {loadingProcessWithoutPO ? (
                                  <div style={{ marginTop: '6px' }}>
                                    <CircularProgress size={25} />
                                  </div>
                                ) : (
                                  <>
                                    {orderDataListRef.current.length > 0 ? (
                                      <MatchedPOButton
                                        name="process_without_po"
                                        variant="contained"
                                        component="span"
                                        disabled={openRef.current || rejectedLoading}
                                        onClick={() => onProcessMatchedPO(invoicesRef.current.id)}
                                      >
                                        <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                          Process Matched PO<span style={{ fontSize: '12px' }}>s</span>
                                        </Typography>
                                      </MatchedPOButton>
                                    ) : (
                                      <Button
                                        name="process_without_po"
                                        variant="contained"
                                        className={classes.buttonProcessWithoutPO}
                                        component="span"
                                        disabled={openRef.current || rejectedLoading}
                                        onClick={() => onProcessWithoutPO(invoicesRef.current)}
                                      >
                                        <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                          Process Without PO
                                        </Typography>
                                      </Button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ) : invoicesRef.current.order ? (
                          <CssTextField
                            className={classes2.match_order_number_disabled}
                            value={invoicesRef.current.order.order_number}
                            variant="outlined"
                            disabled
                            style={{ marginTop: '3px' }}
                          />
                        ) : null}

                        {invoicesRef.current.invoice_status !== 'unmatched' ? (
                          <div
                            style={{
                              display: 'inline-flex',
                              fontFamily: 'SF Pro',
                              marginLeft: '12px',
                              marginRight: '12px',
                              textTransform: 'Capitalize',
                              lineHeight: '36px'
                            }}
                          >
                            {getInvoiceStatusName(invoicesRef.current)}
                          </div>
                        ) : null}

                        {(approveFlagRef.current || InvoiceAdminPermission()) && invoicesRef.current && invoicesRef.current.invoice_status === 'approved' ? (
                          approvedLoading ? (
                            <div style={{ marginTop: '6px' }}>
                              <CircularProgress size={25} />
                            </div>
                          ) : (
                            <>
                              <UndoButton
                                style={{ width: '200px' }}
                                variant="contained"
                                component="span"
                                onClick={() => approvedInvoice(invoicesRef.current.id, invoicesRef.current.sent_to_accounting)}
                              >
                                <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                  {invoicesRef.current.sent_to_accounting ? 'Re-send to Accounts' : 'Send to Accounts'}
                                </Typography>
                              </UndoButton>

                              {/**
                               * Button to reset to un-approved status
                               */
                                AdminPermission() ? (
                                  <UndoButton style={{ width: '220px' }} variant="contained" component="span" onClick={() => setOpenUnapproved(true)}>
                                    <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                      Reset to Un-Approved
                                    </Typography>
                                  </UndoButton>
                                ) : (
                                  ''
                                )}

                              {invoicesRef.current.sent_to_accounting ? (
                                <MatchedPOButton
                                  style={{ width: '190px' }}
                                  variant="contained"
                                  component="span"
                                  onClick={() => exportInvoiceOrder(invoicesRef.current.id)}
                                >
                                  <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                    Export Invoice & PO
                                  </Typography>
                                </MatchedPOButton>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        ) : (
                          ''
                        )}

                        <MatchedPOButton
                          style={{ width: '190px' }}
                          variant="contained"
                          component="span"
                          onClick={() => handleClickExportActionLog(invoicesRef.current.id)}
                        >
                          <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                            Export Action Logs
                          </Typography>
                        </MatchedPOButton>

                        {InvoiceAdminPermission() &&
                          (invoicesRef.current.invoice_status === 'disputed' || invoicesRef.current.invoice_status === 'matched') ? (
                          undoLoading ? (
                            <div style={{ marginTop: '6px' }}>
                              <CircularProgress size={25} />
                            </div>
                          ) : (
                            <UndoButton
                              variant="contained"
                              component="span"
                              onClick={() => undoInvoices(invoicesRef.current.id)}
                              disabled={rejectedLoading || resolveLoading}
                            >
                              <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                Reset to New
                              </Typography>
                            </UndoButton>
                          )
                        ) : null}

                        {InvoiceAdminPermission() && invoicesRef.current.invoice_status === 'disputed' ? (
                          resolveLoading ? (
                            <div style={{ marginTop: '6px' }}>
                              <CircularProgress size={25} />
                            </div>
                          ) : (
                            <MatchButton
                              style={{ width: '160px' }}
                              variant="contained"
                              component="span"
                              onClick={() => resolveInvoices(invoicesRef.current.id)}
                              disabled={undoLoading || undoLoading || rejectedLoading}
                            >
                              <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                Resolve Dispute
                              </Typography>
                            </MatchButton>
                          )
                        ) : null}

                        {showAwaitingApprovalButtonRef.current ?
                          <ApprovedButton
                            className='invoice-approve-level-2'
                            style={{ width: '160px' }}
                            variant="contained"
                            component="span"
                            disabled={resolveLoading || rejectedLoading}
                            onClick={() => updateToAwaitingApprovalLevel2(invoicesRef.current.id, 'awaiting_approval_level_2')}
                          >
                            <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                              Approve Invoice
                            </Typography>
                          </ApprovedButton>
                          :
                          showApproveButtonRef.current ?
                            <ApprovedButton
                              className='invoice-approve'
                              style={{ width: '160px' }}
                              variant="contained"
                              component="span"
                              disabled={resolveLoading || undoLoading || rejectedLoading || approvedLoading}
                              onClick={() => openApprovedDialog(invoicesRef.current.id)}
                            >
                              <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                Approve Invoice
                              </Typography>
                            </ApprovedButton>
                            : ''
                        }

                        {!enableApprovalWarningRef.current && approvedLoading ? (
                          <div style={{ marginTop: '6px' }}>
                            <CircularProgress size={25} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div style={{ display: 'flex' }}>
                        {approveFlagRef.current && invoicesRef.current.invoice_status === 'matched' ? getDisputeInvoiceButton() : null}

                        {(InvoiceAdminPermission() || isApproverLevel2Ref.current) &&
                          approveFlagRef.current &&
                          invoicesRef.current.invoice_status === 'awaiting_approval_level_2'
                          ? getDisputeInvoiceButton()
                          : null}

                        {loadingNavigationRef.current ? (
                          <div style={{ marginTop: '6px', marginRight: '15px' }}>
                            <CircularProgress size={25} />
                          </div>
                        ) : invoiceCurrentPositionRef.current !== null ? (
                          <div>
                            {invoiceCurrentPositionRef.current !== 0 ? (
                              <ArrowButton variant="contained" component="span" onClick={() => navigateInvoice(-1)}>
                                <ArrowBackIosNewIcon />
                              </ArrowButton>
                            ) : (
                              ''
                            )}
                            {invoiceCurrentPositionRef.current < totalPage - 1 ? (
                              <ArrowButton variant="contained" component="span" onClick={() => navigateInvoice(1)}>
                                <ArrowForwardIosIcon />
                              </ArrowButton>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Grid>

                    {invoicesRef.current && invoicesRef.current.invoice_status === 'disputed' ? (
                      invoicesRef.current.dispute_notes ? (
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: '10px',
                            padding: '6px 6px 6px 20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderRadius: '8px',
                            backgroundColor: '#FFDBD9'
                          }}
                        >
                          <Typography noWrap style={{ fontWeight: '400', lineHeight: '24px', fontSize: '16px' }}>
                            <b>Disputed Reason:</b> {invoicesRef.current.dispute_notes}
                          </Typography>
                        </Grid>
                      ) : ''
                    ) : ''}

                    <Grid item xs={12}>
                      <ColsWrapper
                        separatorProps={{
                          style: {
                            minHeight: '100px',
                            backgroundColor: '#ccc8c8',
                            width: '15px',
                            border: '6px solid #F2F2F6'
                          },
                          className: 'cols-seperator'
                        }}
                      >
                        <Col className="left-container" initialWidth={initialWidth.left} style={{ minWidth: '40%', maxWidth: 'calc(100% - 500px)' }}>
                          {/* <Grid item xs={8} style={{ marginBottom: "80px" }}> */}
                          <Grid item xs={12} style={{ paddingRight: '15px' }}>
                            {invoicesRef.current.invoice_status === 'unmatched' ||
                              (invoicesRef.current.invoice_status !== 'unmatched' && selectedOrderDataRef.current) ? (
                              <Grid item xs={12} style={{ marginTop: '10px' }}>
                                <Paper elevation={0} className={classes.orderhead} style={{ borderRadius: '8px 8px 0 0' }}>
                                  <Grid item xs={12} style={{ padding: '7px 0 13px 0', display: 'flex' }}>
                                    <div style={{ lineHeight: '43px', marginRight: '10px' }}>
                                      <b>PO Info</b>
                                    </div>
                                    {orderDataListRef.current ? getOrderDataList() : ''}
                                    {orderDataListRef.current.length !== 0 && invoicesRef.current.invoice_status === 'unmatched' ? (
                                      <div style={{ lineHeight: '38px', marginLeft: '5px' }}>
                                        <IconButton id="add_order_menu" className={classes.iconAddNewMatch} onClick={() => addNewMatch()}>
                                          <AddIcon fontSize="small" />
                                        </IconButton>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </Grid>

                                  {invoicesRef.current.invoice_status === 'unmatched' ? (
                                    <Grid item xs={12}>
                                      {!selectedOrderDataRef.current ? (
                                        <>
                                          <Autocomplete
                                            id="combo-box-demo"
                                            size="small"
                                            disableClearable
                                            className={classes.autocomplete}
                                            style={{ height: '36px', display: 'inline-block', marginTop: '8px' }}
                                            options={orders ? orders : []}
                                            getOptionLabel={option => String(option.order_number)}
                                            onChange={(event, newValue) => {
                                              changeOrders(newValue);
                                            }}
                                            onInputChange={(e, newInputValue) => searchOrders(newInputValue)}
                                            value={orderDataRef.current.length > 0 ? orderDataRef.current[0] : null}
                                            disabled={disableInvoice}
                                            renderInput={params => (
                                              <CssTextField
                                                {...params}
                                                className={classes2.match_order_number}
                                                variant="outlined"
                                                label="Match Order Number"
                                              />
                                            )}
                                            renderOption={(props, option) => (
                                              <Box component="div" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} style={{ width: '100%' }}>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    alignItems: 'center'
                                                  }}
                                                >
                                                  <span>{props.order_number}</span>
                                                  <span style={{ width: '80px', textAlign: 'right', fontSize: '12px' }}>
                                                    {props.invoices
                                                      ? props.invoices.length
                                                        ? props.invoices.length > 0
                                                          ? `${props.invoices.length} matched`
                                                          : ''
                                                        : ''
                                                      : ''}{' '}
                                                  </span>
                                                </div>
                                              </Box>
                                            )}
                                          />

                                          {openRef.current === true ? (
                                            <div>
                                              <p
                                                style={{
                                                  display: 'inline-flex',
                                                  marginLeft: '6px',
                                                  marginRight: '6px',
                                                  lineHeight: '35px',
                                                  whiteSpace: 'nowrap'
                                                }}
                                              >
                                                Please verify order details below before matching invoice{' '}
                                              </p>

                                              {matchedLoading || loadingProcessWithoutPO ? (
                                                <span style={{ marginTop: '6px' }}>
                                                  <CircularProgress size={25} />
                                                </span>
                                              ) : (
                                                <>
                                                  <MatchButton
                                                    name="file"
                                                    variant="contained"
                                                    className={classes.buttonmatch}
                                                    component="span"
                                                    onClick={() => matchInvoices(invoicesRef.current.id, orderDataRef.current[0])}
                                                  >
                                                    <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                                      MATCH INVOICE
                                                    </Typography>
                                                  </MatchButton>

                                                  {/* {orderDataRef.current.length > 0 ? orderDataRef.current[0].status !== "completed" ?
                                            <MatchButton
                                              name="file"
                                              variant="contained"
                                              className={classes.buttonmatch}
                                              style={{ width: "295px" }}
                                              component="span"
                                              onClick={() => matchInvoices(invoicesRef.current.id, "complete_order", orderDataRef.current[0])}
                                            >
                                              <Typography noWrap style={{ fontWeight: "700", lineHeight: "24px" }}>Complete Order & Match Invoice</Typography>
                                            </MatchButton>
                                            : '' : ''} */}

                                                  <FormControlLabel
                                                    style={{ marginLeft: '5px' }}
                                                    label="Complete PO Matching"
                                                    control={
                                                      <Checkbox
                                                        checked={isProcessMatchedRef.current}
                                                        icon={<CheckBoxOutlineBlankRoundedIcon style={{ color: '#8ace50' }} />}
                                                        checkedIcon={<CheckBoxRoundedIcon style={{ color: '#82cb43' }} />}
                                                        onChange={() => setIsProcessMatched(!isProcessMatchedRef.current)}
                                                      />
                                                    }
                                                  />

                                                  {orderDataRef.current.length > 0 ? (
                                                    orderDataRef.current[0].status !== 'completed' ? (
                                                      <FormControlLabel
                                                        label="Complete Order"
                                                        control={
                                                          <Checkbox
                                                            checked={isCompleteRef.current}
                                                            icon={<CheckBoxOutlineBlankRoundedIcon style={{ color: '#8ace50' }} />}
                                                            checkedIcon={<CheckBoxRoundedIcon style={{ color: '#82cb43' }} />}
                                                            onChange={() => setIsComplete(!isCompleteRef.current)}
                                                          />
                                                        }
                                                      />
                                                    ) : (
                                                      ''
                                                    )
                                                  ) : (
                                                    ''
                                                  )}
                                                  <CancelMatchButton
                                                    name="file"
                                                    variant="contained"
                                                    className={classes.buttonmatch}
                                                    component="span"
                                                    onClick={() => cancelMatchInvoice()}
                                                  >
                                                    <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                                      Cancel
                                                    </Typography>
                                                  </CancelMatchButton>
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                </Paper>
                              </Grid>
                            ) : (
                              ''
                            )}

                            {orderDataRef.current.length > 0 ? (
                              orderDataRef.current.map((order_value, index) => getOrderData(order_value, index))
                            ) : selectedOrderDataRef.current ? (
                              <Grid item xs={12}>
                                <Grid item xs={12}>
                                  <Paper elevation={0} className={classes.orderhead} style={{ borderRadius: '0' }}>
                                    <Grid container spacing={0} justifyContent="space-around">
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Typography>
                                          <b>Date</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Typography>
                                          <b>Order No.</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Typography>
                                          <b>Supplier</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Typography>
                                          <b>Customer</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Typography style={{ paddingLeft: '5px' }}>
                                          <b>Status</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ display: 'inline-flex' }}>
                                        <Typography align="center" style={{ marginLeft: 'auto', marginRight: '64px' }}>
                                          <b>Amount</b>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Grid>

                                <Grid item xs={12}>
                                  <Paper elevation={0} style={{ padding: '6px', borderRadius: '0 0 8px 8px' }}>
                                    <Grid
                                      container
                                      spacing={0}
                                      justifyContent="space-around"
                                      // style={{ marginLeft: '16px' }}
                                      className={classes3.orderlist}
                                    >
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Typography noWrap>
                                          {moment(selectedOrderDataRef.current.orders_id.order_date).format('DD/MM/YYYY')}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Typography noWrap>{selectedOrderDataRef.current.orders_id.order_number}</Typography>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Typography noWrap>
                                          {selectedOrderDataRef.current.orders_id.supplier
                                            ? selectedOrderDataRef.current.orders_id.supplier.name
                                            : ''}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <div style={{ display: "flex" }}>
                                          <Typography noWrap>
                                            {selectedOrderDataRef.current.orders_id.farm_code
                                              ? selectedOrderDataRef.current.orders_id.farm_code.name
                                              : ''}

                                          </Typography>

                                          {selectedOrderDataRef.current.orders_id.farm_code ? selectedOrderDataRef.current.orders_id.farm_code.legal_name ?
                                            <CustomTooltip title={<Typography variant="h6"> {selectedOrderDataRef.current.orders_id.farm_code.legal_name}</Typography>}>
                                              <InfoIcon
                                                fontSize="small"
                                                style={{ cursor: 'pointer', height: '15px', marginRight: "15px", marginTop: "3px" }}
                                              />
                                            </CustomTooltip>
                                            : '' : ''}
                                        </div>
                                        {selectedOrderDataRef.current.orders_id.farm_code ? selectedOrderDataRef.current.orders_id.farm_code.legal_name ?
                                          <Typography style={{ marginTop: "15px" }}>
                                            <b>Invoice To:</b> {selectedOrderDataRef.current.orders_id.farm_code.legal_name}
                                          </Typography>
                                          : '' : ''}
                                      </Grid>
                                      <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <div style={{ marginTop: '-7px' }}>
                                          <Paper
                                            elevation={0}
                                            style={getBackgroundColor(selectedOrderDataRef.current.orders_id.status)}
                                            className={classes.statusarea}
                                          >
                                            <Typography style={{ paddingLeft: '5px' }}>{selectedOrderDataRef.current.orders_id.status}</Typography>
                                          </Paper>
                                        </div>
                                      </Grid>
                                      <Grid item xs={2} style={{ display: 'inline-flex' }}>
                                        <div style={{ marginTop: '-7px', width: '100%' }}>
                                          <Paper
                                            elevation={0}
                                            style={getBackgroundColor(selectedOrderDataRef.current.orders_id.status)}
                                            className={classes.amountarea}
                                          >
                                            <Paper elevation={0} className={classes.amountdetail}>
                                              <Typography>
                                                {isNaN(selectedOrderDataRef.current.orders_id.total_order)
                                                  ? 0
                                                  : '$' + formatNumber(selectedOrderDataRef.current.orders_id.total_order.toFixed(2))}
                                              </Typography>
                                            </Paper>
                                          </Paper>
                                        </div>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      container
                                      spacing={0}
                                      justifyContent="space-between"
                                      style={{ backgroundColor: '#8AA9CA' }}
                                      className={classes3.categorydetail}
                                    >
                                      <Grid className={classes3.internalcategory} style={{ width: '16%' }}>
                                        <Typography>INTERNAL</Typography>
                                      </Grid>
                                      <Grid className={classes3.internalcategory} style={{ width: '12%' }}>
                                        <Typography>Category</Typography>
                                      </Grid>
                                      <Grid item style={{ width: '30%' }}>
                                        <Paper elevation={0} className={classes3.categorycolumn}>
                                          <Typography id="order_category" style={{ paddingLeft: '6px' }}>
                                            {selectedOrderDataRef.current.orders_id
                                              ? selectedOrderDataRef.current.orders_id.category_type
                                                ? selectedOrderDataRef.current.orders_id.category_type.name
                                                : ''
                                              : ''}
                                          </Typography>
                                        </Paper>
                                      </Grid>
                                      <Grid className={classes3.internalcategory} style={{ width: '12%' }}>
                                        <Typography>Details</Typography>
                                      </Grid>
                                      <Grid item style={{ width: '30%' }}>
                                        <Paper elevation={0} className={classes3.categorycolumn}>
                                          <Typography id="order_category_description" style={{ paddingLeft: '6px' }}>
                                            {selectedOrderDataRef.current.orders_id
                                              ? selectedOrderDataRef.current.orders_id.category_type
                                                ? !selectedOrderDataRef.current.orders_id.category_type.collection
                                                  ? selectedOrderDataRef.current.orders_id.category_description
                                                  : getCategoryDescription(selectedOrderDataRef.current.orders_id)
                                                : ''
                                              : ''}
                                          </Typography>
                                        </Paper>
                                      </Grid>
                                    </Grid>

                                    {selectedOrderDataRef.current.orders_id.order_items !== null ? (
                                      selectedOrderDataRef.current.orders_id.order_items.length > 0 ? (
                                        <Grid
                                          container
                                          spacing={0}
                                          justifyContent="space-around"
                                          style={{ backgroundColor: '#D4E3F2' }}
                                          className={classes3.orderitemdetail}
                                        >
                                          <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '7%' : '8%' }}>
                                            <Typography className={classes3.orderitem_header_left} style={{ marginLeft: '18px' }}>
                                              Qty
                                            </Typography>
                                          </Grid>
                                          <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '11%' : '12%' }}>
                                            <Typography className={classes3.orderitem_header_left} style={{ marginLeft: '12px' }}>
                                              Supplier Code
                                            </Typography>
                                          </Grid>
                                          <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '28%' : '30%' }}>
                                            <Typography className={classes3.orderitem_header_left} style={{ marginLeft: '12px' }}>
                                              Description
                                            </Typography>
                                          </Grid>
                                          <Grid item className={classes3.orderitem_header} style={{ width: isUpdateMatchedByLine ? '10%' : '12%' }}>
                                            <Typography
                                              className={classes3.orderitem_header_left}
                                              style={{ marginRight: '18px', textAlign: 'right' }}
                                            >
                                              Unit Price
                                            </Typography>
                                          </Grid>
                                          <Grid item className={classes3.itemcategory_header}>
                                            <Typography className={classes3.orderitem_header_right}>Item Category</Typography>
                                          </Grid>
                                          <Grid item className={classes3.itemdetails_header}>
                                            <Typography className={classes3.orderitem_header_right}>Item Details</Typography>
                                          </Grid>
                                          {isUpdateMatchedByLine ? (
                                            <Grid
                                              item
                                              className={classes3.orderitem_header}
                                              style={{ width: '6%', marginTop: '6px', paddingTop: '0px' }}
                                            >
                                              <Typography
                                                className={classes3.orderitem_header_left}
                                                style={{ textAlign: 'center', lineHeight: '0px' }}
                                              >
                                                <CustomTooltip arrow title={<Typography variant="h6">Quantity Matched</Typography>}>
                                                  <PlaylistAddCheckIcon style={{ color: 'green', cursor: 'pointer' }} />
                                                </CustomTooltip>
                                              </Typography>
                                            </Grid>
                                          ) : (
                                            ''
                                          )}
                                          <Grid item className={classes3.itemclose}></Grid>
                                        </Grid>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {selectedOrderDataRef.current.orders_id.order_items !== null
                                      ? selectedOrderDataRef.current.orders_id.order_items.map((item, index) => {
                                        if (item.order_item) {
                                          return (
                                            <div key={index}>
                                              <Grid
                                                container
                                                spacing={0}
                                                justifyContent="space-between"
                                                className={
                                                  index + 1 !== selectedOrderDataRef.current.orders_id.order_items.length
                                                    ? classes.itemarea
                                                    : classes3.itemarealast
                                                }
                                                style={{
                                                  backgroundColor: '#D4E3F2',
                                                  borderRadius:
                                                    index === selectedOrderDataRef.current.orders_id.order_items.length - 1
                                                      ? '0px 0px 8px 8px'
                                                      : '0px'
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  style={{
                                                    width: isUpdateMatchedByLine ? '7%' : '8%',
                                                    paddingLeft: '6px'
                                                  }}
                                                >
                                                  <Paper elevation={0} className={classes3.itemcolumn2}>
                                                    <Typography id={`order_item_quantity_${index}`} style={{ marginLeft: '2px' }}>
                                                      {item.order_item.quantity}
                                                    </Typography>
                                                  </Paper>
                                                </Grid>
                                                <Grid item style={{ width: isUpdateMatchedByLine ? '11%' : '12%' }}>
                                                  <Paper elevation={0} className={classes3.itemcolumn2}>
                                                    <Typography id={`order_item_supplier_code_${index}`} style={{ marginLeft: '2px' }}>
                                                      {item.order_item.supplier_code}
                                                    </Typography>
                                                  </Paper>
                                                </Grid>
                                                <Grid item style={{ width: isUpdateMatchedByLine ? '28%' : '30%' }}>
                                                  <Paper elevation={0} className={classes3.itemcolumn2}>
                                                    <Typography id={`order_item_description_${index}`} style={{ marginLeft: '2px' }}>
                                                      {item.order_item.description}
                                                    </Typography>
                                                  </Paper>
                                                </Grid>
                                                <Grid item style={{ width: isUpdateMatchedByLine ? '10%' : '12%' }}>
                                                  <Paper elevation={0} className={classes3.itemcolumn2}>
                                                    <Typography
                                                      id={`order_item_unit_price_${index}`}
                                                      style={{ textAlign: 'right', marginRight: '2px' }}
                                                    >
                                                      {isNaN(item.order_item.unit_price)
                                                        ? 0
                                                        : '$' + formatNumber(item.order_item.unit_price.toFixed(2))}
                                                    </Typography>
                                                  </Paper>
                                                </Grid>

                                                <Grid item style={{ width: '17%' }}>
                                                  <div
                                                    className={classes3.itemcategory_content}
                                                    style={{
                                                      height: '100%',
                                                      borderBottomLeftRadius:
                                                        index === selectedOrderDataRef.current.orders_id.order_items.length - 1 ? '6px' : '0px'
                                                    }}
                                                  >
                                                    <Paper elevation={0} className={classes3.itemcolumn2} style={{ marginRight: '0px' }}>
                                                      <Typography id={`order_item_category_type_${index}`} style={{ marginLeft: '2px' }}>
                                                        {item.order_item.category_type ? item.order_item.category_type.name : ''}
                                                      </Typography>
                                                    </Paper>
                                                  </div>
                                                </Grid>
                                                <Grid item className={classes3.itemdetails}>
                                                  <div
                                                    className={classes3.itemdetails_content}
                                                    style={{
                                                      height: '100%',
                                                      borderBottomRightRadius:
                                                        index === selectedOrderDataRef.current.orders_id.order_items.length - 1 ? '6px' : '0px'
                                                    }}
                                                  >
                                                    <Paper elevation={0} className={classes3.itemcolumn2} style={{ marginRight: '0px' }}>
                                                      <Typography id={`order_item_description_${index}`} style={{ marginLeft: '2px' }}>
                                                        {item.order_item.category_type
                                                          ? !item.order_item.category_type.collection
                                                            ? item.order_item.category_description
                                                            : getCategoryDescription(item.order_item)
                                                          : ''}
                                                      </Typography>
                                                    </Paper>
                                                  </div>
                                                </Grid>
                                                {isUpdateMatchedByLine ? (
                                                  <Grid item style={{ width: '6%', paddingLeft: '6px' }}>
                                                    <Paper elevation={0} className={classes3.itemcolumn2}>
                                                      <Typography id={`order_item_quantity_${index}`} style={{ marginLeft: '2px' }}>
                                                        {getQuantityMatched(item.order_item)}
                                                      </Typography>
                                                    </Paper>
                                                  </Grid>
                                                ) : (
                                                  ''
                                                )}
                                                <Grid item className={classes3.itemclose}></Grid>
                                              </Grid>
                                            </div>
                                          );
                                        }

                                        return null;
                                      })
                                      : ''}
                                    <Grid container spacing={1} justifyContent="space-between" style={{ paddingTop: '6px' }}>
                                      <Grid item xs={2} md={2} lg={2}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            border: '1px solid rgba(142,142,147,0.3)',
                                            borderRadius: '10px',
                                            height: '35px'
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: '10px',
                                              fontFamily: 'SF Pro',
                                              lineHeight: '35px',
                                              marginLeft: '10.82px',
                                              fontSize: '14px',
                                              overflow: 'hidden'
                                            }}
                                          >
                                            Delivery by:{' '}
                                            {selectedOrderDataRef.current.orders_id
                                              ? selectedOrderDataRef.current.orders_id.due_date
                                                ? moment(new Date(selectedOrderDataRef.current.orders_id.due_date)).format('DD/MM/YYYY')
                                                : ''
                                              : ''}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={3} md={3} lg={3}>
                                        <TextareaAutosize
                                          id="order_notes"
                                          minRows={2}
                                          maxRows={2}
                                          variant="outlined"
                                          name="note"
                                          placeholder="Notes: "
                                          value={getNotes(selectedOrderDataRef.current.orders_id)}
                                          disabled
                                          style={{
                                            maxWidth: '100%',
                                            minWidth: '100%',
                                            minHeight: '35px',
                                            borderRadius: '8px',
                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                            fontFamily: 'SF Pro',
                                            padding: '7px 14px',
                                            height: '32px',
                                            fontSize: '14px'
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={3} md={3} lg={3}>
                                        <TextareaAutosize
                                          minRows={2}
                                          maxRows={2}
                                          variant="outlined"
                                          name="delivery_instructions"
                                          placeholder="Delivery Instructions: "
                                          value={
                                            selectedOrderDataRef.current.delivery_instructions
                                              ? selectedOrderDataRef.current.delivery_instructions
                                              : ''
                                          }
                                          disabled
                                          style={{
                                            maxWidth: '100%',
                                            minWidth: '100%',
                                            maxHeight: '54%',
                                            minHeight: '35px',
                                            borderRadius: '8px',
                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                            fontFamily: 'SF Pro',
                                            padding: '7px 14px',
                                            height: '32px',
                                            fontSize: '14px'
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4} lg={4}>
                                        {selectedOrderDataRef.current ? (
                                          selectedOrderDataRef.current.orders_id ? (
                                            selectedOrderDataRef.current.orders_id.owner ? (
                                              <div
                                                style={{
                                                  textAlign: 'right',
                                                  fontSize: '14px',
                                                  marginRight: '5px',
                                                  marginTop: '5px',
                                                  marginBottom: '5px'
                                                }}
                                              >
                                                <span style={{ fontWeight: '600' }}>Created by : </span>{' '}
                                                {selectedOrderDataRef.current.orders_id.owner.first_name}{' '}
                                                {selectedOrderDataRef.current.orders_id.owner.last_name}
                                              </div>
                                            ) : (
                                              ''
                                            )
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                      {selectedOrderDataRef.current.orders_id ? (
                                        selectedOrderDataRef.current.orders_id.status === 'completed' ? (
                                          <Grid
                                            container
                                            spacing={0}
                                            justifyContent="space-between"
                                            style={{ backgroundColor: '#DAF0DC' }}
                                            className={classes.firstnote}
                                          >
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                              Previous Note:
                                              <p>{getPreviousNotes(selectedOrderDataRef.current.orders_id)}</p>
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </Grid>

                                    <Grid container spacing={0} justifyContent="space-around" className={classes3.orderitemdetail}>
                                      <Grid item style={{ width: '100%', marginTop: '10px' }}>
                                        {getSelectedOrderFiles(selectedOrderDataRef.current.orders_id)}
                                      </Grid>
                                    </Grid>

                                    {matchedInvoiceDataRef.current
                                      ? matchedInvoiceDataRef.current.length > 0
                                        ? getMatchedInvoiceData()
                                        : null
                                      : null}

                                    {invoicesRef.current.invoice_status === 'unmatched' ? (
                                      <div style={{ textAlign: 'right', marginTop: '10px', marginBottom: '8px' }}>
                                        <UndoButton
                                          variant="contained"
                                          component="span"
                                          disabled={loadingUnmatchedPO}
                                          onClick={() => undoMatchInvoice(selectedOrderDataRef.current)}
                                        >
                                          <Typography noWrap style={{ fontWeight: '700', lineHeight: '24px' }}>
                                            UNDO MATCH
                                          </Typography>
                                        </UndoButton>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </Paper>
                                </Grid>
                              </Grid>
                            ) : (
                              ''
                            )}

                            <Grid
                              container
                              spacing={0}
                              justifyContent="space-around"
                              style={{
                                paddingTop: 'inherit',
                                background: 'white',
                                borderRadius: '8px',
                                marginTop: '10px',
                                marginBottom: '8px'
                              }}
                            >
                              <Grid item xs={12} style={{ padding: '15px 6px 6px 22px' }}>
                                <b>Invoice Info</b>
                              </Grid>
                              <Grid
                                container
                                style={{
                                  padding: '12.58px 0px 6px 16px',
                                  borderTopLeftRadius: '8px',
                                  borderTopRightRadius: '8px',
                                  marginLeft: '6px',
                                  marginRight: '6px'
                                }}
                              >
                                <Grid style={{ width: '10%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Date</Typography>
                                </Grid>
                                <Grid style={{ width: '22%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Supplier</Typography>
                                </Grid>
                                <Grid style={{ width: '22%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Customer</Typography>
                                </Grid>
                                <Grid style={{ width: '12%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Order Number</Typography>
                                </Grid>
                                <Grid style={{ width: '12%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Invoice Number</Typography>
                                </Grid>
                                <Grid style={{ width: '10%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Due Date</Typography>
                                </Grid>
                                <Grid style={{ width: '12%' }}>
                                  <Typography className={classes.matched_invoice_info_header}>Amount</Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                style={{
                                  background: '#D4E3F2',
                                  padding: '14px 2px 14px 16px',
                                  borderRadius: '8px',
                                  marginLeft: '6px',
                                  marginRight: '6px'
                                }}
                              >
                                <Grid style={{ width: '10%' }}>
                                  <div style={{ marginRight: '10px' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <KeyboardDatePicker
                                        className={classes2.date_picker}
                                        inputVariant="outlined"
                                        id="date_picker"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/MM/yyyy"
                                        onError={e =>
                                          e && checkInvoiceDateRef.current.date === false
                                            ? setCheckInvoiceDate({ ...checkInvoiceDateRef.current, date: true })
                                            : ''
                                        }
                                        value={invoicesRef.current.date ? moment(invoicesRef.current.date).format() : null}
                                        onChange={date => handleChangeEditInvoice(date, 'date')}
                                        disabled={disableInvoice}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </Grid>
                                <Grid style={{ width: '22%' }}>
                                  <div>
                                    <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                                      <ListItem
                                        className={classes.selectDate}
                                        style={{ padding: '0 4px 0 0' }}
                                        aria-haspopup="listbox"
                                        aria-controls="lock-menu"
                                        aria-expanded={open2 ? 'true' : undefined}
                                      >
                                        <div
                                          tabIndex={0}
                                          ref={supplierListRef}
                                          className={classes.textInput}
                                          onClick={e => setAnchorElSupplier(supplierListRef.current)}
                                          onKeyDown={e => onListKeyDown(e, 'supplier')}
                                        >
                                          <div
                                            className={classes.textInputValue}
                                            style={{
                                              color: selectedSupplierRef.current
                                                ? selectedSupplierRef.current.name
                                                  ? '#263238'
                                                  : '#bdc1c3'
                                                : '#bdc1c3'
                                            }}
                                            onClick={e => setAnchorElSupplier(supplierListRef.current)}
                                          >
                                            {selectedSupplierRef.current ? selectedSupplierRef.current.name ?? 'Select Supplier' : 'Select Supplier'}
                                          </div>

                                          {selectedSupplierRef.current ? (
                                            selectedSupplierRef.current.name ? (
                                              <div id="close_multiselect" style={{ height: '100%' }} onClick={() => setSelectedSupplier(null)}>
                                                <CloseRoundedIcon className={classes.closeIcon} />
                                              </div>
                                            ) : (
                                              ''
                                            )
                                          ) : (
                                            ''
                                          )}

                                          <div style={{ height: '100%' }}>
                                            <ArrowDropDownIcon className={classes.arrowDropDown} />
                                          </div>
                                        </div>
                                      </ListItem>
                                    </List>
                                    <Menu
                                      id="lock-menu"
                                      anchorEl={anchorElSupplier}
                                      open={open2}
                                      onClose={() => {
                                        resetSupplier();
                                      }}
                                      MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                        style: { paddingTop: '0px' }
                                      }}
                                      PaperProps={{
                                        style: {
                                          maxWidth: '350px',
                                          width: '350px',
                                          maxHeight: '300px'
                                        }
                                      }}
                                      getContentAnchorEl={null}
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                      onKeyDown={e => checkKeyDown(e, 1)}
                                    >
                                      {suppliersRef.current ? (
                                        <div
                                          style={{
                                            position: 'sticky',
                                            top: '0',
                                            zIndex: '9999',
                                            background: 'white',
                                            height: '40px'
                                          }}
                                          onKeyDown={e => moveFocusToInput(e, 1)}
                                        >
                                          <TextField
                                            autoFocus
                                            inputRef={filterRef}
                                            onKeyDown={e => {
                                              stopPropagation(e);
                                              saveSupplier(e);
                                            }}
                                            style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                                            onChange={e => searchSupplier(e)}
                                          />
                                        </div>
                                      ) : null}

                                      {suppliersRef.current
                                        ? suppliersRef.current.map((option, index) => (
                                          <MenuItem
                                            key={index}
                                            style={{
                                              height: 'auto',
                                              minHeight: '30px',
                                              paddingLeft: '10px',
                                              paddingRight: '10px',
                                              fontSize: '12px',
                                              width: '100%',
                                              borderTop:
                                                index !== 0
                                                  ? suppliersRef.current[index].isFavourite === false &&
                                                    suppliersRef.current[index - 1].isFavourite === true
                                                    ? '1px solid black'
                                                    : '0px solid black'
                                                  : '0px solid black'
                                            }}
                                            selected={selectedSupplierRef.current ? selectedSupplierRef.current.id === option.id : false}
                                            onClick={e => {
                                              setSelectedSupplier(option);
                                              setAnchorElSupplier(null);
                                              setSuppliers(suppliersRef2.current);
                                            }}
                                          >
                                            <div
                                              style={{
                                                whiteSpace: 'break-spaces',
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-between'
                                              }}
                                            >
                                              <div
                                                style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                                                onClick={e => {
                                                  setSelectedSupplier(option);
                                                  setAnchorElSupplier(null);
                                                  setSuppliers(suppliersRef2.current);
                                                }}
                                              >
                                                {option.name}
                                              </div>
                                              <div
                                                style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }}
                                                onClick={e => clickSupplierFavourite(option)}
                                              >
                                                {option.isFavourite ? (
                                                  <StarRoundedIcon fontSize="small" />
                                                ) : (
                                                  <StarBorderRoundedIcon fontSize="small" />
                                                )}
                                              </div>
                                            </div>
                                          </MenuItem>
                                        ))
                                        : null}
                                    </Menu>
                                  </div>
                                  {/* <CssTextField2
                            className={classes.textfield2}
                            variant="outlined"
                            fullWidth={true}
                            name="raw_supplier"
                            value={invoicesRawRef.current.raw_supplier ? invoicesRawRef.current.raw_supplier : ''}
                            onChange={(e) => handleChangeEditInvoice(e, "", true)}
                            disabled={disableInvoice}
                          /> */}
                                </Grid>
                                <Grid style={{ width: '22%' }}>
                                  <div>
                                    <List style={{ padding: '0px' }} component="nav" sx={{ bgcolor: 'background.paper' }}>
                                      <ListItem
                                        className={classes.selectDate}
                                        style={{ padding: '0 4px 0 0' }}
                                        aria-haspopup="listbox"
                                        aria-controls="lock-menu"
                                        aria-expanded={open2 ? 'true' : undefined}
                                      >
                                        <div
                                          tabIndex={0}
                                          ref={customerListRef}
                                          className={classes.textInput}
                                          onClick={e => setAnchorElCustomer(customerListRef.current)}
                                          onKeyDown={e => onListKeyDown(e, 'customer')}
                                        >
                                          <div
                                            className={classes.textInputValue}
                                            style={{
                                              color: selectedCustomerRef.current
                                                ? selectedCustomerRef.current.name
                                                  ? '#263238'
                                                  : '#bdc1c3'
                                                : '#bdc1c3'
                                            }}
                                          >
                                            {selectedCustomerRef.current ? selectedCustomerRef.current.name ?? 'Select Customer' : 'Select Customer'}
                                          </div>

                                          {selectedCustomerRef.current ? (
                                            selectedCustomerRef.current.name ? (
                                              <div id="close_multiselect" style={{ height: '100%' }} onClick={() => setSelectedCustomer(null)}>
                                                <CloseRoundedIcon className={classes.closeIcon} />
                                              </div>
                                            ) : (
                                              ''
                                            )
                                          ) : (
                                            ''
                                          )}

                                          <div style={{ height: '100%' }}>
                                            <ArrowDropDownIcon className={classes.arrowDropDown} />
                                          </div>
                                        </div>
                                      </ListItem>
                                    </List>
                                    <Menu
                                      id="lock-menu"
                                      anchorEl={anchorElCustomer}
                                      open={open3}
                                      onClose={() => {
                                        resetCustomer();
                                      }}
                                      MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                        style: { paddingTop: '0px' }
                                      }}
                                      PaperProps={{
                                        style: {
                                          maxWidth: '350px',
                                          width: '350px',
                                          maxHeight: '300px'
                                        }
                                      }}
                                      getContentAnchorEl={null}
                                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                      onKeyDown={e => checkKeyDown(e, 2)}
                                    >
                                      {customersRef.current ? (
                                        <div
                                          style={{
                                            position: 'sticky',
                                            top: '0',
                                            zIndex: '9999',
                                            background: 'white',
                                            height: '40px'
                                          }}
                                          onKeyDown={e => moveFocusToInput(e, 2)}
                                        >
                                          <TextField
                                            autoFocus
                                            inputRef={filter2Ref}
                                            onKeyDown={e => {
                                              stopPropagation(e);
                                              saveCustomer(e);
                                            }}
                                            style={{ width: '100%', padding: '5px 10px 0', height: '45px' }}
                                            onChange={e => searchCustomer(e)}
                                          />
                                        </div>
                                      ) : null}

                                      {customersRef.current
                                        ? customersRef.current.map((option, index) => (
                                          <MenuItem
                                            key={index}
                                            style={{
                                              height: 'auto',
                                              minHeight: '30px',
                                              paddingLeft: '10px',
                                              paddingRight: '10px',
                                              fontSize: '12px',
                                              width: '100%',
                                              borderTop:
                                                index !== 0
                                                  ? customersRef.current[index].isFavourite === false &&
                                                    customersRef.current[index - 1].isFavourite === true
                                                    ? '1px solid black'
                                                    : '0px solid black'
                                                  : '0px solid black'
                                            }}
                                            selected={selectedCustomerRef.current ? selectedCustomerRef.current.id === option.id : false}
                                            onClick={e => {
                                              setSelectedCustomer(option);
                                              setAnchorElCustomer(null);
                                              setCustomers(customersRef2.current);
                                              reloadOrders();
                                            }}
                                          >
                                            <div
                                              style={{
                                                whiteSpace: 'break-spaces',
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-between'
                                              }}
                                            >
                                              <div
                                                style={{ width: 'calc(100% - 25px)', fontSize: '14px' }}
                                                onClick={e => {
                                                  setSelectedCustomer(option);
                                                  setAnchorElCustomer(null);
                                                  setCustomers(customersRef2.current);
                                                }}
                                              >
                                                {option.name}
                                              </div>
                                              <div
                                                style={{ width: '25px', alignSelf: 'center', textAlign: 'right' }}
                                                onClick={e => clickCustomerFavourite(option)}
                                              >
                                                {option.isFavourite ? (
                                                  <StarRoundedIcon fontSize="small" />
                                                ) : (
                                                  <StarBorderRoundedIcon fontSize="small" />
                                                )}
                                              </div>
                                            </div>
                                          </MenuItem>
                                        ))
                                        : null}
                                    </Menu>
                                  </div>
                                  {/* <CssTextField2
                            className={classes.textfield2}
                            variant="outlined"
                            fullWidth={true}
                            name="raw_customer"
                            value={invoicesRawRef.current.raw_customer ? invoicesRawRef.current.raw_customer : ''}
                            onChange={(e) => handleChangeEditInvoice(e, "", true)}
                            disabled={disableInvoice}
                          /> */}
                                </Grid>
                                <Grid style={{ width: '12%' }}>
                                  <CssTextField2
                                    className={classes.textfield2}
                                    variant="outlined"
                                    fullWidth={true}
                                    name="raw_po_number"
                                    value={invoicesRawRef.current.raw_po_number ? invoicesRawRef.current.raw_po_number : ''}
                                    onChange={e => handleChangeEditInvoice(e, '', true)}
                                    disabled={disableInvoice}
                                  />
                                </Grid>
                                <Grid style={{ width: '12%' }}>
                                  <CssTextField2
                                    className={isDuplicateRef.current ? classes.textfieldDuplicate : classes.textfield2}
                                    variant="outlined"
                                    fullWidth={true}
                                    name="number"
                                    value={invoicesRef.current.number ? invoicesRef.current.number : ''}
                                    onChange={e => handleChangeEditInvoice(e)}
                                    disabled={disableInvoice}
                                  />
                                </Grid>
                                <Grid style={{ width: '10%' }}>
                                  <div style={{ marginRight: '10px' }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <KeyboardDatePicker
                                        className={classes2.date_picker}
                                        inputVariant="outlined"
                                        id="date_picker"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/MM/yyyy"
                                        onError={e =>
                                          e && checkInvoiceDateRef.current.raw_due_date === false
                                            ? setCheckInvoiceDate({
                                              ...checkInvoiceDateRef.current,
                                              raw_due_date: true
                                            })
                                            : ''
                                        }
                                        value={getInvoiceDueDate()}
                                        onChange={date => handleChangeEditInvoice(date, 'raw_due_date')}
                                        disabled={disableInvoice}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </Grid>
                                <Grid style={{ width: '12%' }}>
                                  <CssTextField2
                                    className={classes.textfield2}
                                    variant="outlined"
                                    name="total_amount"
                                    value={invoicesRawRef.current.total_amount ? parseFloat(invoicesRawRef.current.total_amount).toFixed(2) : ''}
                                    onChange={e => handleChangeEditInvoice(e, '', true)}
                                    InputProps={{
                                      inputComponent: NumberFormatCustom
                                    }}
                                    disabled={disableInvoice}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', height: '40px' }}>
                                  <div style={{ display: 'flex', padding: '0 0 0 22px', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: '14px' }}>
                                      <b>Raw Invoice</b>
                                    </Typography>

                                    <CustomTooltip title={<Typography variant="h6">Show Raw Invoice</Typography>}>
                                      <InfoIcon
                                        onClick={() => showMatchInfo(invoicesRef.current)}
                                        fontSize="small"
                                        style={{ cursor: 'pointer', height: '15px' }}
                                      />
                                    </CustomTooltip>
                                  </div>
                                  <div>
                                    {loadingUpdateInvoice ? (
                                      <CircularProgress
                                        className={classes.circular}
                                        style={{ width: '30px', height: '30px', float: 'right', marginRight: '15px' }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          paddingRight: '10px',
                                          textAlign: 'right',
                                          marginTop: '4px',
                                          display: 'flex'
                                        }}
                                      >
                                        {!disableInvoice ? (
                                          invoicesRef.current.invoice_status != 'approved' ? (
                                            loadingFile ? (
                                              <div style={{ marginRight: '6px' }}>
                                                <CircularProgress style={{ color: '#5bfa17' }} size={30} />
                                              </div>
                                            ) : (
                                              <>
                                                <form id="my-form" style={{ display: 'inline' }}>
                                                  <input
                                                    type="file"
                                                    name="file"
                                                    accept=".pdf, .img, .jpg, .jpeg, .png, .xlsx, .xls, .docx, .doc"
                                                    onChange={e => handleSubmission(e)}
                                                    id="contained-button-file"
                                                    style={{ display: 'none' }}
                                                  />
                                                  <label htmlFor="contained-button-file">
                                                    <CancelButton
                                                      name="file"
                                                      variant="contained"
                                                      style={{ marginRight: '6px', width: '120px' }}
                                                      disabled={loadingOCR}
                                                      component="span"
                                                    >
                                                      <Typography noWrap style={{ fontSize: '12px', fontWeight: '700' }}>
                                                        Attach File
                                                      </Typography>
                                                    </CancelButton>
                                                  </label>
                                                </form>
                                              </>
                                            )
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}

                                        {!disableInvoice ? (
                                          invoicesRef.current.invoice_status === 'unmatched' ? (
                                            loadingOCR ? (
                                              <div style={{ marginRight: '6px' }}>
                                                <CircularProgress style={{ color: '#5bfa17' }} size={30} />
                                              </div>
                                            ) : (
                                              <>
                                                <CancelButton
                                                  name="file"
                                                  variant="contained"
                                                  style={{ marginRight: '6px', width: '145px' }}
                                                  component="span"
                                                  disabled={loadingFile}
                                                  onClick={() => onReprocessOCR(invoicesRef.current)}
                                                >
                                                  <Typography noWrap style={{ fontSize: '12px', fontWeight: '700' }}>
                                                    Reprocess OCR
                                                  </Typography>
                                                </CancelButton>
                                              </>
                                            )
                                          ) : (
                                            ''
                                          )
                                        ) : (
                                          ''
                                        )}
                                        {!disableInvoice ? (
                                          <>
                                            <CancelButton
                                              style={{ width: '80px' }}
                                              size="small"
                                              variant="contained"
                                              disabled={loadingOCR || loadingFile}
                                              onClick={() => {
                                                resetInvoice(invoicesTempRef.current);
                                              }}
                                            >
                                              <Typography variant="h6" style={{ fontSize: '12px', fontWeight: '700' }}>
                                                Reset
                                              </Typography>
                                            </CancelButton>
                                            <SubmitButton
                                              style={{ marginLeft: '5px', width: '80px' }}
                                              size="small"
                                              variant="contained"
                                              disabled={
                                                checkInvoiceDateRef.current.date ||
                                                checkInvoiceDateRef.current.raw_due_date ||
                                                loadingOCR ||
                                                loadingFile
                                              }
                                              onClick={() => updateInvoice()}
                                            >
                                              <Typography variant="h6" style={{ fontSize: '12px', fontWeight: '700' }}>
                                                Save
                                              </Typography>
                                            </SubmitButton>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Grid>

                              <Grid item xs={12} style={{ marginTop: '10px', paddingLeft: '6px', paddingRight: '6px' }}>
                                <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '6px' }}>
                                  <Grid item xs={12}>
                                    <TextareaAutosize
                                      minRows={2}
                                      variant="outlined"
                                      name="notes"
                                      placeholder="Notes"
                                      onChange={e => handleChangeEditInvoice(e)}
                                      // disabled={invoicesRef.current.invoice_status === "approved" || invoicesRef.current.invoice_status === "disputed" || invoicesRef.current.invoice_status === "matched" ? true : false}
                                      value={invoicesRef.current.notes ? invoicesRef.current.notes : ''}
                                      style={{
                                        maxWidth: '100%',
                                        minWidth: '100%',
                                        minHeight: '32px',
                                        borderRadius: '8px',
                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                        fontFamily: 'SF Pro',
                                        padding: '5.5px 14px',
                                        height: '32px'
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                {POApproverPermissionOnly() ?
                                  <Grid>
                                    <SubmitButton
                                      style={{ marginLeft: '5px', width: '120px', marginTop: '10px', marginBottom: '10px' }}
                                      size="small"
                                      variant="contained"
                                      disabled={
                                        checkInvoiceDateRef.current.date ||
                                        checkInvoiceDateRef.current.raw_due_date ||
                                        loadingOCR ||
                                        loadingFile ||
                                        loadingUpdateInvoice
                                      }
                                      onClick={() => saveNotes()}
                                    >
                                      {loadingUpdateInvoice ?
                                        <div><CircularProgress
                                          className={classes.circular}
                                          style={{ width: '15px', height: '15px', float: 'right', marginRight: '5px', color: 'white' }}
                                        /></div> : ''}
                                      <Typography variant="h6" style={{ fontSize: '12px', fontWeight: '700' }}>
                                        Save Notes
                                      </Typography>

                                    </SubmitButton>
                                  </Grid>
                                  : ''}

                                <Grid style={{ marginLeft: "10px", display: "flex", marginTop: "3px" }}>
                                  <div style={{ fontSize: "13px", marginTop: "7px", marginRight: "8px" }}><b>Invoice Files:</b></div>
                                  <Grid>
                                    {invoiceFileRef.current.map((file, index) => {
                                      if (file.directus_files_id) {
                                        if (file.data_found) {
                                          return (
                                            <Grid key={index} container spacing={0} justifyContent="space-between" style={{ display: 'inline' }}>
                                              <span key={index} style={{ whiteSpace: 'nowrap' }}>
                                                {InvoiceAdminPermission() && index > 0 ? (
                                                  <span
                                                    style={{ position: 'relative', marginRight: '20px', cursor: 'pointer' }}
                                                    onClick={e => handleOpenSplitInvoice(file)}
                                                  >
                                                    <CustomTooltip arrow title={<Typography variant="h6">Split Invoice</Typography>}>
                                                      <SplitscreenIcon className={classes.split_screen} />
                                                    </CustomTooltip>
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                                <Chip
                                                  style={{
                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                    color: 'rgba(0, 0, 0, 0.87)',
                                                    zIndex: '1'
                                                  }}
                                                  icon={<InsertDriveFileIcon />}
                                                  label={file.directus_files_id.filename_download}
                                                  onClick={() => handleClick(file.directus_files_id.data.full_url)}
                                                  variant="outlined"
                                                />
                                                {/* {!disableInvoice && invoicesRef.current.orders.length === 0 ? (
                                                <CancelIcon
                                                  style={{
                                                    fontSize: '15px',
                                                    height: '20px',
                                                    marginLeft: '-10px',
                                                    marginBottom: '3px',
                                                    marginRight: '5px',
                                                    cursor: 'pointer',
                                                    zIndex: '9999'
                                                  }}
                                                  onClick={() => openDeleteInvoiceFileDialog(file)}
                                                />
                                              ) : (
                                                ''
                                              )} */}
                                              </span>
                                            </Grid>
                                          );
                                        } else {
                                          return (
                                            <Grid key={index} container spacing={0} justifyContent="space-between" style={{ display: 'inline' }}>
                                              <span key={index} style={{ whiteSpace: 'nowrap' }}>
                                                {InvoiceAdminPermission() && index > 0 ? (
                                                  <span
                                                    style={{ position: 'relative', marginRight: '20px', cursor: 'pointer' }}
                                                    onClick={e => handleOpenSplitInvoice(file)}
                                                  >
                                                    <CustomTooltip arrow title={<Typography variant="h6">Split Invoice</Typography>}>
                                                      <SplitscreenIcon className={classes.split_screen} />
                                                    </CustomTooltip>
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                                <Chip
                                                  style={{
                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                    color: 'rgba(0, 0, 0, 0.87)',
                                                    zIndex: '1'
                                                  }}
                                                  icon={<InsertDriveFileIcon />}
                                                  label={file.directus_files_id.filename_download}
                                                  onClick={() => alert('File not found')}
                                                  variant="outlined"
                                                />
                                                {/* {!disableInvoice && invoicesRef.current.orders.length === 0 ? (
                                                <CancelIcon
                                                  style={{
                                                    fontSize: '15px',
                                                    height: '20px',
                                                    marginLeft: '-10px',
                                                    marginBottom: '3px',
                                                    marginRight: '5px',
                                                    cursor: 'pointer',
                                                    zIndex: '9999'
                                                  }}
                                                  onClick={() => openDeleteInvoiceFileDialog(file)}
                                                />
                                              ) : (
                                                ''
                                              )} */}
                                              </span>
                                            </Grid>
                                          );
                                        }
                                      } else {
                                        return null;
                                      }
                                    })}
                                  </Grid>
                                </Grid>
                                {invoiceAttachedFileRef.current.length > 0 ?
                                  <Grid style={{ marginLeft: "10px", marginTop: "10px", display: "flex" }}>
                                    <div style={{ fontSize: "13px", marginTop: "9px", marginRight: "5px" }}><b>Attachments:</b></div>
                                    <Grid>
                                      {invoiceAttachedFileRef.current.map((file, index) => {
                                        if (file.directus_files_id) {
                                          if (file.data_found) {
                                            return (
                                              <Grid key={index} container spacing={0} justifyContent="space-between" style={{ display: 'inline' }}>
                                                <span
                                                  key={index}
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                    position: 'relative',
                                                    zIndex: '0',
                                                    cursor: 'pointer'
                                                  }}
                                                >
                                                  <Chip
                                                    style={{
                                                      borderColor: 'rgb(80 139 202)',
                                                      color: 'rgb(80 139 202)',
                                                      zIndex: '1'
                                                    }}
                                                    icon={<InsertDriveFileIcon />}
                                                    label={file.directus_files_id.filename_download}
                                                    onClick={() => handleClick(file.directus_files_id.data.full_url)}
                                                    variant="outlined"
                                                  />
                                                  {!disableInvoice && invoicesRef.current.invoice_status != 'approved' ? (
                                                    <CancelIcon
                                                      style={{
                                                        fontSize: '15px',
                                                        height: '20px',
                                                        marginLeft: '-10px',
                                                        marginBottom: '3px',
                                                        marginRight: '5px',
                                                        cursor: 'pointer',
                                                        zIndex: '9999'
                                                      }}
                                                      onClick={() => openDeleteAttachedFileInvoiceDialog(file)}
                                                    />
                                                  ) : (
                                                    ''
                                                  )}
                                                </span>
                                              </Grid>
                                            );
                                          } else {
                                            return (
                                              <Grid key={index} container spacing={0} justifyContent="space-between" style={{ display: 'inline' }}>
                                                <span
                                                  key={index}
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                    position: 'relative',
                                                    zIndex: '0',
                                                    cursor: 'pointer'
                                                  }}
                                                >
                                                  <Chip
                                                    style={{
                                                      borderColor: 'rgb(80 139 202)',
                                                      color: 'rgb(80 139 202)',
                                                      zIndex: '1'
                                                    }}
                                                    icon={<InsertDriveFileIcon />}
                                                    label={file.directus_files_id.filename_download}
                                                    onClick={() => alert('File not found')}
                                                    variant="outlined"
                                                  />
                                                  {!disableInvoice && invoicesRef.current.invoice_status != 'approved' ? (
                                                    <CancelIcon
                                                      style={{
                                                        fontSize: '15px',
                                                        height: '20px',
                                                        marginLeft: '-10px',
                                                        marginBottom: '3px',
                                                        marginRight: '5px',
                                                        cursor: 'pointer',
                                                        zIndex: '9999'
                                                      }}
                                                      onClick={() => openDeleteAttachedFileInvoiceDialog(file)}
                                                    />
                                                  ) : (
                                                    ''
                                                  )}
                                                </span>
                                              </Grid>
                                            );
                                          }
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </Grid>
                                  </Grid> :
                                  ''
                                }
                              </Grid>

                              {!disableInvoice ?
                                <Grid item xs={12} style={{ marginTop: '5px', marginBottom: "10px" }}>
                                  <CancelledButton
                                    variant="contained"
                                    onClick={() => handleOpenDeleteInvoiceDialog()}
                                    disabled={loadingDeleteDialog}
                                    style={{ width: '168px', marginLeft: '7px', float: "right", marginRight: "10px" }}
                                  >
                                    Delete Invoice
                                  </CancelledButton>
                                </Grid>
                                : ''}

                              <Dialog isopen={`${openDeleteInvoiceDialog}`} open={openDeleteInvoiceDialog} fullWidth={true} onClose={handleCloseDeleteInvoiceDialog}>
                                <DialogTitle disableTypography={true} id="form-dialog-title">
                                  <Typography component="div" variant="h6">
                                    <h2>Delete Invoice</h2>
                                  </Typography>
                                </DialogTitle>
                                <DialogContent>
                                  <Typography variant="h6">Are you sure?</Typography>
                                </DialogContent>
                                <DialogActions style={{ height: '60px' }}>
                                  {loadingDeleteDialog ? (
                                    <CircularProgress className={classes.circular} />
                                  ) : (
                                    <div>
                                      <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseDeleteInvoiceDialog}>
                                        Cancel
                                      </CancelButton>
                                      <SubmitButton style={{ marginLeft: '10px' }} id="save_btn" size="small" variant="contained" onClick={() => delete_invoice()}>
                                        Delete
                                      </SubmitButton>
                                    </div>
                                  )}
                                </DialogActions>
                              </Dialog>
                              <Grid container style={{ marginBottom: '10px' }}></Grid>
                            </Grid>

                            <div style={{ minHeight: '50px' }}></div>
                          </Grid>
                          {/* </Grid> */}
                        </Col>
                        <Col className="right-container" initialWidth={initialWidth.right} style={{ minWidth: '500px' }}>
                          {/* <Grid item xs={4}> */}
                          <div
                            style={{
                              marginBottom: invoiceFileRef.current.length > 0 ? '120px' : 0,
                              textAlign: 'center',
                              marginLeft: '20px',
                              height: '100%',
                              overflowY: 'overlay',
                              width: 'calc(100% - 40px)'
                            }}
                          >
                            {/* invoice tabs */}
                            {getMatchedInvoiceTabs()}
                            <div
                              style={{
                                height: '100%',
                                overflowY: 'overlay',
                                maxHeight: window.innerHeight - 150,
                                width: '100%'
                              }}
                            >
                              {!matchedInvoiceFileRef.current
                                ? invoiceFileRef.current.map((file, index) => {
                                  if (file.data_found && file.directus_files_id) {
                                    return getFileIFrame(index, file);
                                  } else {
                                    return getFileNotFoundIFrame(index);
                                  }
                                })
                                : matchedInvoiceFileRef.current.map((file, index) => {
                                  if (file.data_found && file.directus_files_id) {
                                    return getFileIFrame(index, file);
                                  } else {
                                    return getFileNotFoundIFrame(index);
                                  }
                                })}
                            </div>
                          </div>
                          {/* </Grid> */}
                        </Col>
                      </ColsWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ minHeight: '30px' }}></div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            ) : (
              ''
            )}
          </Container>

          <Dialog open={openMatchInfoRef.current} onClose={handleCloseMatchInfoDialog} fullWidth>
            <DialogTitle>
              <div style={{ fontSize: '18px' }}>Raw Invoice</div>
            </DialogTitle>
            <DialogContent>
              <Typography style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                <b>Raw Supplier</b>:{' '}
                {dataInvoiceRef.current ? (dataInvoiceRef.current.raw_supplier ? dataInvoiceRef.current.raw_supplier : '-') : '-'}
              </Typography>
              <Typography style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                <b>Raw Customer</b>:{' '}
                {dataInvoiceRef.current ? (dataInvoiceRef.current.raw_customer ? dataInvoiceRef.current.raw_customer : '-') : '-'}
              </Typography>
              <Typography style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                {dataInvoiceRef.current ? (dataInvoiceRef.current.raw_invoice ? dataInvoiceRef.current.raw_invoice : '') : ''}
              </Typography>
            </DialogContent>
            <DialogActions>
              <CancelButton size="small" variant="contained" onClick={handleCloseMatchInfoDialog}>
                <Typography variant="h6">Close</Typography>
              </CancelButton>
            </DialogActions>
          </Dialog>

          <Dialog open={openDeleteInvoiceRef.current} fullWidth>
            <DialogTitle>
              <div style={{ fontSize: '18px' }}>Delete Confirmation</div>
            </DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this invoice file?</Typography>
              {/* {invoicesRef.current ? (
                invoiceFileRef.current.length > 0 && invoicesRef.current.invoice_status === 'unmatched' ? (
                  <Typography style={{ color: 'red' }}>If you delete the last file, the invoice data will also be deleted.</Typography>
                ) : null
              ) : null} */}
            </DialogContent>
            <DialogActions style={{ height: '60px' }}>
              {loadingDeleteInvoice ? (
                <CircularProgress className={classes.circular} style={{ marginRight: '10px' }} />
              ) : (
                <div>
                  <CancelButton size="small" variant="contained" onClick={() => handleCloseDeleteInvoice()}>
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                  <SubmitButton style={{ marginLeft: '10px' }} size="small" variant="contained" onClick={() => deleteInvoice()}>
                    <Typography variant="h6">Delete</Typography>
                  </SubmitButton>
                </div>
              )}
            </DialogActions>
          </Dialog>

          <Dialog open={openDeleteAttachedFileInvoiceRef.current} fullWidth>
            <DialogTitle>
              <div style={{ fontSize: '18px' }}>Delete Confirmation</div>
            </DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this attached file?</Typography>
            </DialogContent>
            <DialogActions style={{ height: '60px' }}>
              {loadingDeleteInvoice ? (
                <CircularProgress className={classes.circular} />
              ) : (
                <div>
                  <CancelButton size="small" variant="contained" onClick={() => handleCloseDeleteAttachedFileInvoice()}>
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                  <SubmitButton style={{ marginLeft: '10px' }} size="small" variant="contained" onClick={() => deleteAttachedFileInvoice()}>
                    <Typography variant="h6">Delete</Typography>
                  </SubmitButton>
                </div>
              )}
            </DialogActions>
          </Dialog>

          <Dialog open={openApprovedRef.current}>
            <DialogTitle disableTypography={true} id="form-dialog-title">
              <Typography component="div" variant="h6">
                <h2>Approve Invoice</h2>
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to approve this invoice?
                <br />
                This will send the invoice to the accounting system and cannot be undone.
              </Typography>
            </DialogContent>

            <DialogActions style={{ height: '60px', marginRight: '5px' }}>
              {approvedLoading ? (
                <CircularProgress className={classes.circular} />
              ) : (
                <div>
                  <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeApprovedDialog}>
                    Cancel
                  </CancelButton>
                  <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => approvedInvoiceFirst()}>
                    Approve
                  </SubmitButton>
                </div>
              )}
            </DialogActions>
          </Dialog>

          <Dialog open={openRejectedDialogRef.current}>
            <DialogTitle disableTypography={true} id="form-dialog-title">
              <Typography component="div" variant="h6">
                <h2>Dispute Invoice Details</h2>
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextareaAutosize
                variant="outlined"
                placeholder="(Optional) Leave a note"
                style={{
                  maxWidth: '400px',
                  minWidth: '400px',
                  minHeight: '32px',
                  borderRadius: '8px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  fontFamily: 'SF Pro',
                  padding: '5.5px 14px',
                  height: '64px'
                }}
                onChange={e => handleChangeDisputeNote(e)}
                value={disputeNotesRef.current}
              />
            </DialogContent>

            <DialogActions style={{ height: '60px', marginRight: '5px' }}>
              {rejectedLoading ? (
                <CircularProgress className={classes.circular} />
              ) : (
                <div>
                  <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeRejectedDialog}>
                    Cancel
                  </CancelButton>
                  <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => rejectInvoices(invoicesRef.current.id)}>
                    Save
                  </SubmitButton>
                </div>
              )}
            </DialogActions>
          </Dialog>

          <Dialog open={openUnapprovedRef.current}>
            <DialogTitle disableTypography={true} id="form-dialog-title">
              <Typography component="div" variant="h6">
                <h2>Reset to Un-approved Invoice</h2>
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to reset this invoice to Un-approved?
                <br />
                After resetting, you will need to re-approve this invoice before it can be sent to the accounting system.
              </Typography>
            </DialogContent>

            <DialogActions style={{ height: '60px', marginRight: '5px' }}>
              {unapprovedLoading ? (
                <CircularProgress className={classes.circular} />
              ) : (
                <div>
                  <CancelButton id="cancel_btn" size="small" variant="contained" onClick={() => closeUnapprovedDialog()}>
                    Cancel
                  </CancelButton>
                  <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => resetToUnapproved(invoicesRef.current.id)}>
                    Reset
                  </SubmitButton>
                </div>
              )}
            </DialogActions>
          </Dialog>

          <Dialog open={openSplitInvoiceRef.current}>
            <DialogTitle>
              <div style={{ fontSize: '18px' }}>{openSplitInvoiceRef.current ? 'Split' : 'Copy'} Invoice Confirmation</div>
            </DialogTitle>
            <DialogContent>
              <Typography>This will create a new invoice record for this file. Are you sure?</Typography>
            </DialogContent>
            <DialogActions style={{ minHeight: '60px' }}>
              {loadingSplitCopyInvoice ? (
                <CircularProgress className={classes.circular} style={{ marginRight: '10px' }} />
              ) : (
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <CancelButton size="small" variant="contained" onClick={() => handleCloseSplitCopyInvoice()}>
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                  <SubmitButton style={{ marginLeft: '10px' }} size="small" variant="contained" onClick={() => splitInvoice()}>
                    <Typography variant="h6">Save</Typography>
                  </SubmitButton>
                </div>
              )}
            </DialogActions>
          </Dialog>
        </>
      )
      }
    </PageContent >
  );
};
export default IndexView;
