import React, { useState, useEffect } from 'react';
import { useStyles } from './style.js';
import PageContent from 'src/components/Page';
import AllOrdersListView from './AllOrdersListView';
import SelectedOrderView from './SelectedOrderView';
import EventEmitter from 'src/utils/EventEmitter';

const PurchaseOrderDetail = () => {
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      EventEmitter.emit('reload_item', { value: '/purchase-orders/:id' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContent title="Purchase Order Details">
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <AllOrdersListView />
        </div>

        <div className={classes.rightSide}>
          <SelectedOrderView />
        </div>
      </div>
    </PageContent>
  );
};

export default PurchaseOrderDetail;
