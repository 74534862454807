/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from 'react';
import directus from "src/services/directus";
import Enumerable from "linq";
import { Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import { AdminPermission, InvoiceAdminPermission, POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import { useStyles } from './ReusableStyle.js';

export const configurationFields = 'id, show_invoices, employee_settings, supplier_settings, customer_settings, config_settings, enable_approvals, show_po_account, show_po_internal_category, show_purchase_orders, show_sales_orders, show_purchase_orders_total_details, show_sales_orders_total_details';

export const formatNumber = num => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const generateDeliveryOption = (address) => {
  let newDeliveryAddressOption = [];

  if (address) {
    if (address.delivery_address_line_1) {
      newDeliveryAddressOption.push({
        id: 0,
        address_name: 'Default',
        address_line_1: address.delivery_address_line_1 ?? '',
        address_line_2: address.delivery_address_line_2 ?? '',
        city: address.delivery_city ?? '',
        state: address.delivery_state ?? '',
        post_code: address.delivery_post_code ?? '',
        lable: 
          `${address.delivery_address_line_1? address.delivery_address_line_1 : ''}` +
          `${address.delivery_address_line_2 ? ', ' + address.delivery_address_line_2 : ''}` +
          `${address.delivery_city ? ', ' + address.delivery_city + ' ' : ''}` +
          `${address.delivery_state ? address.delivery_state + ' ' : ''}` +
          `${address.delivery_post_code ? address.delivery_post_code + ' ' : ''}`
      })
    }
    
    if (address.additional_address && address.additional_address.length > 0) {
      address.additional_address.forEach(data => {
        newDeliveryAddressOption.push({
          id: data.id,
          address_name: data.address_name,
          address_line_1: data.address_line_1 ?? '',
          address_line_2: data.address_line_2 ?? '',
          city: data.city ?? '',
          state: data.state ?? '',
          post_code: data.post_code ?? '',
          lable: 
            `${data.address_line_1? data.address_line_1 : ''}` +
            `${data.address_line_2 ? ', ' + data.address_line_2 : ''}` +
            `${data.city ? ', ' + data.city + ' ' : ''}` +
            `${data.state ? data.state + ' ' : ''}` +
            `${data.post_code ? data.post_code + ' ' : ''}`
        })
      });
    }
  }
  
  newDeliveryAddressOption.push({
    id: -1,
    address_name: 'Custom',
    lable: 'Custom',
  })

  return newDeliveryAddressOption;
}

export const getNewOrder = async Orders => {
  var findCategoryType = Orders.data;
  var result5 = findNestedObjByKey(findCategoryType, "category_description");
  var result6 = Enumerable.from(findNestedObj(findCategoryType, "category_type"))
    .groupBy(x => x.id)
    .select(x => x.first().id)
    .toArray();

  if (result5.length > 0) {
    var new_data = await directus.api.post("/custom/mobile/timesheets/batch-data", {
      activities: {
        filter: {
          // "status": "published",
          id: { in: result5 }
        }
      },
      resources: {
        filter: {
          // "status": "published",
          id: { in: result5 }
        }
      },
      order_category_type: {
        filter: {
          id: { in: result6 }
        }
      }
    });

    if (new_data.data.activities.length > 0) {
      //eslint-disable-next-line array-callback-return
      new_data.data.activities.map((value, index) => {
        findCategoryType = findCategoryType.map(u => {
          if (!u.category_type) return u;

          let categoryType = Enumerable.from(new_data.data.order_category_type).firstOrDefault(x => x.id == u.category_type.id);
          if (categoryType && categoryType.collection == "activities") {
            return u.category_description != value.id ? u : replaceCategoryDescription(u, value);
          } else {
            return u;
          }
        });
      });
    }

    if (new_data.data.resources.length > 0) {
      //eslint-disable-next-line array-callback-return
      new_data.data.resources.map((value, index) => {
        findCategoryType = findCategoryType.map(u => {
          if (!u.category_type) return u;

          let categoryType = Enumerable.from(new_data.data.order_category_type).firstOrDefault(x => x.id == u.category_type.id);
          if (categoryType && categoryType.collection == "resources") {
            return u.category_description != value.id ? u : replaceCategoryDescription(u, value);
          } else {
            return u;
          }
        });
      });
    }

    findCategoryType = findCategoryType.map(order => {
      return replaceOrderItemsCategoryDescription(order, new_data.data);
    });
  }

  return findCategoryType;
};

const replaceOrderItemsCategoryDescription = (order, categories_data) => {
  if (order.order_items && order.order_items.length > 0) {
    order.order_items = order.order_items.map((order_item, index) => {
      if (!order_item.order_item) return order_item;
      if (!order_item.order_item.category_type) return order_item;

      let categoryType = Enumerable.from(categories_data.order_category_type).firstOrDefault(x => x.id == order_item.order_item.category_type.id);
      if (categoryType && categoryType.collection == "activities") {
        order_item.order_item.category_description = categories_data.activities.find(x => x.id == order_item.order_item.category_description);
      } else if (categoryType && categoryType.collection == "resources") {
        order_item.order_item.category_description = categories_data.resources.find(x => x.id == order_item.order_item.category_description);
      }

      return order_item;
    });
  }

  return order;
};

const replaceCategoryDescription = (oldData, newData) => {
  oldData.category_description = newData;
  return oldData;
};

export const findNestedObj = (entireObj, keyToFind) => {
  //eslint-disable-next-line no-unused-vars
  let foundObj;
  var list = [];
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue) {
      if (nestedValue && nestedValue[keyToFind]) {
        if (list.indexOf(nestedValue[keyToFind]) == -1) {
          foundObj = nestedValue;
          list.push(nestedValue[keyToFind]);
        }
      }
    }
    return nestedValue;
  });
  return list;
};

export const findNestedObjByKey = (theObject, keyToFind) => {
  var result = [];
  if (theObject instanceof Array) {
    for (let index = 0; index < theObject.length; index++) {
      const obj = theObject[index];

      for (const [key, value] of Object.entries(obj)) {
        if (value && key === keyToFind && result.indexOf(value) == -1) {
          result.push(value);
        } else if (value instanceof Object || value instanceof Array) {
          result = result.concat(findNestedObjByKey(value, keyToFind));
        }
      }
    }
  } else if (theObject instanceof Object) {
    for (const [key, value] of Object.entries(theObject)) {
      if (value && key === keyToFind && result.indexOf(value) == -1) {
        result.push(value);
      } else if (value instanceof Object || value instanceof Array) {
        result = result.concat(findNestedObjByKey(value, keyToFind));
      }
    }
  }

  return result;
};

export const checkEnableClick = (option) => {
  var enable_click = false;
  if (option.selectable) {
    enable_click = true;
  }

  if (option.access_level) {
    if (option.access_level === 'po_creator') {
      if (!POCreatorPermission()) {
        enable_click = false;
      }
    } else if (option.access_level === 'po_approver') {
      if (!POApproverPermission()) {
        enable_click = false;
      }
    } else if (option.access_level === 'invoice_admin') {
      if (!InvoiceAdminPermission()) {
        enable_click = false;
      }
    } else if (option.access_level === 'invoice_admin') {
      if (!AdminPermission()) {
        enable_click = false;
      }
    }
  }

  if (AdminPermission()) {
    enable_click = true;
  }

  return enable_click;
}

export const generateDeliveryAddress = (address, selectedOrder = null) => {
  let address_name = address.address_name;
  let address_line_1 = '';
  let address_line_2 = '';
  let address_city = '';
  let address_state = '';
  let address_postcode = '';

  if (address && address.id != -1) {
    let delivery_address_line_1 = address.address_line_1 ?? '';
    let delivery_address_line_2 =  address.address_line_2 ?? '';
    let delivery_city = address.city;
    let delivery_state = address.state;
    let delivery_post_code = address.post_code;

    if (delivery_address_line_1) {
      address_line_1 += delivery_address_line_1;
    }

    if (delivery_address_line_2) {
      address_line_2 += delivery_address_line_2;
    }

    if (delivery_city) {
      address_city += delivery_city
    }
    
    if (delivery_state) {
      address_state += delivery_state
    }
    
    if (delivery_post_code) {
      address_postcode += delivery_post_code
    }
  } else if (address.id == -1) {
    if (selectedOrder != null) {
      if (selectedOrder && selectedOrder.delivery_address_line_1) {
        address_line_1 += selectedOrder.delivery_address_line_1;
      }
    
      if (selectedOrder && selectedOrder.delivery_address_line_2) {
        address_line_2 += selectedOrder.delivery_address_line_2;
      }
    
      if (selectedOrder && selectedOrder.delivery_address_city) {
        address_city += selectedOrder.delivery_address_city;
      }
    
      if (selectedOrder && selectedOrder.delivery_address_state) {
        address_state += selectedOrder.delivery_address_state;
      }
    
      if (selectedOrder && selectedOrder.delivery_address_postcode) {
        address_postcode += selectedOrder.delivery_address_postcode;
      }
    }
  }
  
  return {
    AddressName: address_name,
    AddressLine1: address_line_1,
    AddressLine2: address_line_2,
    AddressCity: address_city,
    AddressState: address_state,
    AddressPostcode: address_postcode,
  }
}

export const filterOption = (option) => {
  return option.data.value.terms && option.data.value.terms.length > 4;
};

export const OrderDetailLoadingView = () => {  
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={0} justifyContent="space-around">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Skeleton animation="wave" variant="rounded" width="40%" height={50} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexEnd}>
          <Skeleton animation="wave" variant="rounded" width="50%" height={50} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
        <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={55} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '5px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={55} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '5px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={55} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
        <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={148} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ marginTop: '20px' }}>
        <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <Grid item xs={4} sm={4} md={4} lg={4}  className={classes.flexEnd}>
          <Skeleton animation="wave" variant="rounded" width="100%" height={44} style={{ borderRadius: '10px', marginBottom: '10px' }} />
        </Grid>
      </Grid>
    </>
  )
}

export const NumResultsHeader = ({ children, ...other }) => {
  const paperRef = useRef();

  return (
    <div style={{ background: 'white', borderRadius: '0 0 5px 5px', marginLeft:'-105px' }}>
      <Paper {...other} ref={paperRef}>
        {children}
      </Paper>
    </div>
  );
};

export const NumResultsHeaderDescription = ({ children, dataItem, collection_data, ...other }) => {
  const headerRef = useRef();
  const countRef = useRef();
  const paperRef = useRef();

  useEffect(() => {
    const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
    countRef.current.innerHTML = numOptions;
    if (numOptions > 0) {
      headerRef.current.style.display = 'block';
    } else {
      headerRef.current.style.display = 'none';
    }
  });

  var total_number = 0;

  if (collection_data) {
    total_number = collection_data.length;
  }

  return (
    <div style={{ background: 'white', borderRadius: '0 0 5px 5px', marginLeft:'-105px' }}>
      <Paper {...other} ref={paperRef}>
        {children}
      </Paper>
      <div ref={headerRef} style={{ display: 'none', textAlign: 'right', padding: '5px' }}>
        Showing <span ref={countRef}></span> of {total_number}
      </div>
    </div>
  );
};

export const NumResultsHeaderItemDescription = ({ children, dataItem, collection_data, ...other }) => {
  const headerRef = useRef();
  const countRef = useRef();
  const paperRef = useRef();

  useEffect(() => {
    const numOptions = paperRef.current.querySelectorAll('li[data-option-index]').length;
    countRef.current.innerHTML = numOptions;
    if (numOptions > 0) {
      headerRef.current.style.display = 'block';
    } else {
      headerRef.current.style.display = 'none';
    }
  });

  var total_number = 0;

  if (collection_data) {
    total_number = collection_data.length;
  }

  return (
    <div style={{ background: 'white', borderRadius: '0 0 5px 5px', marginLeft:'-145px' }}>
      <Paper {...other} ref={paperRef}>
        {children}
      </Paper>
      <div ref={headerRef} style={{ display: 'none', textAlign: 'right', padding: '5px' }}>
        Showing <span ref={countRef}></span> of {total_number}
      </div>
    </div>
  );
};