import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Grid,
  MenuItem,
  LinearProgress,
  Divider,
  InputAdornment
} from '@material-ui/core';
import { CancelButton, SubmitButton } from 'src/components/ReusableStyle';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import directus from '../../services/directus';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import NumberFormat from 'react-number-format';
import { useStylesEmployees, AddButton } from './style.js';
import useStateRef from "react-usestateref";
import { pageOptions } from 'src/_utils/pageOptions';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/views/Components/ErrorMessage';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { removeCache } from "../../utils/KeyValueCache";
import { formatNumber } from 'src/components/ReusableFunction';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import TimezoneDate from 'src/components/ConvertTimezone';
import moment from 'moment';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

let page = 1;

var searchTimeout;
const Items = (props) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStylesEmployees();
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState(null);

  const [totalPage, setTotalPage] = useState('');
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);

  const [loadingPagination, setLoadingPagination] = useState(false);
  const [dataItem, setDataItem, dataItemRef] = useStateRef({
    id: '', item_number: '', name: '', description: '', price: '', not_synced: false,
  })

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    "name": { type: "desc", value: false },
    "item_number": { type: "desc", value: false },
    "description": { type: "desc", value: false },
    "price": { type: "desc", value: false },
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const isMounted = useRef(true);

  const [search, setSearch] = React.useState('');
  const [searchingLoading, setSearchingLoading] = useState(false);

  const [loadingSync, setLoadingSync] = useState(false);
  const [integration, setIntegration, integrationRef] = useStateRef(null);


  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    isMounted.current = true;
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }

    return () => {
      isMounted.current = false;
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.items) {
        setSortField(sort_data.items)
      }
    }
    loadIntegration();
    loadItems();
  }

  let disable = dataItemRef.current.name === '' || dataItemRef.current.description === '' || dataItemRef.current.price === '';

  const loadIntegration = async () => {
    var result = await directus.getItems('configuration', { fields: 'id, integration.*' });
    if (result) {
      if (result.data.length > 0) {
        if (result.data[0]) {
          if (result.data[0].integration) {
            setIntegration(result.data[0].integration);
          }
        }
      }
    }
  }

  const loadItems = async (data) => {
    var item_number_sort = sortFieldRef.current.item_number;
    var name_sort = sortFieldRef.current.name;
    var description_sort = sortFieldRef.current.description;
    var price_sort = sortFieldRef.current.price;

    var orderByField = "id";

    if (item_number_sort.value) {
      if (item_number_sort.type === "asc") { orderByField = "item_number"; }
      else if (item_number_sort.type === "desc") { orderByField = "-item_number"; }
    }

    if (name_sort.value) {
      if (name_sort.type === "asc") { orderByField = "name"; }
      else if (name_sort.type === "desc") { orderByField = "-name"; }
    }

    if (description_sort.value) {
      if (description_sort.type === "asc") { orderByField = "description"; }
      else if (description_sort.type === "desc") { orderByField = "-description"; }
    }

    if (price_sort.value) {
      if (price_sort.type === "asc") { orderByField = "price"; }
      else if (price_sort.type === "desc") { orderByField = "-price"; }
    }

    let filterData = {
      limit: pageNumberRef.current,
      page: page,
      filter: {
        status: { eq: 'published' },
      },
      meta: "filter_count",
      sort: orderByField
    };

    if (search) {
      filterData = {
        limit: pageNumberRef.current,
        page: page,
        filter: {
          description: { like: search },
          item_number: { 'logical': 'or', like: search },
          status: { eq: 'published' },
        },
        meta: "filter_count",
        sort: orderByField
      };
    }

    var ItemsResult = await directus.getItems('items', filterData);

    if (isMounted.current) {
      setItems(ItemsResult.data);
      setTotalPage(ItemsResult.meta.filter_count);
      setLoadingPagination(false);
      setSortLoading(false);
      setSearchingLoading(false);
    }
  };

  const handleClickOpen = (item, mode) => {
    setOpen(true);
    if (mode === "add") {
      setMode('add');
      setTitle('Add Item');
      setText('');
    }
    else if (mode === "edit") {
      setDataItem({
        id: item.id,
        item_number: item.item_number,
        name: item.name,
        description: item.description,
        price: item.price,
        not_synced: item.not_synced
      })
      setMode('edit');
      setTitle('Edit Item');
      setText('');
    } else {
      setDataItem({
        id: item.id, item_number: item.item_number, name: item.name, description: item.description, price: item.price
      })
      setMode('delete');
      setTitle('Delete Item');
      setText('Are you sure?');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setDataItem({
      id: '', item_number: '', name: '', description: '', price: ''
    })
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    setDataItem({ ...dataItemRef.current, [name]: value })
  }

  const SaveData = async () => {
    setLoading(true);
    try {
      if (mode === 'edit') {
        try {
          var new_data_edit = dataItemRef.current;
          var body_edit = {
            id: new_data_edit.id,
            name: new_data_edit.name,
            description: new_data_edit.description,
            price: new_data_edit.price,
            item_number: new_data_edit.item_number,
            status: 'published'
          }
          await directus.updateItem('items', body_edit.id, body_edit);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }
      else if (mode === 'add') {
        try {
          var new_data = dataItemRef.current;
          var body = {
            item_number: new_data.item_number,
            name: new_data.name,
            description: new_data.description,
            price: new_data.price,
            not_synced: true,
            status: 'published'
          }
          await directus.createItem('items', body);
          enqueueSnackbar("Data updated successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        } catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }

      }
      else {
        try {
          await directus.deleteItem('items', dataItemRef.current.id);
          enqueueSnackbar("Data deleted successfully", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
        }
      }

      removeCache('items');
      loadItems();
      setLoading(false);
      handleClose();
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      setLoading(false);
    }
  }


  const pageChange = (value) => {
    setLoadingPagination(true);
    page = page + value;
    loadItems();
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    page = 1;
    loadItems();
  };

  const sortData = (val) => {
    setSortLoading(true);
    var sort_field_data = {
      "name": { type: "desc", value: false },
      "item_number": { type: "desc", value: false },
      "description": { type: "desc", value: false },
      "price": { type: "desc", value: false },
    }

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {

        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data_ls = localStorage.sort_data;
        if (sort_data_ls) {
          sort_data = JSON.parse(sort_data_ls);
          sort_data.items = sort_field_data;
        } else {
          sort_data = { items: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data))
        setSortField(sort_field_data);
      }
    }

    loadItems();
  };

  const handleChangeSearching = (e) => {
    setSearch(e.target.value);
  }

  const searchItems = (event) => {
    setSearchingLoading(true);
    if (event.key === "Enter") {
      loadItems();
    }
    else {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        page = 1;
        loadItems();
      }, 500)
    }
  }

  const loadSyncData = async () => {
    setLoadingSync(true);

    try {
      await directus.api.get('/custom/sync_data/getdata/items?access_token=2rfv43cVjKKZ7MFZIt8QIuh1')
        .then(response =>
          enqueueSnackbar('Sync Complete', { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 })
        )
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setLoadingSync(false);
    window.location.reload();
  };

  return (
    <div>
      <div style={{ width: "1200px", display: "flex", alignItems: "center", height: "60px", marginTop: "10px", marginBottom: "10px" }}>
        <div style={{ padding: "0px 20px 0 22px", lineHeight: "60px", background: "white", height: "100%", borderRadius: "8px 0 0 8px" }}>
          <Typography style={{ lineHeight: "60px" }}><b>Filter:</b></Typography>
        </div>
        <div style={{ lineHeight: "60px", width: "310px", background: "white", height: "100%", paddingRight: "10px", borderRadius: "0 8px 8px 0" }}>
          <InputBase
            id="search_order"
            name="searchOrder"
            variant="outlined"
            placeholder="Search"
            size="small"
            onChange={handleChangeSearching}
            onKeyUp={(e) => searchItems(e)}
            className={classes.searchbox}
            value={search}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.IconSearch} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <div>{searchingLoading ? <CircularProgress style={{ width: "20px", height: "20px", marginTop: "4px" }} className={classes.circular2} /> : null}</div>
              </InputAdornment>
            }
          />
        </div>
        <div style={{ lineHeight: "60px", width: "20px", background: "white", height: "100%", paddingRight: "20px", paddingLeft: "10px", borderRadius: "0 8px 8px 0" }}>

        </div>
      </div>

      {items ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("item_number")}>
                        <Typography noWrap variant="h5">Item Number</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['item_number'].value === true ?
                            sortFieldRef.current['item_number'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    {/* <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("name")}>
                        <Typography noWrap variant="h5">Name</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['name'].value === true ?
                            sortFieldRef.current['name'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid> */}
                    <Grid item xs={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("description")}>
                        <Typography noWrap variant="h5">Description</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['description'].value === true ?
                            sortFieldRef.current['description'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", paddingRight: "5px" }}>
                      <div className={classes.headerSort} onClick={() => sortData("price")}>
                        <Typography noWrap variant="h5">Price</Typography>
                        <div style={{ position: "relative" }}>
                          {sortFieldRef.current['price'].value === true ?
                            sortFieldRef.current['price'].type === "asc" ?
                              <SortRoundedIcon className={classes.ascSort} />
                              :
                              <SortRoundedIcon className={classes.descSort} />
                            :
                            ''
                          }
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                }
              />
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                  <AddIcon />
                </AddButton>
              </Grid>

            </ListItem>

          </List>
          {sortLoadingRef.current ?
            <Grid item xs={12}>
              <div className={classes.linearProgress} style={{ marginLeft: "7px", marginRight: "7px" }}><LinearProgress style={{ height: "2px" }} /></div>
            </Grid>
            : ''}
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ marginBottom: '20px' }} >

            {items.map((item, index) => {
              return (
                <div key={index}>
                  <ListItem style={{ backgroundColor: item.status === "draft" ? "#EBE9E9" : item.status === "inactive" ? "#cdd0d1" : item.status === "deleted" ? "#fee1e0a1" : "white" }}>
                    <ListItemText
                      primary={
                        <Grid container key={index}>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{item.item_number ? item.item_number : ''}</Typography>
                          </Grid>
                          {/* <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{item.name ? item.name : ''}</Typography>
                          </Grid> */}
                          <Grid item xs={6} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{item.description ? item.description : ''}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                            <Typography variant="h6">{isNaN(item.price) ? 0 : '$' + formatNumber((Math.round(item.price * 100) / 100).toFixed(2))}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    {item.not_synced ?
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(item, 'edit')} >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(item, 'delete')} >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      :
                      <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(item, 'edit')} >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    }
                  </ListItem>
                  {items.length - 1 > index ? <Divider /> : ''}
                </div>
              );
            })}
          </List>
        </div>
        :
        <CircularProgress className={classes.circular} />
      }

      <Dialog
        id="holiday_dialog"
        isopen={`${open}`}
        open={open}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="holiday_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
        <DialogContent>
          <Typography variant="h6">{text ? text : ''}</Typography>
          {mode !== 'delete' ?
            <div>
              <TextField
                id="item_number"
                label="Item Number"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="item_number"
                value={dataItemRef.current.item_number}
                onChange={handleChangeInput}
              />
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="name"
                value={dataItemRef.current.name}
                onChange={handleChangeInput}
              />
              <TextField
                id="description"
                label="Description"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="description"
                value={dataItemRef.current.description}
                onChange={handleChangeInput}
              />
              <TextField
                id="price"
                label="Price"
                variant="outlined"
                fullWidth={true}
                margin="normal"
                name="price"
                value={dataItemRef.current.price ? parseFloat(dataItemRef.current.price).toFixed(2) : ''}
                onChange={handleChangeInput}
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
              />
            </div>
            : ''}
        </DialogContent>
        <DialogActions style={{ height: "60px" }}>
          {loading ?
            <CircularProgress className={classes.circular} />
            :
            <div>
              <CancelButton
                id="cancel_btn"
                size="small"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                disabled={mode === 'delete' ? false : disable}
                size="small"
                variant="contained"
                onClick={() => SaveData()}
              >
                {mode === 'delete' ? 'Delete' : 'Save'}
              </SubmitButton>
            </div>
          }

        </DialogActions>
      </Dialog>

      {items ?
        <Grid
          item xs={12}
          style={{ textAlign: 'right', marginBottom: '30px', fontFamily: 'SF Pro' }}
        >
          <Grid item xs={12} style={{ "display": "flex", "justifyContent": "space-between", "flexWrap": "wrap" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {loadingSync ?
                <span style={{ marginRight: "10px" }}><CircularProgress style={{ marginLeft: "7px", color: "#5bfa17" }} size={20} /></span>
                :
                <IconButton className={classes.export} TouchRippleProps={{ style: { width: "130px" } }} style={{ width: "130px", marginRight: "10px", borderRadius: "10px", backgroundColor: "#82cb43", height: "30px", boxShadow: "0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)", padding: "0px", marginTop: "-2px", color: "white" }}
                  onClick={(e) => loadSyncData()}
                >
                  <span style={{ fontSize: "14px", fontWeight: "600", marginLeft: "3px", marginRight: "8px" }}>SYNC ITEMS</span>
                </IconButton>
              }

              {integrationRef.current ?
                <Typography variant="h6" style={{ color: "red", fontSize: "16px", textAlign: 'right' }}>
                  {integrationRef.current.items_last_sync === null || integrationRef.current.items_last_sync === '' ? '' : 'Master Data last synced: ' + TimezoneDate(integrationRef.current.items_last_sync)}
                </Typography>
                : ''}

            </div>

            <div>
              <span style={{ paddingRight: '20px' }}>
                Rows per page:
              </span>
              <span style={{ paddingRight: '20px' }}>
                <TextField
                  id="standard-select-currency"
                  select
                  value={pageNumber}
                  onChange={handleChange}
                >
                  {pageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span style={{ paddingRight: '20px' }}>
                {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
              </span>

              <Button
                variant="outlined"
                size="small"
                disabled={page === 1 || loadingPagination}
                className={classes.buttonLeft}
                onClick={() => pageChange(-1)}
              >
                <ArrowBackIosRoundedIcon />
              </Button>

              <Button
                variant="outlined"
                size="small"
                className={classes.buttonRight}
                disabled={page >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
                onClick={() => pageChange(1)}
              >
                <ArrowForwardIosRoundedIcon />
              </Button>
            </div>
          </Grid>
        </Grid>
        :
        " "}
    </div>
  );
}

export default Items;
